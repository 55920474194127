import React, { useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import classes from '../dash.module.css'
import Slider from "react-slick"
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
}

const getOptions = (type, selectedUserChartData, clrs) => ({

    chart: {
        type: 'pie',
        width: 580,
        height: 360,
    },

    plotOptions: {
        series: {
            borderWidth: 0,
            colorByPoint: true,
            type: 'pie',
            size: '100%',
            innerSize: '80%',
            showInLegend: true,
            dataLabels: {
                enabled: true,
                crop: false,
                distance: '5%',
                style: {
                    fontWeight: '300px',
                    fontSize: '16px',
                },
                format: '{point.percentage:.1f} %',
                connectorWidth: 0
            }
        }
    },

    colors: clrs?.length >= 1 ? clrs : ['#CA3559', '#B6BA04', '#44A0DF'],
    title: '',
    series: [
        {
            type: 'pie',
            name: 'Chart',
            data: selectedUserChartData
        }
    ],

    legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'top',
        x: 0,
        y: 10,
    },
    tooltip: {
        enabled: false
    },
    credits: {
        enabled: false
    },
});

const addChartColors = (key, dashboardDetails) => {

    const colors = []
    const newKey1 = key+"_tags_pending";
    const newKey2 = key+"_tags_reviewed";

    if (dashboardDetails[newKey1] >= 1)
        colors.push('#B6BA04')
    if (dashboardDetails[newKey2] >= 1)
        colors.push('#44A0DF')

    return colors;
}

const numberRound = (number) => {
    return Math.round(number)
}

function NERDonut(adminDashboardDetails) {
    const dashboardDetails = adminDashboardDetails?.adminDashboardDetails;
    const graphNerData = [];
    const graphImageData = [];
    const graphSpeechData = [];
    const graphPosData = [];
    const chartColors = [];
    const allColors = {
        image_tags: [],
        ner_tags: [],
        speech_tags: [],
        pos_tags: []

    }
    if (dashboardDetails?.ner_tags_assigned) {
        if (dashboardDetails?.ner_tags_reviewed >= 1)
            graphNerData.push({ name: 'Reviewed', y: numberRound(dashboardDetails?.ner_tags_reviewed) })
        if (dashboardDetails?.ner_tags_pending >= 1)
            graphNerData.push({ name: 'Pending', y: numberRound(dashboardDetails?.ner_tags_pending) })
       // if (dashboardDetails?.ner_tags?.accept >= 1)
         //   graphNerData.push({ name: 'Accept', y: dashboardDetails?.ner_tags?.accept })

        const colors = addChartColors('ner', dashboardDetails)
        allColors.ner_tags = colors

    }
    if ( dashboardDetails?.image_tags_assigned) {
        if (dashboardDetails?.image_tags_reviewed >= 1)
            graphImageData.push({ name: 'Reviewed', y: numberRound(dashboardDetails?.image_tags_reviewed) })
        if (dashboardDetails?.image_tags_pending >= 1)
            graphImageData.push({ name: 'Pending', y: numberRound(dashboardDetails?.image_tags_pending) })
        //if (dashboardDetails?.image_tags?.accept >= 1)
          //  graphImageData.push({ name: 'Accept', y: dashboardDetails?.image_tags?.accept })

        const colors = addChartColors('image', dashboardDetails)
        allColors.image_tags = colors

    }
    if ( dashboardDetails?.speech_tags_assigned) {
        if (dashboardDetails?.speech_tags_reviewed >= 1)
            graphSpeechData.push({ name: 'Reviewed', y: numberRound(dashboardDetails?.speech_tags_reviewed) })
        if (dashboardDetails?.speech_tags_pending >= 1)
            graphSpeechData.push({ name: 'Pending', y: numberRound(dashboardDetails?.speech_tags_pending) })
      //  if (dashboardDetails?.speech_tags?.accept >= 1)
        //    graphSpeechData.push({ name: 'Accept', y: dashboardDetails?.speech_tags?.accept })

        const colors = addChartColors('speech', dashboardDetails)
        allColors.speech_tags = colors;
    }
    if ( dashboardDetails?.pos_tags_assigned) {
        if (dashboardDetails?.pos_tags_reviewed >= 1)
            graphPosData.push({ name: 'Reviewed', y: numberRound(dashboardDetails?.pos_tags_reviewed) })
        if (dashboardDetails?.pos_tags_reviewed >= 1)
            graphPosData.push({ name: 'Pending', y: numberRound(dashboardDetails?.pos_tags_pending) })
       // if (dashboardDetails?.pos_tags?.accept >= 1)
         //   graphPosData.push({ name: 'Accept', y: dashboardDetails?.pos_tags_reviewed })

        const colors = addChartColors('pos', dashboardDetails);
        allColors.pos_tags = colors
    }

    


    return (
        <>

            {(dashboardDetails?.ner_tags_assigned >= 1 || dashboardDetails?.pos_tags_assigned >= 1 || dashboardDetails?.speech_tags_assigned >= 1 || dashboardDetails?.image_tags_assigned >= 1) &&

                <div className='errorPie mx-auto p-4 lg:px-4 lg:py-0'>


                    <div className='px-6'>
                        <Slider {...settings}>
                            {( dashboardDetails?.ner_tags_assigned >= 1) &&
                                <div className='py-4 px-4 lg:px-6 text-sm'>
                                    <p><strong>NER</strong></p>
                                    <p>Graphical Representation</p>
                                    <p>Total: {dashboardDetails?.ner_tags_assigned}</p>

                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={getOptions('pie', graphNerData, allColors?.ner_tags || [])}
                                    />
                                </div>
                            }
                            {(dashboardDetails?.pos_tags_assigned && dashboardDetails?.pos_tags_assigned >= 1) &&
                                <div className='py-4 px-4 lg:px-6 text-sm'>
                                    <p><strong>POS</strong></p>
                                    <p>Graphical Representation</p>
                                    <p>Total: {dashboardDetails?.pos_tags_assigned}</p>

                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={getOptions('pie', graphPosData, allColors?.pos_tags || [])}
                                    />
                                </div>
                            }

                            {(dashboardDetails?.image_tags_assigned && dashboardDetails?.image_tags_assigned >= 1) &&
                                <div>
                                    <div className='py-4 px-4 lg:px-6 text-sm'>
                                        <p><strong>IMAGES</strong></p>
                                        <p>Graphical Representation</p>
                                        <p>Total: {dashboardDetails?.image_tags_assigned}</p>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={getOptions('pie', graphImageData, allColors?.image_tags || [])}
                                        />
                                    </div>
                                </div>
                            }
                            {(dashboardDetails?.speech_tags_assigned && dashboardDetails?.speech_tags_assigned >= 1) &&
                                <div className='py-4 px-4 lg:px-6 text-sm'>
                                    <p><strong>SPEECH</strong></p>
                                    <p>Graphical Representation</p>
                                    <p>Total: {dashboardDetails?.speech_tags_assigned}</p>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={getOptions('pie', graphSpeechData, allColors?.speech_tags || [])}
                                    />
                                </div>
                            }
                        </Slider>
                    </div>
                </div>

            }
        </>
    )
}

export default NERDonut
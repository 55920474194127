import React, { useCallback, useState } from 'react'
import { ReactTags } from 'react-tag-autocomplete'

export function HeadTagBox({ subcats , setsubcats}) {
    const [selected, setSelected] = useState(subcats.map(sc => ({value :sc , label: sc }) ))

    const onAdd = useCallback(
        (newTag) => {
            setSelected([...selected, newTag])
            setsubcats([...selected, newTag].map(it => (it.value)))
        },
        [selected]
    )

    const onDelete = useCallback(
        (tagIndex) => {
            setSelected(selected.filter((_, i) => i !== tagIndex))
            setsubcats(selected.filter((_, i) => i !== tagIndex).map(it => (it.value)))
        },
        [selected]
    )

    console.log(selected , subcats)

    return (
        <>
            <ReactTags
                labelText=""
                selected={selected}
                onAdd={onAdd}
                onDelete={onDelete}
                allowNew
                placeholder=""
                newOptionText="Enter here"
            />
        </>
    )
}
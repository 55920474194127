import * as React from 'react'
import styles from '../shared/common.module.css'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useNavigate } from 'react-router-dom'
import { SERVICE_OPTIONS } from '../../config';

function Select({ services }) {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate()
    //const selectedLanguage = lang=='punjabi'?'Punjabi to English':lang=='english'?'English to Punjabi':'Select Language';

    const getServicesText = () => {
        switch (services) {
            case 'ner':
                return SERVICE_OPTIONS?.ner || '';

            case 'pos':
                return SERVICE_OPTIONS?.pos || '';

            case 'translation':
                return SERVICE_OPTIONS?.translation || '';


            case 'transliteration':
                return SERVICE_OPTIONS?.transliteration || '';
            default:
                return 'Select services';
        }
    }
    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={styles.dropbox}>
                <button type="button" onClick={handleClick}>
                    {getServicesText()}<ArrowDropDownOutlinedIcon />
                </button>
                {open ? (
                    <div className={styles.dropped}>
                        <div className={styles.ddItem}>
                            <input checked={services === 'ner'} onChange={() => navigate('/ner')} type='radio' name="selectname" />
                            <label>Named Entity Recognition</label>
                            <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                        </div>
                        <div className={styles.ddItem}>
                            <input checked={services == 'pos'} onChange={() => navigate('/pos')} type='radio' name="selectname" />
                            <label>Part of Speech Recognition</label>
                            <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                        </div>
                        <div className={styles.ddItem}>
                            <input checked={services === 'translation'} onChange={() => navigate('/translation')} type='radio' name="selectname" />
                            <label>Translation</label>
                            <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                        </div>
                        <div className={styles.ddItem}>
                            <input checked={services === 'transliteration'} onChange={() => navigate('/transliteration')} type='radio' name="selectname" />
                            <label>Machine Transliteration</label>
                            <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                        </div>
                    </div>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}

export default Select
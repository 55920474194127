import React, { useState , useEffect} from 'react'
// import { useNavigate } from 'react-router-dom'
import { CategoryPopUp } from './addCategoryPop'
import classes from './cate.module.css'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import ClearIcon from '@mui/icons-material/Clear';
import { useGetAllCats } from './hooks'
import { ToastContainer, toast } from 'react-toastify';
import { AddNewCatPop } from './addNewCategoryPop'


function CateList() {
    // const navigate = useNavigate();
    const [toggle, setToggle] = useState([]);
    const get_cats_hook = useGetAllCats()
    const handleClick = (ind) => {
        let td = [...toggle]
        td[ind]= !td[ind]
        setToggle(td);

    };
    const [pop, setpop] = useState(false)
    const [pop2, setpop2] = useState(false)
    const [CatsList, setCatsList] = useState({})




    useEffect(() => {
        if (get_cats_hook.isSuccess) {
          if (get_cats_hook.data.data.length === 0) {
            setCatsList([])
    
          }
          else {
    
            setCatsList(get_cats_hook?.data?.data[0])
            setToggle(new Array(get_cats_hook?.data?.data[0].length).fill(false))
          }
    
    
        }
        else if (get_cats_hook.isError) {
          toast.error("Error is Fetching Data")
        }
      }, [get_cats_hook.isSuccess, get_cats_hook.isError, get_cats_hook.data])

  useEffect(() => {
    get_cats_hook.refetch()
    setpop(false)
    return () => {
        get_cats_hook.remove()
    }
  }, [])


    return (
        <>
            <div className='lg:p-4'>
                <div className='px-4 lg:px-5 py-2 text-right mb-2'>
                    <button type='button' className='btnPrimary px-4 py-2' onClick={() => setpop2(true)}>
                        <AddIcon /> Add Category
                    </button>
                </div>
                <div className={`${classes.catFrontLWrap} px-4`}>
                    {/* Category List Loop */}
                    {CatsList && Object.keys(CatsList).map((itm,ind) => (
                        <div className='mb-3'>
                        <div className={`${classes.lBox} flex items-center justify-between py-4 pl-6 pr-2 rounded-lg`}>
                            <div className='flex items-center'>
                                <div>
                                    <span class="badge">{ind+1}</span>
                                </div>
                                <div className='px-5 lg:px-8'>
                                    <label className={`${classes.listLBL} max-w-[200px] lg:max-w-[400px]`}>
                                        {itm}
                                    </label>
                                </div>
                            </div>
                            <div className='flex items-center'>
                                <div className='px-3'>
                                    <button type='button' title='Edit' onClick={() => setpop(itm)}>
                                        <EditIcon fontSize='small' />
                                    </button>
                                </div>
                                {/* <div className='px-3'>
                                    <button type='button' title='Remove'>
                                        <ClearIcon fontSize='medium' />
                                    </button>
                                </div> */}
                                <div className='px-3'>
                                    <button type='button' onClick={()=>handleClick(ind)}>
                                        {toggle[ind] ?
                                            <ArrowDropUpIcon fontSize='medium' />
                                            :
                                            <ArrowDropDownIcon fontSize='medium' />
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>

                        {toggle[ind] ?
                            <div className={`${classes.catListDropBox} mt-2 rounded-lg`}>
                                {/* Files Loop */}
                                {CatsList[itm].map((subcat) => (
                                <div className={`${classes.lFile} flex items-center pl-[5.5rem]`}>
                                    <div className='pr-2'>
                                        <InsertDriveFileIcon />
                                    </div>
                                    <div className='pr-2'>
                                        <label className={`${classes.listLBL} max-w-[160px] lg:max-w-[300px]`}>{subcat}</label>
                                    </div>
                                </div>

                                ))}
                               
                            </div>
                            :
                            <></>
                        }
                    </div>
                    )) }
                    


                </div>
            </div>

            {pop !=false ? <CategoryPopUp get_cats_hook={get_cats_hook} setpop={setpop} itm={pop} CatsList={CatsList} setCatsList={setCatsList}   /> : null}
        {pop2 !=false ? <AddNewCatPop get_cats_hook={get_cats_hook} setpop={setpop2} CatsList={CatsList} setCatsList={setCatsList} /> : null }
        </>
    )
}

export default CateList
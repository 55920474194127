import React from 'react'
import classes from '../dash.module.css'

function PublicTopSection(adminDashboardDetails) {
    const dashboardDetails = adminDashboardDetails?.adminDashboardDetails
    return (
        <>
            <div className='block lg:flex lg:gap-4'>
                <div className='lg:flex-1 pb-3 lg:pb-0'>
                    <div className={`${classes.gradBG} counterBox h-auto text-center rounded-xl`}>
                        <div className='p-3'>
                            <div className='inline-flex items-center mb-3'>
                                <span className='cIco cate'></span>
                                <h1 className='pl-4 text-lg font-semibold'>Corpus</h1>
                            </div>
                            <div className='flex gap-4'>
                                <div className='flex-1'>
                                    <div className={`${classes.whiteBx} shadow-lg rounded-md text-center p-4`}>
                                        <h1 className='text-gradient text-2xl mb-2'>{dashboardDetails?.corpus_uploaded || 0}</h1>
                                        <div className='inline-flex items-center'>
                                            <div><img src='/svg/solar_upload-bold.svg' alt="" /></div>
                                            <div className='pl-2 text-md font-semibold'>Uploaded</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-1'>
                                    <div className={`${classes.whiteBx} shadow-lg rounded-md text-center p-4`}>
                                        <h1 className='text-gradient text-2xl mb-2'>{dashboardDetails?.corpus_documented || 0}</h1>
                                        <div className='inline-flex items-center'>
                                            <div><img src='/svg/ion_documents.svg' alt="" /></div>
                                            <div className='pl-2 text-md font-semibold'>Documented</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='lg:flex-1'>
                    <div className={`${classes.gradBG} counterBox h-auto text-center rounded-xl`}>
                        <div className='p-3'>
                            <div className='inline-flex items-center mb-3'>
                                <span className='cIco images'></span>
                                <h1 className='pl-4 text-lg font-semibold'>Images</h1>
                            </div>
                            <div className='flex gap-4'>
                                <div className='flex-1'>
                                    <div className={`${classes.whiteBx} shadow-lg rounded-md text-center p-4`}>
                                        <h1 className='text-gradient text-2xl mb-2'>{dashboardDetails?.images_uploaded || 0}</h1>
                                        <div className='inline-flex items-center'>
                                            <div><img src='/svg/solar_upload-bold.svg' alt="" /></div>
                                            <div className='pl-2 text-md font-semibold'>Uploaded</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-1'>
                                    <div className={`${classes.whiteBx} shadow-lg rounded-md text-center p-4`}>
                                        <h1 className='text-gradient text-2xl mb-2'>{dashboardDetails?.images_documented || 0}</h1>
                                        <div className='inline-flex items-center'>
                                            <div><img src='/svg/ion_documents.svg' alt="" /></div>
                                            <div className='pl-2 text-md font-semibold'>Documented</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PublicTopSection
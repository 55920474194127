import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const getOptions = (type, chartData) => ({


    legend: {
        layout: 'horizontal',
        align: 'bottom',
        horizontalAlign: 'middle',
        itemMarginTop: 3,
        itemMarginBottom: 3,
        style: {
            fontSize: '8px',
            fill: '#ffffff',
        }
    },
    chart: {
        type,
        width: 960,
        height: 350,
    },
    title: {
        text: '',
        align: 'top',
        style: {
            fontSize: '10px',
            fill: '#ffffff',
        }
    },


    credits: {
        enabled: false
    },
    yAxis: {
        title: {
            text: 'Total number of people',
            x: -10,
            style: {
                color: '#cdcdcd'
            }
        },
        labels: {
            style: {
                color: '#cdcdcd'
            },
            format: '{value}'
        }
    },
    xAxis: {
        type: 'category',
        labels: {
            style: {
                color: '#cdcdcd'
            }
        },
    },
    colors: ['#44A0DF', '#CA3559'],
    series: [
        {
            borderWidth: 0,
            xAxis: 0,
            yAxis: 0,
            style: {
                fontSize: '8px',
                color: '#cdcdcd',
            },
            data: chartData,
            showInLegend: false,
            dataLabels: {
                enabled: false,
                allowOverlap: false
            },
            style: {
                fontSize: '8px',
                fontWeight: 300,
                color: '#cdcdcd',
            }
        },
    ],
    tooltip: {
        // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
    },
});

function CategoriesChart(adminDashboardDetails) {

    const chartData = [];
    if (adminDashboardDetails?.adminDashboardDetails?.length >= 1) {
        for (const bar of adminDashboardDetails?.adminDashboardDetails) {
            chartData.push([bar.category, bar.distinct_users_count])
        }
    }
    return (
        <>
            {adminDashboardDetails?.adminDashboardDetails?.length >= 1 && 
            <div className='p-4'>
                <h6 className='mb-1'>Progress of categories</h6>
                <p className='mb-3 text-xs'>Total progress of categories as per total number of people.</p>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={getOptions('column', chartData)}
                />
            </div>
            }

        </>
    )
}

export default CategoriesChart
import { useQuery , useMutation } from "react-query";
import axios from "axios";
import { useSelector } from "react-redux";
import { BACKEND_URL } from "../../../config";

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { redux_logout } from "../../../auth/authSlice";
import logoutFn from "../../../auth/logout_fn";

export const useAddImageForTags = () => {
  const Data = useSelector(state => state);
  let navigate = useNavigate()
  let dispatch = useDispatch()
  return useMutation(
      {
        mutationFn: async (data) => {
          
          return await axios.post(BACKEND_URL + `/upload_image`, data , {
             headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
          }
          )
        },
        onSuccess: (data) => {
            
        },
        onError: (error) => {
          if (error.response.data.message == "Please login first"){
            // logoutFn(Data.auth["access_token"])
          localStorage.clear()
          dispatch(redux_logout())
          navigate('/')
          }
        },
      }
    )
}

export const useAddImageTags = () => {
  const Data = useSelector(state => state);
  let navigate = useNavigate()
  let dispatch = useDispatch()
  return useMutation(
      {
        mutationFn: async (data) => {
          
          return await axios.post(BACKEND_URL + `/upload_image_tags`, data , {
             headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
          }
          )
        },
        onSuccess: (data) => {
            
        },
        onError: (error) => {
          if (error.response.data.message == "Please login first"){
            // logoutFn(Data.auth["access_token"])
          localStorage.clear()
          dispatch(redux_logout())
          navigate('/')
          }
        },
      }
    )
}


export const useUpdateImageTags = () => {
  const Data = useSelector(state => state);
  let navigate = useNavigate()
  let dispatch = useDispatch()
  return useMutation(
      {
        mutationFn: async (data) => {
          return await axios.post(BACKEND_URL + `/upload_image_tags`, data , {
             headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
          }
          )
        },
        onSuccess: (data) => {
            
        },
        onError: (error) => {
          if (error.response.data.message == "Please login first"){
            // logoutFn(Data.auth["access_token"])
          localStorage.clear()
          dispatch(redux_logout())
          navigate('/')
          }
        },
      }
    )
}




export const useViewImagesTags = ()=> {

  const Data = useSelector(state => state)
  let navigate = useNavigate()
  let dispatch = useDispatch()
  return useQuery(
      {
        queryKey: ['getImagesTags'],
        queryFn: async () => {
          const response = await axios.request({
              headers: {
                Authorization: `Bearer ${Data.auth["access_token"]}`
              },
              method: "GET",
              url: BACKEND_URL + `/get_my_images_tags`
            })
          return response.data ? response.data : []
        },
        onSuccess: (data) => {

        },
        onError: (error) => {
          if (error.response.data.message == "Please login first"){
            // logoutFn(Data.auth["access_token"])
          localStorage.clear()
          dispatch(redux_logout())
          navigate('/')
          }
        },
        enabled:true,
      }
    )
}

import React, { useState, useEffect } from 'react'
import styles from '../../shared/common.module.css'
import { Link, useNavigate } from 'react-router-dom'
import { DeleteCorpusPop } from './deleteCorpuspop';
import { toast } from 'react-toastify';
import CorpusContent from './corpus-content';
import { ListObject } from './listObject';
import { useGetCategories } from './hooks';

function CorpusListing({ corpaList, view_corpuses_hook }) {
    const navigate = useNavigate();


    const [pop, setpop] = useState(false)
    const [delete_s3, setdelete_s3] = useState(null)
    const [isDeleted, setisDeleted] = useState(false)

    const cat_hook = useGetCategories()

    const [CategoriesMeta, setCategoriesMeta] = useState({})

    useEffect(() => {
        if (cat_hook.isSuccess) {
            if (cat_hook.data.data.length === 0) {
                setCategoriesMeta({})

            }
            else {

                setCategoriesMeta(cat_hook.data.data[0])
            }

        }
        else if (cat_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [cat_hook.isSuccess, cat_hook.isError, cat_hook.data])

    useEffect(() => {
        cat_hook.refetch()

        return () => {
            cat_hook.remove()
        }
    }, [])
    
    useEffect(() => {
        if (isDeleted) {
            view_corpuses_hook.refetch()
            toast.success("Deleted")
            setisDeleted(false)
        }
    }, [isDeleted])

    function cmp(a,b) {
        const [dateParta, timeParta] = a.last_updated_on.split(' ');
        const [daya, montha, yeara] = dateParta.split('/');
        const [houra, minutea, seconda] = timeParta.split(':');
        const [datePartb, timePartb] = b.last_updated_on.split(' ');
        const [dayb, monthb, yearb] = datePartb.split('/');
        const [hourb, minuteb, secondb] = timePartb.split(':');
    
    
        const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
        const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
        return dateB - dateA;
          
        
    }
    



    return (
        <>
            <div>
                <button type="button" className='backTxtBtn' onClick={() => navigate('/contribution')}>Back</button>
            </div>
            <div className='grayBox rounded-xl'>
                <div className='mb-4 flex justify-between px-3 pt-3'>
                    <div>
                        <h2 className='text-primary mb-0 text-lg'>Upload Corpus</h2>
                    </div>
                    <div>
                        <button type="button" className='px-4 btnPrimary text-white font-semibold py-2' onClick={() => navigate('/upload_new_corpus')}>Upload File</button>
                    </div>
                </div>

                <div className='tableListWrap'>
                    <div className='contTable text-xs min-w-[1000px] px-3 pb-3'>
                        <div className='tableHead nonSticky gray flex items-center py-2'>
                            <div className='flex-none px-3'>&nbsp;</div>
                            <div className='flex-1 px-3'>File Title</div>
                            <div className='flex-1 px-3'>Upload Date and Time</div>
                            <div className='flex-1 px-3'>Category</div>
                            <div className='flex-1 px-3'>Sub Category</div>
                            <div className='flex-1 px-3'>Type of File</div>
                            <div className='flex-1 px-3'>Label</div>
                            <div className='flex-1 px-3'>Status</div>
                            <div className='flex-1 px-3'>&nbsp;</div>
                        </div>
                        <div className='tableBody'>
                            {/* Table Row Loop */}
                            {corpaList.sort(cmp).map((itm) => {
                                return (
                                    <div key={itm.s3_uri}>
                                        <ListObject key={itm.s3_uri} frm={itm} setdelete_s3={setdelete_s3} setisDeleted={setisDeleted} setpop={setpop} CategoriesMeta={CategoriesMeta}/>
                                    </div>
                                )

                            })}



                        </div>
                    </div>
                </div>
            </div>

            {pop ? <DeleteCorpusPop setpop={setpop} s3_uri={delete_s3} set_s3uri={setdelete_s3} setisDeleted={setisDeleted} /> : null}
        </>
    )
}

export default CorpusListing
import { BrowserRouter as Router, Route, Outlet, Link, Routes, Navigate } from "react-router-dom";
import "./App.css"
import Login from "./auth/login"
import ForgetPassword from "./auth/forget-password"
import SignUp from "./auth/signup"
import HomeAdmin from "./pages/dashboard/admin"
import PublicAdmin from "./pages/dashboard/publicAdmin"
import HomePublic from "./pages/dashboard/public"
import LeaderBoard from "./pages/dashboard/leaderboard"
import ServicesIndex from "./pages/services/services-front"
import SpeechRecognition from "./pages/contribution/speech-recognition/speech-recognition-page";
import SpeechRecognitionDetails from "./pages/contribution/speech-recognition/speech-recognition-details"
import ApiIndex from "./pages/api/api-front-page"
import { useSelector } from "react-redux"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Examples from "./pages/examples"

import Contribution from "./pages/contribution/contribution-front";

import { Gurmukhi } from "./pages/gurmukhi/gurmukhi-page";
import { Fonts } from "./pages/fonts";
import Dictionary from "./pages/dictionary";
import AuthCopy from "./auth/shared/auth-copyright";
import Header from "./components/header";
import ServicesFront from "./pages/services/services-front";
import GurbaniFront from "./pages/contribution/gurbani/gurbani-page";
import Test from "./pages/contribution/test";
import Tagging from "./pages/contribution/tagging";
import Translate from "./pages/services/translate-page";
import Transliterate from "./pages/services/transliterate";
import NerPage from "./pages/services/ner-page";
import ImageTagging from "./pages/contribution/imagetagging/addImageTagging";
import SpeechTaggingListing from "./pages/contribution/speech-recognition/tagging-listing";
import ViewCorpuses from "./pages/contribution/upload-corpus/viewCorpuses";
import ViewTagging from "./pages/contribution/tagging/viewTagging";
import UploadNew from "./pages/contribution/upload-corpus/uploadNew";
import UploadTaggingImage from "./pages/contribution/tagging/uploadNew";
import EditImageTagging from './pages/contribution/imagetagging/editImageTagging'
import NerFront from "./pages/contribution/ner/nerFront";
import PosPage from "./pages/services/pos-page";
import { NerCreateTag } from "./pages/contribution/ner/nerCreateTag";
import { NerEditTag } from "./pages/contribution/ner/nerEditTag";
import PosFront from "./pages/contribution/pos/posFront";
import { PosCreateTag } from "./pages/contribution/pos/posCreateTag";
import { PosEditTag } from "./pages/contribution/pos/posEditTag";
import { SpeechTagEdit } from "./pages/contribution/speech-recognition/speechTagEdit";
import AdminHeader from "./admin/AdminHeader";
import AdminListCorpus from "./admin/corpus/list";
import { ActionCorpus } from "./admin/corpus/actionCorpus";
import AdminNERList from "./admin/ner/list";
import { NERUpdateStatus } from "./admin/ner/updateStatus";
import AdminPOSList from "./admin/pos/list";
import { POSUpdateStatus } from "./admin/pos/updateStatus";
import AdminListImageCorpus from "./admin/imageCorpus/list";
import AdminListImageTags from "./admin/imageTagging/list";
import AdminSpeechTaggingListing from "./admin/speechTagging/list"
import { UpdateStatusImageTag } from "./admin/imageTagging/updateStatus";
import AdminEditImageTagging from "./admin/imageTagging/editImageTagging";
import AdminSpeechRecognitionDetails from "./admin/speechTagging/speech-recognition-details"
import ProfilePage from "./pages/dashboard/profile-files/profile-front";
import EditProfile from "./pages/dashboard/profile-files/edit-profile";
import CategoryFrontpage from "./admin/category-management/category-front";
import CreateCategory from "./admin/category-management/create-category";

function App() {

  const Data = useSelector(state => state)

  return (
    <div className="App">
  
      <Router>
        
        <Routes>

          <Route element={<PrivateRoutes/>}>
          <Route path="/home-public" element={<PublicAdmin />} />
            <Route path="/elements-uses" element={<Examples />} />
         
            <Route path="/api" element={<ApiIndex />} />
            {/* <Route path="/services" element={<ServicesIndex />} />  */}
                 
            <Route path="/gurmukhi" element={<Gurmukhi />} />
            <Route path="/fonts" element={<Fonts />} />
            {/* <Route path="/dictionary" element={<Dictionary />} />       */}
           
            <Route path="/contribution" element={<Contribution />} />
            <Route path="/speech-recognition" element={<SpeechRecognition />} />  
            <Route path="/edit_speech_tag/:id" element={<SpeechRecognitionDetails/>} />  
            <Route path="/view_speech_taggings" element={<SpeechTaggingListing/>} />      
            
            <Route path="/view_corpuses" element={<ViewCorpuses/>} />    
            <Route path="/upload_new_corpus" element={<UploadNew/>} /> 
            <Route path="/upload_new_tagging" element={<UploadTaggingImage/>} />     
            
            <Route path="/gurbani" element={<GurbaniFront/>} /> 

            <Route path="/services" element={<ServicesFront/>} />
            <Route path="/ner" element={<NerPage/>} /> 
            <Route path="/pos" element={<PosPage/>} /> 
            <Route path="/translation" element={<Translate/>} /> 
            <Route path="/transliteration" element={<Transliterate/>} /> 
            <Route path="/view_taggings" element={<ViewTagging/>} />
            <Route path="/add_image_tagging" element={<ImageTagging/>} /> 
            <Route path="/view_image_tagging/:id" element={<EditImageTagging/>} /> 
            {/*  <Route path="/tagging" element={<Tagging/>} /> */}
            <Route path="/nerContribution" element={<NerFront/>} /> 
            <Route path="/nerCreateNew" element={<NerCreateTag/>} /> 
            <Route path="/nerEditTag/:id" element={<NerEditTag/>} /> 
            <Route path="/posContribution" element={<PosFront/>} /> 
            <Route path="/posCreateNew" element={<PosCreateTag/>} /> 
            <Route path="/posEditTag/:id" element={<PosEditTag/>} /> 

            <Route path="/publicProfile" element={ <ProfilePage/>} /> 
            <Route path="/editPublicProfile" element={ <EditProfile/>} /> 
                    
          </Route>

          <Route element={<AdminRoutes/>}>
            <Route path="/home-admin" element={<HomeAdmin />} />
            <Route path="/admin-list-corpus" element={<AdminListCorpus/>} />
            <Route path="/adminDocumentCorpus" element={<ActionCorpus/>} /> 
            <Route path="/adminNerList" element={<AdminNERList/>} /> 
            <Route path="/leaderboard" element={<LeaderBoard />} />
            <Route path="/adminNerUpdateStatus/:id" element={<NERUpdateStatus/>} /> 

            <Route path="/adminPosList" element={<AdminPOSList/>} /> 
            <Route path="/adminPosUpdateStatus/:id" element={<POSUpdateStatus/>} /> 

            {/* <Route path="/admin-list-images" element={<AdminListImageCorpus/>} />  */}

            <Route path="/admin-image-tags" element={ <AdminListImageTags/>} /> 
            <Route path="/admin-speech-tags" element={ <AdminSpeechTaggingListing/>} /> 
            <Route path="/admin_speech_tag_view/:id" element={<AdminSpeechRecognitionDetails/>} /> 
            <Route path="/adminStatusImageTag/:id" element={ <AdminEditImageTagging/>} /> 

            <Route path="/category" element={ <CategoryFrontpage/>} />
            {/* <Route path="/create-category" element={ <CreateCategory/>} /> */}
            <Route path="/adminProfile" element={ <ProfilePage/>} /> 
            <Route path="/edit-profile" element={ <EditProfile/>} /> 
            
            
         
          </Route>

          <Route path="/" element={ <RoleBased/>} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/signup" element={<SignUp />} />
          
          
          
          <Route path="/test" element={<Test/>} />
          <Route path="/tagging" element={<Tagging/>} />
          
          </Routes>
      </Router>

      <ToastContainer theme="colored" />

    </div>
  )
}

const RoleBased = () => {
  const Data = useSelector(state => state)
  if (Data.auth.isSignedIn) {
    if (Data.auth.role ==="admin") {
     return <> <AdminHeader/> <HomeAdmin /> </> 
    }
    else{
      return <><Header/> <PublicAdmin /> </> 
    }
  }
  else{
    return <Login />
  }
}


const PrivateRoutes = () => {
  const Data = useSelector(state => state)
  return Data.auth.isSignedIn && Data.auth.role !== 'admin'  ? <Header/> : <Navigate to={'/'} />
}

const AdminRoutes = () => {
  const Data = useSelector(state => state)
  return Data.auth.isSignedIn && Data.auth.role === 'admin' ?<AdminHeader/> : <Navigate to={'/'} />
}


export default App
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetCategories, useListcorpus } from "./hooks";
import styles from '../../pages/shared/common.module.css'
import { DeleteCorpusPop } from "./deletePop";
import { AdminListObject } from "./listObj";
import AuthCopy from "../../auth/shared/auth-copyright";
import LoaderForAll from '../../components/loader-for-all'


function AdminListCorpus() {
    const navigate = useNavigate();
    const [corpusList, setcorpusList] = useState([])
    const get_corpus_list_hook = useListcorpus()


    const cat_hook = useGetCategories()

    const [CategoriesMeta, setCategoriesMeta] = useState({})

    useEffect(() => {
        if (cat_hook.isSuccess) {
            if (cat_hook.data.data.length === 0) {
                setCategoriesMeta({})

            }
            else {

                setCategoriesMeta(cat_hook.data.data[0])
            }

        }
        else if (cat_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [cat_hook.isSuccess, cat_hook.isError, cat_hook.data])



    const [pop, setpop] = useState(false)
    const [delete_s3, setdelete_s3] = useState(null)
    const [isDeleted, setisDeleted] = useState(false)

    useEffect(() => {
        if (isDeleted) {
            get_corpus_list_hook.refetch()
            toast.success("Deleted")
            setisDeleted(false)
        }
    }, [isDeleted])

    useEffect(() => {
        get_corpus_list_hook.refetch()

    }, [])

    useEffect(() => {
        console.log("LIST CHANGES")
        if (get_corpus_list_hook.isSuccess) {
            setcorpusList(get_corpus_list_hook.data.data)


        }
        else if (get_corpus_list_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [get_corpus_list_hook.isSuccess, get_corpus_list_hook.isError, get_corpus_list_hook.data])

    function cmp(a, b) {
        const [dateParta, timeParta] = a.last_updated_on.split(' ');
        const [daya, montha, yeara] = dateParta.split('/');
        const [houra, minutea, seconda] = timeParta.split(':');
        const [datePartb, timePartb] = b.last_updated_on.split(' ');
        const [dayb, monthb, yearb] = datePartb.split('/');
        const [hourb, minuteb, secondb] = timePartb.split(':');


        const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
        const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
        return dateB - dateA;


    }





    return (
        <>
            {get_corpus_list_hook.isLoading ? <LoaderForAll /> :
                <>
                    {/* <div>
                <button type="button" className='backTxtBtn' onClick={() => navigate('/contribution')}>Back</button>
            </div> */}
                    <div className='pageWrap lightWhite withCopy'>
                        <div className="px-5 py-4 lg:px-8">
                            <div className='grayBox rounded-xl'>
                                <div className='mb-4 flex justify-between px-3 pt-3'>
                                    <div>
                                        <h2 className='text-primary mb-0 text-lg'>Text Corpus</h2>
                                    </div>
                                    {/* <div>
                                    <button type="button" className='px-4 btnPrimary text-white font-semibold py-2' onClick={() => navigate('/upload_new_corpus')}>Upload File</button>
                                </div> */}
                                </div>

                                <div className='tableListWrap pb-3'>
                                    <div className='contTable text-xs min-w-[1300px] px-3 pb-3'>
                                        <div className='tableHead nonSticky gray flex items-center py-2'>
                                            <div className='flex-none px-3'>&nbsp;</div>
                                            <div className='flex-1 px-3'>File Title</div>
                                            <div className='flex-1 px-3'>Last Update at</div>
                                            <div className='flex-1 px-3'>Is Documented</div>
                                            <div className='flex-1 px-3'>Is Updated</div>
                                            <div className='flex-1 px-3'>Creator Name</div>
                                            <div className='flex-1 px-3'>Type of File</div>
                                            <div className='flex-1 px-3'>Label</div>
                                            <div className='flex-1 px-3'>Status</div>
                                            <div className='flex-none w140 px-3'>&nbsp;</div>
                                        </div>
                                        <div className='tableBody'>
                                            {/* Table Row Loop */}
                                            {corpusList.sort(cmp).map((itm) => {
                                                return (
                                                    <div key={itm.s3_uri}>
                                                        <AdminListObject key={itm.s3_uri} itm={itm} setdelete_s3={setdelete_s3} setisDeleted={setisDeleted} setpop={setpop} CategoriesMeta={CategoriesMeta} />
                                                    </div>
                                                )

                                            })}



                                        </div>
                                    </div>
                                </div>
                            </div>


                            {pop ? <DeleteCorpusPop setpop={setpop} s3_uri={delete_s3} set_s3uri={setdelete_s3} setisDeleted={setisDeleted} /> : null}
                            <div className='copyFloat'><AuthCopy /></div>
                        </div>
                    </div>
                </>}
        </>
    )
}

export default AdminListCorpus
import React, { useEffect, useState } from 'react'
import styles from '../../shared/common.module.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useGetAllPOSTags, useGetMyTags, useGetNewDocument, useGetTagByReqId, useUpdateTags, useUploadTags, uuseUploadTags } from './hooks';
import { ToastContaipos, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TokenAnnotator } from "react-text-annotate";
import LoaderForAll from '../../../components/loader-for-all'
import { POS_TAGS } from '../../../config';
import HelpIcon from '@mui/icons-material/Help';
import AuthCopy from '../../../auth/shared/auth-copyright'
import { PosGuidelines } from './posGuidelines';


export const PosEditTag = () => {
    const { id } = useParams()
    const [Pos_ID, setPos_ID] = useState(parseInt(id))
    const navigate = useNavigate();
    const [Doc, setDoc] = useState()
    const [postag, setpostag] = useState(POS_TAGS[0])
    const [PosValue, setPosValue] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [loading, setloading] = useState(false)


    const updatePosTags_hook = useUpdateTags()

    const get_my_pos_tags_by_request_id_hook = useGetTagByReqId();

    const get_my_tags_hook = useGetMyTags()
    const [tagList, settagList] = useState([])

    const get_tags = useGetAllPOSTags()
    const [tags, settags] = useState([])



    useEffect(() => {
        if (get_tags.isSuccess) {
            if (get_tags.data.data.length === 0) {
                settags([])
            }
            else {
                settags(get_tags?.data?.data.sort())
            }


        }
        else if (get_tags.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [get_tags.isSuccess, get_tags.isError, get_tags.data])

    useEffect(() => {
        get_tags.refetch()
        return () => {
            get_tags.remove()
        }
    }, [])


    function cmp(a, b) {
        const [dateParta, timeParta] = a.last_updated_on.split(' ');
        const [daya, montha, yeara] = dateParta.split('/');
        const [houra, minutea, seconda] = timeParta.split(':');
        const [datePartb, timePartb] = b.last_updated_on.split(' ');
        const [dayb, monthb, yearb] = datePartb.split('/');
        const [hourb, minuteb, secondb] = timePartb.split(':');


        const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
        const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
        return dateB - dateA;


    }

    useEffect(() => {
        if (get_my_tags_hook.isSuccess) {
            if (get_my_tags_hook.data.data.length === 0) {
                settagList([])
            }

            else {
                let ids = get_my_tags_hook.data.data.sort(cmp).map(itm => itm.pos_request_id)
                settagList(ids)
            }
        }
        else if (get_my_tags_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [get_my_tags_hook.isSuccess, get_my_tags_hook.isError, get_my_tags_hook.data])


    useEffect(() => {
        get_my_tags_hook.refetch()
        return () => {
            get_my_tags_hook.remove()
        }
    }, [])



    useEffect(() => {
        if (get_my_pos_tags_by_request_id_hook.isSuccess) {
            if (get_my_pos_tags_by_request_id_hook.data.message) {
                toast.success("No more data available")
            }
            else {
                setDoc(get_my_pos_tags_by_request_id_hook.data.data)
                setPosValue(get_my_pos_tags_by_request_id_hook.data.data.pos_tags)
                setIsSubmit(false)
            }

        }
        else if (get_my_pos_tags_by_request_id_hook.isError) {
            toast.error("Error is Fetching Data")
        }
        setloading(false)
    }, [get_my_pos_tags_by_request_id_hook.data, get_my_pos_tags_by_request_id_hook.isSuccess, get_my_pos_tags_by_request_id_hook.isError])

    useEffect(() => {
        get_my_pos_tags_by_request_id_hook.refetchWithId(Pos_ID)

        return () => {
            get_my_pos_tags_by_request_id_hook.remove()
        }
    }, [id])


    const handlePosAnotationChange = (newValue) => {

        if (!isSubmit) {
            setPosValue(newValue);
        }
    };

    function handleSubmit() {
        if (PosValue.length) {
            let body = {
                pos_request_id: Doc.pos_request_id,
                document: Doc.document,
                pos_tags: JSON.stringify(PosValue),
                pos_status: 'pending',
            }
            updatePosTags_hook.mutate(body)
            setIsSubmit(true)
        } else {
            toast.error('Please add some tags')
        }

    }
    const notifySuccess = (msg) => {
        toast.success(msg, {
            onClose: ({ uid }) => setIsSubmit(false)
        });

    }
    const notifyError = (msg) => {
        toast.error(msg, {
            onClose: ({ uid }) => setIsSubmit(false)
        });

    }

    useEffect(() => {
        if (updatePosTags_hook.isSuccess) {
            // notifySuccess("Uploaded Success")
            navigate('/posContribution');
            toast.success("Update Success")
        }
        else if (updatePosTags_hook.isError) {
            toast.error("Error is updating tags")
            // notifyError("Error in uploading tags")
        }

    }, [updatePosTags_hook.isError, updatePosTags_hook.isSuccess])

    function handleNext() {
        let ind = tagList.indexOf(Pos_ID)
        if (ind < tagList.length - 1) {
            setPos_ID(tagList[ind + 1])
            console.log(tagList, "LLS")
            // get_my_pos_tags_by_request_id_hook.refetchWithId(tagList[ind+1])
            navigate(`/posEditTag/${tagList[ind + 1]}`)
            setloading(true)
        }
    }

    function handlePrev() {
        let ind = tagList.indexOf(Pos_ID)
        if (ind > 0) {
            setPos_ID(tagList[ind - 1])
            navigate(`/posEditTag/${tagList[ind - 1]}`)
            setloading(true)
            // get_my_pos_tags_by_request_id_hook.refetchWithId(tagList[ind-1])
        }
    }

    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen((prev) => !prev);
    };



    return (
        <>
            <div className='pageWrap lightWhite'>
                <div className='flex gap-0'>
                    <div className='grow lg:px-5 relative hScreen'>
                        {updatePosTags_hook.isLoading || loading ? <LoaderForAll /> :

                            <div className={`${styles.pagecontainer} ${styles.pxC}`}>
                                <button type="button" className='backTxtBtn' onClick={() => navigate('/posContribution', { state: { selected_id: id } })}>Back</button>

                                <>

                                    <div className='grayBox rounded-xl p-4 mb-4'>

                                        <div className='flex items-center justify-between mb-3'>
                                            <div className='grow flex items-center'>
                                                <h2 className='text-primary m-0 text-lg'>Part of Speech Recognition Tags</h2>
                                                <div className='pl-4'>
                                                    <select className='selectTag xPad' value={postag} onChange={(e) => setpostag(e.target.value)} name="tags" id="tags">
                                                        {tags.map(itm => {
                                                            return (
                                                                <option key={itm} value={itm}>{itm}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <div className='pl-3'>
                                                    <button type="button" title='Help' onClick={handleClick}><HelpIcon /></button>
                                                </div>
                                            </div>
                                            <div>
                                                <button type="button" className='px-4 btnPrimary text-white font-semibold py-2' onClick={() => navigate('/posCreateNew')}>Get New Document</button>
                                            </div>

                                        </div>
                                        {
                                            Doc != null ?

                                                <TokenAnnotator
                                                    tokens={Doc.document}
                                                    value={PosValue}

                                                    onChange={handlePosAnotationChange}
                                                    getSpan={(span) => ({
                                                        ...span,
                                                        tag: postag,

                                                    })}
                                                />

                                                : null
                                        }

                                    </div>
                                </>


                                <div className='flex gap-4 justify-between'>
                                    <div>
                                        &nbsp;
                                    </div>
                                    <div className='flex gap-4'>
                                        <div><button disabled={tagList.indexOf(Pos_ID) === tagList.length-1 ? true : false } onClick={handleNext} className='btnOutline text-white font-semibold px-4 py-2'>Previous</button></div>
                                        <div><button disabled={tagList.indexOf(Pos_ID) === 0 ? true : false }  onClick={handlePrev} className='btnOutline text-white font-semibold px-4 py-2'>Next</button></div>
                                        <div>
                                            <button disabled={PosValue.length == 0 || updatePosTags_hook.isLoading} onClick={handleSubmit} className='px-4 btnPrimary text-white font-semibold py-2 mb-3'>Update</button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        }
                        <div className='copyFloat'><AuthCopy /></div>
                    </div>

                    {open ? (
                        <div className='flex-none lg:w-[380px]'>
                            <PosGuidelines setOpen={setOpen} />
                        </div>
                    ) : null}
                </div>


            </div>
        </>
    )
}

import React, { useState, useEffect, useRef } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined'
import styles from '../../shared/common.module.css'
import AuthCopy from '../../../auth/shared/auth-copyright'
import Select from '../../services/select'
import GurbaniFilter from '../gurbani/gurbani-filter'
import GurbaniStatusTable from '../gurbani/gurbani-status-table';
import { AreaSelector, IArea } from '@bmunozg/react-image-area'
import { TextTagging } from './addTextTagging'
import { useUpdateImageTags, useGetImageTaggingByID, useViewImagesTags } from './hooks';
import { useFormik } from 'formik';
import { useSearchParams, useNavigate, createSearchParams, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import KeyBoard from '../../../components/virtual-keyboard'
import { cCodes } from "../../../components/keyMap";
import LoaderForAll from '../../../components/loader-for-all'




function EditImageTagging() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams()
    const [image_id, setimage_id] = useState(parseInt(id))
    const editImageTags = useUpdateImageTags();
    const tagImage = useGetImageTaggingByID();
    const [areas, setAreas] = useState([]);
    const [oldAreas, setOldAreas] = useState([]);
    const [imageData, setImageData] = useState([]);
    const [deleteTag, setDeleteTag] = useState(null);
    const [tagToBeDeleted, setTagToBeDeleted] = useState([]);
    const [disabled, setDisabled] = useState(false);
    //const [inpText, setinpText] = useState("")
    //const [actualInp,setactualInp] = useState("")
    // function handleChange(e) {
    //     setinpText(e.target.value)    

    // }



    const [tagList, settagList] = useState([])
    const [Ld, setLd] = useState(false)
    const view_image_tag_hook = useViewImagesTags()


    useEffect(() => {

        if (view_image_tag_hook.isSuccess) {
            let ids = view_image_tag_hook.data.data.sort(cmp).map(itm => itm.image_id)
            settagList(ids)


        }
        else if (view_image_tag_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [view_image_tag_hook.isSuccess, view_image_tag_hook.isError, view_image_tag_hook.data])

    useEffect(() => {
        view_image_tag_hook.refetch()
    }, [])



    function cmp(a, b) {
        const [dateParta, timeParta] = a.last_updated_on.split(' ');
        const [daya, montha, yeara] = dateParta.split('/');
        const [houra, minutea, seconda] = timeParta.split(':');
        const [datePartb, timePartb] = b.last_updated_on.split(' ');
        const [dayb, monthb, yearb] = datePartb.split('/');
        const [hourb, minuteb, secondb] = timePartb.split(':');


        const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
        const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
        return dateB - dateA;


    }



    const [isOpened, setIsOpened] = useState(true);
    function toggleKB() {
        setIsOpened(wasOpened => !wasOpened);
    }

    const [loading, setLoading] = useState(true);
    const counter = useRef(0);
    const imageLoaded = () => {
        counter.current += 1;
        if (counter.current >= 1) {
            setLoading(false);
        }
    }
    function mapToPunjabi(inp) {
        let mapped = ''
        for (let val of inp) {
            if (val in cCodes) {

                mapped += cCodes[val]
            }
            else {
                mapped += val
            }
        }
        return mapped
    }

    //   useEffect(() => {
    //       setactualInp(mapToPunjabi(inpText))

    //   }, [inpText])


    const formik = useFormik({
        initialValues: {
            image_id: "",
            coordinates: "",
            tags: "",
        },
        // validationSchema: UploadCorpusSchema,
        onSubmit: (values) => {
            // if (! file){
            //     return
            // }
            setDisabled(true)
            if (areas.length) {
                const requestsArray = [];
                const oldRequests = oldAreas.requests;
                const oldRequestKeys = [];
                for (let key of oldRequests.keys()) {
                    oldRequestKeys.push(key)
                }
                const allOldRequestsArray = [];
                oldRequests.map((area, index) => {

                    if (oldRequestKeys.includes(index)) {
                        allOldRequestsArray.push({
                            "image_request_id": area.image_request_id,
                            "image_tag_id": area.image_tag_id,
                            "coordinates": area.coordinates,
                            "tags": area.tags,
                            "status": area.status
                        })
                    }
                    // }
                });
                areas.map((area, index) => {

                    if (area.x && area.y) {
                        const tagsAreaCoords = [area.x, area.y, area.height, area.width]
                        const spanId = index + 1;
                        const div = document.getElementById(spanId);
                        if (!oldRequestKeys.includes(index)) {
                            requestsArray.push({
                                "coordinates": tagsAreaCoords,
                                "tags": mapToPunjabi(div.innerHTML),
                                "status": "pending"
                            })
                        } else {
                            if (allOldRequestsArray[index] && allOldRequestsArray[index].coordinates)
                                allOldRequestsArray[index].coordinates = tagsAreaCoords;
                            if (allOldRequestsArray[index])
                                allOldRequestsArray[index].tags = mapToPunjabi(div.innerHTML)
                        }
                    }
                });
                const body = {
                    "image_id": imageData.image_id,
                    requests: allOldRequestsArray,
                    new_tags: requestsArray,
                    "delete_request_ids": tagToBeDeleted
                };
                editImageTags.mutate(body);

            }
        }
    });


    const notifySuccess = (msg) => {
        toast.success(msg, {
            onClose: ({ uid }) => setDisabled(false)
        });

    }
    const notifyError = (msg) => {
        toast.error(msg, {
            onClose: ({ uid }) => setDisabled(false)
        });
    }
    //show success and error messages after update the tags
    useEffect(() => {

        if (editImageTags.isSuccess) {
            notifySuccess('Tags updated successfully');
            setTagToBeDeleted([])
        }
        else if (editImageTags.isError) {
            notifyError('Error while updating tags');
        }
    }, [editImageTags.isSuccess, editImageTags.isError])

    // fetch all image tags
    useEffect(() => {

        if (tagImage.isSuccess) {
            const oldTags = tagImage.data.data.requests;
            const allTags = []
            oldTags.map((data) => {
                const coords = data.coordinates;
                allTags.push({ x: coords[0], y: coords[1], height: coords[2], width: coords[3], unit: 'px', isNew: false, isChanging: false, tags: data.tags, image_request_id: data.image_request_id, image_tag_id: data.image_tag_id })
            })
            setAreas(allTags);
            setOldAreas(tagImage.data.data);
            setImageData(tagImage.data.data);
        }
        else if (tagImage.isError) {
            toast.error("Error in Fetching Data")
        }
        setLd(false)
    }, [tagImage.isSuccess, tagImage.isError, tagImage.data])


    //delete a image tag
    useEffect(() => {
        let deleteIndex = deleteTag - 1
        const allTags = [...areas];

        const deletedTags = tagToBeDeleted.length ? [...tagToBeDeleted] : []
        if (deleteIndex !== -1) {
            const deletedTag = allTags[deleteIndex];
            allTags.splice(deleteIndex, 1);
            if (deletedTag.image_tag_id)
                deletedTags.push(deletedTag.image_tag_id)
            setTagToBeDeleted(deletedTags)
            setAreas(allTags);
            setDeleteTag(null)
        }
    }, [deleteTag])

    useEffect(() => {
        tagImage.refetchWithId(image_id);
        return () => {
            tagImage.remove();
        }

    }, [id])


    // function to get all tags and set in a state
    const onChangeHandler = (areas1) => {
        setAreas(areas1);
    }

    //function to show delete btn and number on image tag
    const customRender = (areaProps) => {
        if (!areaProps.isChanging && areaProps.height && areaProps.width) {

            return (
                <div className={styles.wrapTxt} key={areaProps.areaNumber}>
                    <button type='button' className={styles.wtRemove} onClick={() => {
                        setDeleteTag(areaProps.areaNumber);
                    }}></button>
                    <span className={styles.arNo}>{areaProps.areaNumber}</span>
                </div>
            );
        }
    };


    function handleNext() {
        let ind = tagList.indexOf(image_id)
        if (ind < tagList.length - 1) {
            setimage_id(tagList[ind + 1])
            // get_my_pos_tags_by_request_id_hook.refetchWithId(tagList[ind+1])
            navigate(`/view_image_tagging/${tagList[ind + 1]}`)
            setLd(true)
        }
    }

    function handlePrev() {
        let ind = tagList.indexOf(parseInt(image_id))
        if (ind > 0) {
            setimage_id(tagList[ind - 1])
            navigate(`/view_image_tagging/${tagList[ind - 1]}`)
            setLd(true)
            // get_my_pos_tags_by_request_id_hook.refetchWithId(tagList[ind-1])
        }
    }


    return (
        <>
            <div className={`pageWrap lightWhite withCopy ${isOpened ? 'pbKBImgTg' : ''}`}>
                <div style={{ display: loading ? "block" : "none" }}>
                    <LoaderForAll />
                </div>
                <div style={{ display: loading ? "none" : "block" }} className={`${styles.pagecontainer} ${styles.pxC}`}>

                    <div className='flex justify-between gap-4 mb-3 items-center'>
                        <div className='flex gap-4 items-center'>
                            <div>
                                <button type="button" className='backTxtBtn' onClick={() => navigate('/view_taggings', {state: {selected_id: id}})}>Back</button>
                            </div>
                        </div>
                        <div className='flex gap-4 items-center'>
                            <div>
                                <button disabled={tagList.indexOf(image_id) === tagList.length-1 ? true : false } onClick={handleNext} className='btnOutline text-white font-semibold py-2 px-4'>Previous</button>
                            </div>
                            <div>
                                <button disabled={tagList.indexOf(image_id) === 0 ? true : false } onClick={handlePrev} className='btnOutline text-white font-semibold py-2 px-4'>Next</button>
                            </div>
                            <div>
                                <button onClick={() => navigate('/add_image_tagging')} type='button' className='btnPrimary text-white font-semibold py-2 px-4'>
                                    Add Tag
                                </button>
                            </div>
                        </div>
                    </div>




                    <form>
                        <div className='block lg:flex lg:gap-4'>

                            <div className='w-full lg:w-2/3 mb-4 lg:mb-0'>

                                <div className='grayBox rounded-xl flex h-full items-center'>
                                    {!loading &&
                                        <div className={`${styles.areaS}`}>
                                            <AreaSelector
                                                areas={areas}
                                                debug={true}
                                                minHeight={10}
                                                minWidth={10}
                                                wrapperStyle={{
                                                    border: '2px dashed blue',
                                                    backgroundColor: 'red',
                                                }}
                                                globalAreaStyle={{
                                                    border: 'dashed 2px blue',
                                                    backgroundColor: 'rgba(0,0,255,0.4)',
                                                    opacity: '1'
                                                }}
                                                onChange={onChangeHandler}
                                                customAreaRenderer={customRender}
                                            >
                                                <img src={imageData?.s3_url}  ></img>
                                            </AreaSelector>
                                        </div>
                                    }
                                    {loading &&
                                        <img src={imageData?.s3_url} onLoad={imageLoaded} ></img>
                                    }
                                </div>


                            </div>
                            <div className='w-full lg:w-1/3 mb-4 lg:mb-0'>
                                <div className={`${styles.textAreaPne} grayBox rounded-xl h-full`}>
                                    <TextTagging tags={areas}></TextTagging>

                                    <div className='flex justify-between px-2 pt-1 text-right'>
                                        <div>
                                            <button className={styles.kbtoggleBtn} onClick={toggleKB} title='Toggle Keyboard'><KeyboardAltOutlinedIcon /></button>
                                        </div>
                                        <div>
                                            <button disabled={disabled} onClick={formik.handleSubmit} className='btnPrimary text-white font-semibold py-3 px-6'>
                                                Update
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>

                </div>
                <div className={`${styles.keyBoardFloat} ${isOpened ? 'block' : 'hidden'}`}>
                    <button type='button' className='p-0 btnCloseKB' title='Close' onClick={toggleKB}><HighlightOffIcon /></button>
                    <KeyBoard />
                </div>
                <div className='copyFloat'><AuthCopy /></div>
            </div>
        </>
    )
}

export default EditImageTagging
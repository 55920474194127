import React from 'react'
import styles from '../../shared/common.module.css'
import AuthCopy from '../../../auth/shared/auth-copyright'
import Select from '../../services/select'
import GurbaniFilter from './gurbani-filter'
import GurbaniStatusTable from './gurbani-status-table'



function GurbaniFront() {

    return (
        <>
            <div className='pageWrap lightWhite withCopy'>


                <div className={`${styles.pagecontainer} ${styles.pxC}`}>
                    <div className='grayBox rounded-xl p-4 mb-5'>
                        <div className='flex justify-between items-center'>
                            <div><Select /></div>
                        </div>
                    </div>

                    {/* Index (1st screen) */}
                    <div className='grayBox rounded-xl pb-5 mb-4'>
                        <div className='text-center'>
                            <div className='inline-block'><img src="/svg/themeIcon.svg" alt="" /></div>
                        </div>
                        <div className={styles.selOptBox}>
                            <h2 className='text-primary mb-3 text-lg'>Select an Option</h2>
                            <ul className='list-none'>
                                <li>
                                    <button>Named Entity Recognition</button>
                                </li>
                                <li>
                                    <button>Parts of Speech</button>
                                </li>
                                <li>
                                    <button>Upload Corpus</button>
                                </li>
                                <li>
                                    <button>Gurbani</button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Details page (2nd) */}
                    <div className='block lg:flex lg:gap-4'>
                        <div className='w-full lg:w-1/3 mb-4 lg:mb-0'>
                            <div className='grayBox rounded-xl'>
                                <GurbaniFilter />
                                <GurbaniStatusTable />
                            </div>
                        </div>
                        <div className='w-full lg:w-1/3 mb-4 lg:mb-0'>
                            <div className='grayBox rounded-xl flex h-full items-center'>
                                <img src='/gurbani/Japji-1.png' alt="" />
                            </div>
                        </div>
                        <div className='w-full lg:w-1/3 mb-4 lg:mb-0'>
                            <div className={`${styles.textAreaPne} grayBox rounded-xl h-full`}>
                                <div className='p-4 h-[424px] overflow-auto'>
                                    Lorem Ipsum
                                </div>
                                <div className={`flex justify-end px-4 pb-4 ${styles.btnHldr}`}>
                                    <button className='btnPrimary text-white font-semibold px-5 py-3'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='copyFloat'><AuthCopy /></div>
            </div>
        </>
    )
}

export default GurbaniFront
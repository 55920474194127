import React, { useState } from 'react'
import LoaderForAll from '../../../components/loader-for-all'
import styles from '../../shared/common.module.css'
import { saveAs } from 'file-saver'

// import { BACKEND_URL } from '../../../config'


export function TagImgExpansionPop({setpop2,itm}) {
    const [load, setload] = useState(false)
    const downloadImage = (image) => {
      saveAs(image.s3_url, 'image.jpg') // Put your image URL here.
  }
    return (
        <>
        {load ? <LoaderForAll /> : 
        
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
                <button type='button' className='clsPopBtn' onClick={()=>setpop2(false)}>&times;</button>
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none overflow-hidden">
               
                <div className={`${styles.imgSizeFlex} relative p-4`}>
                    <div className={styles.downloadBtnWrap}>
                      {/* <button type='button' className={`${styles.downloadBtn} ml-4`} onClick={()=>downloadImage(itm)}><span></span>Download</button> */}
                    </div>                    
                    <img className='w-full rounded-xl' src={itm.s3_url} alt="" />
                </div>
                
                
              </div>
            </div>
          </div>
}
          <div className="fixed inset-0 z-40 popTrnsBg"></div>
        </>
    )
}


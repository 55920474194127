import React from 'react'

function DashCounter({ adminDashboardDetails, isUser }) {

    return (
        <>
            {!isUser &&
                <div className='counterBox flex text-center'>
                    <div className='flex-auto px-4 py-6'>
                        <div className='flex h-full flex-col items-center justify-center'>
                            <span className='cIco users mb-3'></span>
                            <h3>{adminDashboardDetails?.total_registration}</h3>
                            <p className='m-0 text-xs'>Total no. of Registrations</p>
                        </div>
                    </div>
                    <div className='flex-auto px-4 py-6'>
                        <div className='flex h-full flex-col items-center justify-center'>
                            <span className='cIco cate mb-3'></span>
                            <h3>{adminDashboardDetails?.total_categories}</h3>
                            <p className='m-0 text-xs'>Total no. of categories</p>
                        </div>
                    </div>
                    <div className='flex-auto px-4 py-6'>
                        <div className='flex h-full flex-col items-center justify-center'>
                            <span className='cIco images mb-3'></span>
                            <h3>{adminDashboardDetails?.total_sub_categories}</h3>
                            <p className='m-0 text-xs'>Total no. of Sub-Categories</p>
                        </div>
                    </div>

                </div>
            }
        </>
    )
}

export default DashCounter
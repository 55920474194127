import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import { Link } from "react-router-dom";
import styles from '../../pages/shared/common.module.css'
import { UploadCorpusSchema } from "../../auth/authSchema";
import { BACKEND_URL } from "../../config";
import { useMutation } from "react-query";
import axios from "axios";
import { CorpusImgExpansionPop } from "./expandImagePop";

export const AdminListObject = ({ itm, setdelete_s3, setisDeleted, setpop, CategoriesMeta }) => {
    const [isOpened, setisOpened] = useState(false)
    const Data = useSelector(state => state)
    const [file, setfile] = useState(null)
    const fileTypes = ['txt']

    const [pop2, setpop2] = useState(false)
    const navigate = useNavigate()



    const mutation = useMutation(
        {
            mutationFn: async (data) => {

                return await axios.post(BACKEND_URL + `/update_corpus_metadata`, data, {
                    headers: { Authorization: "Bearer " + Data.auth["access_token"] }
                }
                ).then(res => {
                    if (res.status === 200) {

                        toast.success("Updated")
                        // view_corpuses.refetch()
                        // setnewupload(false)
                    }
                    else {
                        toast.error("Error Occured")
                    }
                })
            },
            onSuccess: (data) => {
            }
        }
    )





    let formik = useFormik({
        initialValues: {
            name: itm.name,
            description: itm.description,
            source: itm.source,
            category: itm.category,
            sub_category: itm.sub_category,
            s3_uri: itm.s3_uri
        },
        validationSchema: UploadCorpusSchema,
        onSubmit: (values) => {

            var fd = new FormData()
            fd.append("name", values.name)
            fd.append("category", values.category)
            fd.append("sub_category", values.sub_category)
            fd.append("source", values.source)
            fd.append("description", values.description)
            fd.append("s3_uri", values.s3_uri)

            mutation.mutate(fd)
        }
    })

    function formatDate(inputDate) {
        const months = [
            "Jan", "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        var date = inputDate.split(' ')
        var d = date[0]
        var t = date[1]
        d = d.split('/')
        t = t.split(':')
        var din = d[0]
        var month = months[parseInt(d[1])]
        var year = d[2]
        var tm = parseInt(t[0]) > 12 ? 'pm' : 'am'
        var hr = parseInt(t[0]) > 12 ? String(parseInt(t[0]) - 12) : t[0]
        var mn = t[1]
        var ans = din + ' ' + month + ' ' + year + ', ' + hr + ':' + mn + ' ' + tm
        return ans
    }




    return (
        <>

            <div className='tBodyRow flex items-center py-2 rounded-md'>
                <div className='flex-none px-3'> {itm.s3_url !== '#' ?
                    <Link onClick={() => setpop2(itm.s3_url)}><img className='w-[60px]' src={itm.s3_url} alt="" /></Link>
                    : 
                    <img src="/svg/file_ux_icon.svg" alt="Icon" />
                    } 
                </div>
                <div className='flex-1 px-3'>
                    <p>{itm.name}</p>
                </div>
                <div className='flex-1 px-3'>
                    <p title={formatDate(itm.last_updated_on)}>{formatDate(itm.last_updated_on)}</p>
                </div>
                <div className='flex-1 px-3'>
                    <p>{itm.documented ? 'YES' : 'NO'}</p>
                </div>
                <div className='flex-1 px-3'>
                    <p>{itm.is_updated ? 'YES' : 'NO'}</p>
                </div>
                <div className='flex-1 px-3'>
                    <p>{itm.created_by}</p>
                </div>
                <div className='flex-1 px-3'>
                    <p>{itm.type_of_file}</p>
                </div>
                <div className='flex-1 px-3'>
                    <p>{itm.label}</p>
                </div>
                <div className='flex-1 px-3'>
                    <p><span className={`${styles.statLbl} ${itm.status}`}>{itm.status}</span></p>
                </div>
                <div className='flex-none w140 px-3 leading-none text-right'>
                    {/* <button type='btn' onClick={()=>  navigate('/adminDocumentCorpus' , {state: itm.s3_uri}) } className='btnView doc mx-2' title='Admin Doc'></button> */}
                    <button type='btn' onClick={() => setisOpened(p => !p)} className='btnView mx-2' title='View'></button>
                    <button onClick={() => {
                        setdelete_s3(itm.s3_uri)
                        setpop(true)
                    }} type='btn' className='btnDel mx-2' title='Remove'></button>
                </div>
            </div>



            {isOpened && (
                <div className="boxContent">

                    <div className='p-4 lg:p-6'>

                        <form className={`${styles.bdrFields} block max-w-screen-md mx-auto`}>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-10 mb-4'>
                                <div>
                                    <label className='block mb-2'>Name</label>
                                    <input name='name' id="name" value={formik.values.name} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                                </div>
                                <div>
                                    <label className='block mb-2'>Source of Data</label>
                                    <input name='source' id="source" value={formik.values.source} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                                </div>
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-10 mb-4'>
                                <div>
                                    <label className='block mb-2' htmlFor="category">Category</label>
                                    <select
                                        id="category"
                                        name="category"
                                        onChange={formik.handleChange}
                                        value={formik.values.category}
                                    >
                                        <option value="" label="Select Category" />
                                        {Object.keys(CategoriesMeta).map(ct => {
                                            return (
                                                <option value={ct} label={ct.toUpperCase()} />
                                            )
                                        })}

                                    </select>
                                </div>

                                <div>
                                    <label className='block mb-2' htmlFor="sub_category">Sub Category</label>
                                    <select
                                        id="sub_category"
                                        name="sub_category"
                                        onChange={formik.handleChange}
                                        value={formik.values.sub_category}
                                    >
                                        <option value="" label="Select Sub Category" />
                                        {CategoriesMeta[formik.values.category]?.map(ct => {
                                            return (
                                                <option value={ct} label={ct.toUpperCase()} />
                                            )
                                        })}

                                    </select>

                                </div>

                            </div>
                            <div className='grid grid-cols-1 gap-4 lg:gap-10 mb-4'>
                                <div>
                                    <label className='block mb-2'>Description of Data</label>
                                    <input name='description' id="description" value={formik.values.description} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                                </div>
                            </div>
                            {/* <div className='mb-5'>
                            <FormControlLabel required control={<Checkbox sx={{
                                color: grey[400],
                                '&.Mui-checked': {
                                    color: grey[200],
                                },
                            }} />} label="The content shared above is licensed." />
                        </div> */}
                            <div>
                                <button onClick={formik.handleSubmit} className='btnPrimary text-white font-semibold py-3 px-6'>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {pop2 ? <CorpusImgExpansionPop setpop2={setpop2} url={pop2}/> : null}


        </>
    )
}

import React, { useState, useEffect } from 'react'

import LoaderForAll from '../../components/loader-for-all'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'
import { useGetAllTags, useGetMyTags } from './hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from '../../pages/shared/common.module.css'
import AuthCopy from '../../auth/shared/auth-copyright';
import AdminListNerObj from './listObj';

function AdminNERList() {

  const navigate = useNavigate();
  const [tagList, settagList] = useState([])
  const get_ner_tags_hook = useGetAllTags()
  const location = useLocation()
  const [Selected, setSelected] = useState(parseInt(location.state?.selected_id) )



  useEffect(() => {
    if (get_ner_tags_hook.isSuccess) {
      if (get_ner_tags_hook.data.data.length === 0) {
        settagList([])

      }
      else {

        settagList(get_ner_tags_hook.data.data)
      }


    }
    else if (get_ner_tags_hook.isError) {
      toast.error("Error is Fetching Data")
    }
  }, [get_ner_tags_hook.isSuccess, get_ner_tags_hook.isError, get_ner_tags_hook.data])


  useEffect(() => {
    get_ner_tags_hook.refetch()

    return () => {
        get_ner_tags_hook.remove()
    }
  }, [])

  function cmp(a,b) {
    const [dateParta, timeParta] = a.last_updated_on.split(' ');
    const [daya, montha, yeara] = dateParta.split('/');
    const [houra, minutea, seconda] = timeParta.split(':');
    const [datePartb, timePartb] = b.last_updated_on.split(' ');
    const [dayb, monthb, yearb] = datePartb.split('/');
    const [hourb, minuteb, secondb] = timePartb.split(':');


    const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
    const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
    return dateB - dateA;
      
    
}




  return (
    <>
      {get_ner_tags_hook.isLoading ? <LoaderForAll /> :
        <div className='pageWrap lightWhite withCopy'>

          <div className='px-5 py-4 lg:px-8'>
            {/* <button type="button" className='backTxtBtn' onClick={() => navigate('/contribution')}>Back</button> */}

            <div className='grayBox rounded-xl p-0'>
              <div className='mb-4 flex justify-between px-4 pt-4'>
                <div>
                  <h2 className='text-primary mb-0 text-lg'>NER Corpus</h2>
                </div>
                {/* <div>
                  <button type="button" className='px-4 btnPrimary text-white font-semibold py-2' onClick={() => navigate('/nerCreateNew')}>Get New Document</button>
                </div> */}
              </div>
              <div className='tableListWrap pb-2'>
                <div className='contTable text-xs min-w-[1200px] px-4'>
                  <div className='tableHead gray flex items-center py-2'>
                  <div className='flex-1 px-3'>NER Request ID</div>
                    <div className='flex-1 px-3'>Document ID</div>
                    <div className='flex-1 px-3'>Document</div>
                    <div className='flex-1 px-3'>Assignee Name</div>
                    <div className='flex-1 px-3'>NER Status</div>
                    <div className='flex-1 px-3'>Last Update On</div>
                    <div className='flex-1 px-3'>&nbsp;</div>
                  </div>
                  <div className='tableBody'>
                    {tagList.sort(cmp).map((itm) => {
                      if (Selected === itm.ner_request_id){
                        return (
                          <div className='dBorder' key={itm.s3_uri}>
                            <AdminListNerObj itm={itm} />
                          </div>
                        )
                      }
                      return (
                        <div key={itm.s3_uri}>
                          <AdminListNerObj itm={itm} />
                        </div>
                      )

                    })}



                  </div>
                </div>
              </div>
              <div className='copyFloat'><AuthCopy /></div>
            </div>

          </div>
        
        </div>}
    </>
  )
}

export default AdminNERList

import { useState } from 'react';
import ProgressBar from 'react-percent-bar';

function TopCatList(adminDashboardDetails) {

    const categoryData = adminDashboardDetails?.adminDashboardDetails;

    return (
        <>
        {adminDashboardDetails?.adminDashboardDetails?.length>=1 && 
        <>
            <div className='px-4 py-3'>
                <h6>Top Categories</h6>
                <p className='text-xs'>List of top categories explored by people.</p>
            </div>
            <div className='tableWrap tc'>
                <table className='dashTable'>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Sub-category</th>
                            <th>Progress</th>
                        </tr>
                    </thead>  
                    <tbody>

                    {categoryData?.map((itm,index) => {
                         return (
                        <tr key={index}>
                            <td>{itm?.category}</td>
                            <td>{itm?.sub_category}</td>
                            <td>
                                <div className='inline-block pr-3'>
                                    <ProgressBar borderColor="#585858" percent={Math.round(itm?.total_percentage)} fillColor="#44A0DF" width="120px" height="6px" />
                                </div>
                                <strong className='text-primary'>{Math.round(itm?.total_percentage)} %</strong>
                            </td>
                        </tr>
                        )
                    }
                    )}
             
                    </tbody>                  
                </table>
            </div>
            </>
}
        </>
    )
}

export default TopCatList
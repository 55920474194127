import React, { useState,useEffect } from "react";
import Crop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styles from '../../shared/common.module.css'
import demoImage from "./logo192.png";

function ImageCropper(props) {
  const { imageToCrop, onImageCropped,isPopUp } = props;
  const [pop, setpop] = useState(false);
  const [cropConfig, setCropConfig] = useState(
    // default crop config
    {
      unit: '%', // Can be 'px' or '%'
      x: 0,
      y: 0,
      width: 100,
      height: 100
    }
  );
 // setpop(true)
  const [imageRef, setImageRef] = useState();

  const [croppedImage, setCroppedImage] = useState(false)
  async function cropImage(crop) {
    if (imageRef && crop.width && crop.height) {
      const cropImage = await getCroppedImage(
        imageRef,
        crop,
        "croppedImage.png" // destination filename
      );
     // onImageCropped(cropImage);
      setCroppedImage(cropImage)
      
    }
  }

  async function applyPhoto (){
    props.uploadStatus(false)
    if(croppedImage)
      onImageCropped(croppedImage);
    else
      onImageCropped({image:'all'});

      setpop(pop => !pop);
  }

  function getCroppedImage(sourceImage, cropConfig, fileName) {
    // creating the cropped image from the source image
    const canvas = document.createElement("canvas");
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        // returning an error
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        blob.name = fileName;
        // creating a Object URL representing the Blob object given
        const file = new File([blob], { type: "image/png" });
        const croppedImageUrl = window.URL.createObjectURL(blob);


        resolve({ blob: croppedImageUrl, file: file });
      }, "image/png");
    });
  }


  return (
    <>
      {(imageToCrop ) &&
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative z-45 my-6 mx-auto max-w-[480px]">
            <button type='button' className='clsPopBtn' onClick={()=>props.uploadStatus(false)}>&times;</button>
            <div className={`${styles.darkModalBG} rounded-lg p-6`}>
              <Crop
                src={imageToCrop}
                crop={cropConfig}
                ruleOfThirds
                onImageLoaded={(imageRef) => setImageRef(imageRef)}
                onComplete={(cropConfig) => cropImage(cropConfig)}
                onChange={(cropConfig) => setCropConfig(cropConfig)}
                crossorigin="anonymous" // to avoid CORS-related problems
              />
              <div className="pt-3 text-center">
                <button type="button" onClick={()=>applyPhoto()} className='px-4 btnPrimary text-white font-semibold py-2'>Apply</button>
              </div>
            </div>
          </div>
        </div>  
        <div className="fixed inset-0 z-40 popTrnsBg"></div>
        </>      
      }
      
    </>
  );
}

ImageCropper.defaultProps = {
  onImageCropped: () => { }
};

export default ImageCropper;

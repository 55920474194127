import React from 'react'
import LoaderForAll from '../../components/loader-for-all'
import { useState } from 'react'
import { BACKEND_URL } from '../../config'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export function DocumentCorpusPop({setdocumentPop , s3_uri , document_corpus_hook }) {





    return (
        <>
        
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
               
                <div className="relative py-2 px-4 flex-auto">
                  <p className="my-4 text-black text-lg leading-relaxed">
                    Are you sure want to Document This corpus ?
                  </p>
                </div>

                <div className='flex justify-center items-center pb-4'>
                    <div className='px-2'>
                        <button type='button' className="px-4 btnPrimary text-white font-semibold py-2 outline-none focus:outline-none" onClick={()=>document_corpus_hook.refetch()}>Yes</button>
                    </div>
                    <div className='px-2'>
                        <button className="p-1 ml-auto bg-transparent border-0 text-black float-right leading-none font-semibold outline-none focus:outline-none" onClick={()=> {
                            setdocumentPop(false)}}>No</button>
                    </div>
                </div>
               
                
                
              </div>
            </div>
          </div>

          <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}


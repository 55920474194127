import * as React from 'react'
import { Link } from 'react-router-dom'
import styles from '../../shared/common.module.css'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'

function FilterSelect() {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div className={styles.dropbox}>
                    <button type="button" onClick={handleClick}>
                        Select <ArrowDropDownOutlinedIcon />
                    </button>
                    {open ? (
                        <div className={styles.dropped}>
                            <div className={styles.ddItem}>
                                <Link className='p-2' to=''>Option 1</Link>
                            </div>
                            <div className={styles.ddItem}>
                                <Link className='p-2' to=''>Option 2</Link>
                            </div>
                        </div>
                    ) : null}
                </div>
            </ClickAwayListener>
        </>
    )
}

export default FilterSelect
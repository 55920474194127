import React, { useEffect, useState, useRef } from 'react'
import styles from '../../pages/shared/common.module.css'
import { useNavigate } from 'react-router-dom'
import { useUpdateSpeechTags, useAdminUpdateSpeechTags } from './hooks';
import 'react-toastify/dist/ReactToastify.css';
//import AudioReactRecorder, { RecordState } from 'audio-react-recorder'

//import { TokenAnnotator } from "react-text-annotate";
//import LoaderForAll from '../../../components/loader-for-all'
//import { POS_TAGS } from '../../../config';
import { ErrorBoundary } from 'react-error-boundary';
import { useReactMediaRecorder } from "react-media-recorder";
//import SpeechTextPane from './speech-textpane'
import MicRecorder from "mic-recorder-to-mp3"
import { UpdatePosStatusPop } from './updatePop'
import { ToastContainer, toast } from 'react-toastify';

const mimeType = "audio/mp4";
const SpeechTagEdit = ({ tagDocument, id, tagList, setspeech_request_id }) => {

    const [speech_request_id, setpos_request_id] = useState(id)
    const recorder = useRef(null) //Recorder
    const audioPlayer = useRef(null) //Ref for the HTML Audio Tag
    const [blobURL, setBlobUrl] = useState(null)
    const [allBlobs, setAllBlobs] = useState([])
    const [StatusPop, setStatusPop] = useState(null)
    const [disabled, setDisabled] = useState(false);
    const [audioFile, setAudioFile] = useState(null)
    const [isRecording, setIsRecording] = useState(null)
    const sTagValue = tagDocument
    const mediaRecorder = useRef(null);
    const navigate = useNavigate();
    const [isSubmit, setIsSubmit] = useState(false);
    const updatePosTags_hook = useUpdateSpeechTags()
    const [second, setSecond] = useState("00");
    const [minute, setMinute] = useState("00");
    const [isActive, setIsActive] = useState(false);
    const [counter, setCounter] = useState(0);
    const [isPlay, setIsPlay] = useState(false);
    const admin_update_speech_tag = useAdminUpdateSpeechTags()
    const audioSrc = ''
    let audioRef = useRef(new Audio(audioSrc));
    const [audioData, setAudioData] = useState({
        playing: false,
        currentTime: 0,
        duration: 0,
        volume: 1,
        currentTrack: 0,
        tracks: [],
    })
    const notifySuccess = (msg) => {
        toast.success(msg, {
            onClose: ({ uid }) => setIsSubmit(false)
        });

    }
    const notifyError = (msg) => {
        toast.error(msg, {
            onClose: ({ uid }) => setIsSubmit(false)
        });

    }

    useEffect(() => {
        setStatusPop(null)
        if (admin_update_speech_tag.isSuccess) {

            let ind = tagList.indexOf(speech_request_id)
            if (ind < tagList.length - 1) {
                setspeech_request_id(tagList[ind + 1])
                // get_my_ner_tags_by_request_id_hook.refetchWithId(tagList[ind+1])
                navigate(`/admin_speech_tag_view/${tagList[ind + 1]}`)
            }
            else {

                navigate('/admin-speech-tags')
                toast.success("Uploaded Success")
                setIsSubmit(false)
            }
            // notifySuccess("Uploaded Success")

            // setTimeout(() => {
            //     navigate('/admin-speech-tags')
            // }, 2000);
        }
        else if (admin_update_speech_tag.isError) {
            toast.error("Error is uploading")
            setIsSubmit(false)
            // notifyError("Error is uploading")
        }

    }, [admin_update_speech_tag.isError, admin_update_speech_tag.isSuccess])

    //this is used to record the audio
    useEffect(() => {
        try {
            let intervalId;
            if (isActive) {
                intervalId = setInterval(() => {

                    const secondCounter = counter % 60;
                    const minuteCounter = Math.floor(counter / 60);
                    if (counter == 1) {
                        //startRecording();
                    }
                    let computedSecond =
                        String(secondCounter).length === 1
                            ? `0${secondCounter}`
                            : secondCounter;
                    let computedMinute =
                        String(minuteCounter).length === 1
                            ? `0${minuteCounter}`
                            : minuteCounter;

                    setSecond(computedSecond);
                    setMinute(computedMinute);

                    setCounter((counter) => counter + 1);

                }, 1000);
            }

            return () => clearInterval(intervalId);
        } catch (e) {
            return <ErrorHandler error={e} />
        }
    }, [isActive, counter]);

    let audioUrl = ''
    if (Object.keys(sTagValue || []).length) {
        audioRef.current = document.getElementById('audio');
        audioUrl = sTagValue.speech_url;
        audioUrl = sTagValue.speech_url;
    }

    // //this function is used to show the errors
    const ErrorHandler = ({ error }) => {
        return (
            <div role="alert">
                <p>An error occurred:</p>
                <pre>{error.message}</pre>
            </div>
        )
    }
    // const { playing, currentTime, duration, volume, currentTrack, tracks } = audioData;

    useEffect(() => {
        recorder.current = new MicRecorder({ bitRate: 128 })
    }, [])

    const startRecording = async () => {

        recorder.current.start().then(() => {
            setIsRecording(true)
        })
    }

    useEffect(() => {
        const blobs = allBlobs
        if (blobs.length) {
            const concatenatedBlob = new Blob(blobs, { type: 'audio/mpeg' });
            const newBlobUrl = URL.createObjectURL(concatenatedBlob)
            setBlobUrl(newBlobUrl)
            setIsRecording(false)
            setIsSubmit(true);
        }
    }, [allBlobs])

    const stopRecording = () => {
        recorder.current
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                const file = new File(buffer, "audio.mp3", {
                    type: 'audio/mpeg',
                    lastModified: Date.now(),
                })
                setAudioFile(file)
                const blobs = [...allBlobs, blob]
                setAllBlobs(blobs)

            })
            .catch((e) => console.log(e))
    }

    const submitSpeechTags = async () => {
        try {

            if (blobURL && blobURL.search('blob:') != -1) {
                const fd = new FormData()
                const audioBlob = await fetch(blobURL).then((r) => r.blob());
                const audioFile = new File([audioBlob], 'voice.mp3', { type: 'audio/mpeg' });
                fd.append("speech_file", audioFile);
                fd.append("speech_request_id", sTagValue.speech_request_id)
                sTagValue.document.forEach((item) => fd.append("document[]", item));
                updatePosTags_hook.mutate(fd)
            } else {
                toast.success("No new audio found")
            }

        } catch (error) {
            return <ErrorHandler error={error} />
        }
    }

    useEffect(() => {
        try {
            if (updatePosTags_hook.isSuccess) {
                toast.success("Speech tags updated")
                navigate('/view_speech_taggings')
            }
            else if (updatePosTags_hook.isError) {
                toast.error("Upload Failed")
            }
        } catch (e) {
            return <ErrorHandler error={e} />
        }
    }, [updatePosTags_hook.isError, updatePosTags_hook.isSuccess])

    return (

        <>
            <ErrorBoundary FallbackComponent={ErrorHandler}>


                <div className='actions grayBox rounded-xl'>

                <div>
                            <div className={`${styles.actWrap} p-3`}>
                                <div className='block lg:flex gap-4'>

                                    <div className='lg:flex-1 mb-3 lg:mb-0'>
                                        <p className={`${styles.capt} mb-1`}>Play and listen the recording, to ensure its accuracy.</p>
                                        <div className={`${styles.playBtnsBx} flex rounded-xl px-3 py-1`}>
                                            <div className='flex w-full items-center py-[2px]'>
                                                <div className='flex-none w-full'>
                                                    {/* {(audio ) &&  */}
                                                    <audio ref={audioPlayer} src={blobURL ? blobURL : audioUrl} controls='controls' id='audio' />
                                                    {/* <audio  className={`${styles.playBtn} w-full`} title="Play" src={audio?audio:audioUrl} id='audio' controls={true}  /> */}

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='flex items-center justify-end gap-2 px-2 pt-2'>
                                        <div>
                                            <button onClick={(e) => {
                                                e.preventDefault()
                                                setStatusPop('reviewed')
                                            }

                                            } className='btnPrimary text-white font-semibold py-4 px-6 mx-2'>
                                                Review
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                </div>
            </ErrorBoundary>
            {StatusPop !== null && <UpdatePosStatusPop StatusPop={StatusPop} setStatusPop={setStatusPop} admin_update_speech_tag={admin_update_speech_tag} speech_request_id={speech_request_id} />}
        </>
    )
}


export default SpeechTagEdit

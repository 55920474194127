//export const BACKEND_URL = 'https://api.dev.panjabi.ai' ;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const ML_BACKEND = 'https://nlp-toolkit.panjabi.ai'
export const WORD_LIMIT = 5000

export const SERVICE_OPTIONS = {'ner':'Named Entity Recognition','pos':'Part of Speech Recognition','translation':'Translation','transliteration':'Machine Transliteration'}
export const MODAL_OPTION = {'ner_allennlp':'ner_allennlp','ner_ktrain':'ner_ktrain','pos':'pos','pos_rule_based':'pos_rule_based'}
export const LANGUAGE_OPTION = {'punjabi':'Punjabi to English','english':'English to Punjabi'}
export const NER_TAGS = ['animal', 'date', 'day', 'designation', 'dialect', 'distance', 'duration', 'entertainment', 'event', 'facilities', 'food', 'location', 'locomotive', 'money', 'month', 'number', 'organisation', 'person', 'plant', 'profession', 'relation', 'religion', 'time', 'unit', 'year']
export const POS_TAGS = ['N_NN', 'N_NNP', 'N_NST', 'PR_PRP', 'PR_PRF', 'PR_PRL', 'PR_PRC', 'PR_PRQ', 'PR_PRI', 'DM_DMD', 'DM_DMR', 'DM_DMQ', 'DM_DMI', 'V_VM', 'V_VM_VNF', 'V_VM_VF', 'V_VM_VNG', 'V_VAUX', 'JJ', 'RB', 'PSP', 'CC_CCD', 'CC_CCS', 'RP_RPD', 'RP_INJ', 'RP_INTF', 'RP_NEG', 'QT_QTF', 'QT_QTC', 'QT_QTO']
export const LABELS = ["Named Entity Recognition","Part of Speech Tagging","Text to Speech & vice versa","Image Data","Machine Translation (Parallel Corpus)","Machine Transliteration (Parallel Corpus)","Raw Corpus (Non Annotated Data)","Other"]
export const API_Details = {
    "translation": {
        "request_type": "POST",
        "default": "pun2engtranslation",
        "endpoint": {
            "pun2engtranslation": {
                "request_body": "{ text: ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ }",
                "url": "https://nlp-toolkit.panjabi.ai/pun2engtranslation",
                "response_body": `{
    input_text: ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ,
    pun2engtranslation: Pakistan SEEKS help from Sikhs settled abroad to complete construction of Kartarpur corridor. 
}`
            },
            "eng2puntranslation": {
                "request_body": "{ text: I live in chandighar }",
                "url": "https://nlp-toolkit.panjabi.ai/eng2puntranslation",
                "response_body": `{
    eng2puntranslation:  ਮੈਂ ਚੰਡੀਗੜ੍ਹ ਰਹਿੰਦਾ ਹਾਂ। ,
    input_text: i live in chandighar
}`
            }
        }
    },
    "machine_transliteration": {
        "request_type": "POST",
        "default": "pun2engtransliteration",
        "endpoint": {
            "pun2engtransliteration": {
                "request_body": "{ text: ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ }",
                "url": "https://nlp-toolkit.panjabi.ai/pun2engtransliteration",
                "response_body": `{
    input_text: ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ,
    pun2engtransliteration: pakistan ne kartarpur laange dee usarii puree krnn lai videen a chh vassde sikhaan toun mangi madad
}`
            },
            "eng2puntransliteration": {
                "request_body": "{ text: I live in chandighar }",
                "url": "https://nlp-toolkit.panjabi.ai/eng2puntransliteration",
                "response_body": `{
    eng2puntransliteration: ਆਈ ਲਾਈਵ ਇਨ ਚੰਡੀਘਰ,
    input_text: I live in chandighar
}`
            }
        }
    },
    "ner": {
        "request_type": "POST",
        "default": "ner_allennlp",
        "endpoint": {
            "ner_ktrain": {
                "request_body": "{ text: ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ }",
                "url": "https://nlp-toolkit.panjabi.ai/ner_ktrain",
                "response_body": `{
input_text: ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ,
ner_ktrain: 
    [
        ["ਪਾਕਿਸਤਾਨ","B-location"],
        ["ਨ","O"],
        ["ੇ","O"],
        ["ਕਰਤਾਰਪੁਰ","B-location"],
        ["ਲਾਂਘ","O"],
        ["ੇ","O"],
        ["ਦ","O"],
        ["ੀ","B-entertainment"],
        ["ਉਸਾਰ","I-entertainment"],
        ["ੀ","I-entertainment"],
        ["ਪੂਰ","I-entertainment"],
        ["ੀ","I-entertainment"],
        ["ਕਰਨ","O"],
        ["ਲਈ","O"],
        ["ਵਿਦੇਸ਼","O"],
        ["ਾ","O"],
        ["ਂ","O"],
        ["'","O"],
        ["ਚ","O"],
        ["ਵੱਸਦ","O"],
        ["ੇ","O"],
        ["ਸਿੱਖ","B-religion"],
        ["ਾ","O"],
        ["ਂ","O"],
        ["ਤ","O"],
        ["ੋ","O"],
        ["ਂ","O"],
        ["ਮੰਗ","O"],
        ["ੀ","O"],
        ["ਮਦਦ","O"]
    ]
}`
            },
            "ner_allennlp": {
                "request_body": "{ text: ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ }",
                "url": "https://nlp-toolkit.panjabi.ai/ner_allennlp",
                "response_body": `{
input_text: ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ,
ner_allennlp: 
    [
        ["ਪਾਕਿਸਤਾਨ","U-location"],
        ["ਨੇ","O"],
        ["ਕਰਤਾਰਪੁਰ","U-location"],
        ["ਲਾਂਘੇ","O"],
        ["ਦੀ","O"],
        ["ਉਸਾਰੀ","O"],
        ["ਪੂਰੀ","O"],
        ["ਕਰਨ","O"],
        ["ਲਈ","O"],
        ["ਵਿਦੇਸ਼ਾਂ","O"],
        ["'","O"],
        ["ਚ","O"],
        ["ਵੱਸਦੇ","O"],
        ["ਸਿੱਖਾਂ","O"],
        ["ਤੋਂ","O"],
        ["ਮੰਗੀ","O"],
        ["ਮਦਦ","O"]
    ]
}`
            }
        }
    },

    "pos": {
        "request_type": "POST",
        "default": "pos",
        "endpoint": {
            "pos": {
                "request_body": "{ text: ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ }",
                "url": "https://nlp-toolkit.panjabi.ai/pos",
                "response_body": `{
input_text": ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ,
pos: 
    [
        ["ਪਾਕਿਸਤਾਨ","N_NNP"],
        ["ਨੇ","PSP"],
        ["ਕਰਤਾਰਪੁਰ","N_NNP"],
        ["ਲਾਂਘੇ","N_NNP"],
        ["ਦੀ","PSP"],
        ["ਉਸਾਰੀ","N_NN"],
        ["ਪੂਰੀ","JJ"],
        ["ਕਰਨ","V_VM_VNF"],
        ["ਲਈ","PSP"],
        ["ਵਿਦੇਸ਼ਾਂ","N_NN"],
        ["'ਚ","N_NNP"],
        ["ਵੱਸਦੇ","N_NNP"],
        ["ਸਿੱਖਾਂ","N_NNP"],
        ["ਤੋਂ","PSP"],
        ["ਮੰਗੀ","V_VM_VF"],
        ["ਮਦਦ","N_NNP"]
    ]
}`
            },
            "pos_rule_based": {
                "request_body": "{ text: ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ }",
                "url": "https://nlp-toolkit.panjabi.ai/pos_rule_based",
                "response_body": `{
input_text: ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ,
pos_rule_based: 
    [
        ["ਪਾਕਿਸਤਾਨ","N_NNP"],
        ["ਨੇ","PSP"],
        ["ਕਰਤਾਰਪੁਰ","N_NNP"],
        ["ਲਾਂਘੇ","N_NN"],
        ["ਦੀ","PSP"],
        ["ਉਸਾਰੀ","N_NN"],
        ["ਪੂਰੀ","JJ"],
        ["ਕਰਨ","V_VM_VNF"],
        ["ਲਈ","PSP"],
        ["ਵਿਦੇਸ਼ਾਂ","N_NN"],
        ["'ਚ","PSP"],
        ["ਵੱਸਦੇ","N_NNP"],
        ["ਸਿੱਖਾਂ","N_NNP"],
        ["ਤੋਂ","PSP"],
        ["ਮੰਗੀ","N_NNP"],
        ["ਮਦਦ","N_NN"]
    ]
}`
            }
        }
    }
}


export const POS_guide_tags = {
    N_NN: 'Common Noun',
    N_NNP: 'Proper Noun',
    N_NST: 'Noun loc',
    PR_PRP: 'Personal Pronoun',
    PR_PRF: 'Reflexive Pronoun',
    PR_PRL: 'Relative Pronoun',
    PR_PRC: 'Reciprocal Pronoun',
    PR_PRQ: 'Wh-word Pronoun',
    PR_PRI: 'Indefinite',
    DM_DMD: 'Deictic Demonstrative',
    DM_DMR: 'Relative Demonstrative',
    DM_DMQ: 'Wh-word Demonstrative',
    DM_DMI: 'Indefinite Demonstrative',
    V_VM: 'Main Verb',
    V_VM_VNF: 'Non-finite Verb',
    V_VM_VF: 'Infinitive Verb',
    V_VM_VNG: 'Gerund Verb',
    V_VAUX: 'Auxiliary Verb',
    JJ: 'Adjective',
    RB: 'Adverb',
    PSP: 'Postposition',
    CC_CCD: 'Co-ordinator',
    CC_CCS: 'Subordinator',
    RP_RPD: 'Default Particles',
    RP_INJ: 'Interjection Particles',
    RP_INTF: 'Intensifier Particles',
    RP_NEG: 'Negation',
    QT_QTF: 'General',
    QT_QTC: 'Cardinals',
    QT_QTO: 'Ordinals',
  };

export const NER_guide_tags = {
    Person: 'Name of the persons only.',
    Org: 'Name of organizations, political parties, company etc.',
    Location: 'Name of famous locations (taj mahal, delhi, some xyz street/road etc)',
    Duration: 'Time duration like 2 hours, 3 weeks, 4 days, etc.',
    Food: 'Food items like Daal, Roti, Samosa, chaa (tea), etc.',
    Animal: 'Names of animals like Sher, Hathi',
    Plant: 'Names of plants like snake plant, basically any flowers/flora where the word contextually represents plant and not a name like Sandalwood( ਚੰਦਨ) and lotus( ਕਮਲ).',
    Date: 'Date format like 15-02-2023 or 15/02/2023 or 15th February',
    Month: 'Names of months According to both English and Desi calendar',
    Year: 'Like 2020, 2023',
    Time: 'Like 4 vaje, raat 4 vaje',
    Day: 'Days of the week',
    Unit: 'Any unit like, kg, feet, etc. (anything other than distance/money)',
    Number: 'Any number which is mentioned either in numerical format or textual',
    Entertainment: 'Festivals, movies',
    Money: 'Eg- 100 Rupees, 1 trillion dollars',
    Locomotive: 'Like train, car, jeep, plane etc. Any transportation vehicle',
    Distance: '120 kilometer',
    Dialect: 'Languages like Punjabi, English',
    Facilities: 'Publically Available places like airports, bus stands (not specific names otherwise it will fall into location) Eg: I went to the library yesterday. (Here library will be a facility) I went to Ludhiana Public Library. Here Ludhiana public library will be a location',
    Religion: 'Names of religion like Sikh, Hindu, Muslim etc',
    Profession: 'Profession like Engineer, army sergeant, etc (in general context)',
    Event: 'Marriage, birthday party, Mela',
    Designation: 'Something that is used before a person"s name like Dr, Er etc Dr. Rajendra Prasad Dr. -> Designation Rajendra Prasad -> Person',
    Relation: 'Relations like sister/son/daughter/mother/father etc.',
  };
  
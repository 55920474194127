import React, { useState, useEffect } from 'react'
import styles from '../../shared/common.module.css'
import AuthCopy from '../../../auth/shared/auth-copyright'
import Select from '../../services/select'
import DragDrop from './dropfiles'
import CorpusContent from './corpus-content'
import CorpusListing from './corpus-listing'
import { useMutation, useQuery } from 'react-query'
import { BACKEND_URL } from '../../../config'
import axios from 'axios'
import { useFormik } from 'formik';
import { UploadCorpusSchema } from '../../../auth/authSchema'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'
import LoaderForAll from '../../../components/loader-for-all'
import { useGetPreAssignedUrl, useUploadCorpusToS3, useUploadNewCorpus, useViewCorpuses } from './hooks'
import { Navigate, useNavigate, useSearchParams, createSearchParams } from 'react-router-dom'
import { FileUpload, Flare } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { redux_logout } from "../../../auth/authSlice";

function UploadNew() {

    const Data = useSelector(state => state)
    const [newupload, setnewupload] = useState(false)
    const fileTypes = ['txt']
    const [file, setFile] = useState(null);
    const [viewcorpus, setviewcorpus] = useState(false)
    const upload_corpus_hook = useUploadNewCorpus()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const tabId = searchParams.get('tabId');
    // const upload_s3_hook = useUploadCorpusToS3()
    const { upload_s3_hook, Progress, setUploadCancelToken, uploadCancelToken } = useUploadCorpusToS3();
    let dispatch = useDispatch()
    const [S3_key, setS3_key] = useState('')
    const [fileUploaded, setfileUploaded] = useState(false)

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            source: "",
            category: "",
            sub_category: "",
            label: ""
        },
        // validationSchema: UploadCorpusSchema,
        onSubmit: (values) => {
            if (validateForm(values)) {
                return
            }

            var fd = new FormData()
            fd.append("name", values.name)
            fd.append("category", values.category)
            fd.append("sub_category", values.sub_category)
            fd.append("label", values.label)
            fd.append("source", values.source)
            fd.append("description", values.description)
            fd.append("s3_key", S3_key)
            upload_corpus_hook.mutate(fd)
        }
    });

    const validateForm = (values) => {
        let isError = false;
        if (values.name === '') {
            toast.error("Name field is empty")
            isError = true
        } else if (values.source === '') {
            toast.error("Source field is empty")
            isError = true
        } else if (values.category === '') {
            toast.error("Category field is empty")
            isError = true
        } else if (values.sub_category === '') {
            toast.error("Sub category field is empty")
            isError = true
        } else if (values.description === '') {
            toast.error("Description field is empty")
            isError = true
        } else if (values.label === '') {
            toast.error("label is empty")
            isError = true
        } else if (S3_key === '' || FileUpload === false) {
            toast.error("Upload a file")
            isError = true
        }
        return isError;
    }

    useEffect(() => {
        if (upload_corpus_hook.isSuccess) {
            toast.success("Uploaded")
            navigate('/view_corpuses')
        }
        else if (upload_corpus_hook.isError) {
            toast.error("Upload Failed")
        }
    }, [upload_corpus_hook.isSuccess, upload_corpus_hook.isError])



    async function UploadToS3Handle() {
        if (file == null) {
            toast.error("Please Select File")
        }
        const response = await axios.request({
            headers: {
                Authorization: `Bearer ${Data.auth["access_token"]}`
            },
            method: "GET",
            url: BACKEND_URL + `/generate_pre_assigned_url?content_type=${file.type}&ext=${file.name.split('.').slice(-1)}`
        })
        if (response.status === 200) {
            let url = response.data.url.url
            var formData = new FormData()
            setS3_key(response.data.url.fields.key)
            console.log(response.data.url.fields.key, "KEY")
            formData.append('key', response.data.url.fields.key)
            formData.append("x-amz-algorithm", response.data.url.fields['x-amz-algorithm'])
            formData.append("x-amz-credential", response.data.url.fields['x-amz-credential'])
            formData.append("x-amz-date", response.data.url.fields['x-amz-date'])
            formData.append("policy", response.data.url.fields.policy)
            formData.append("x-amz-signature", response.data.url.fields['x-amz-signature'])
            formData.append("file", file)
            upload_s3_hook.mutate({ url, formData })

        }
        else if (response.data.message == "Please login first") {
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
        }
        else {
            toast.error("Unable to Connect to Server, Try again")
        }
    }

    useEffect(() => {
        if (upload_s3_hook.isSuccess) {
            if (upload_s3_hook.data === 'Upload cancelled by user.' || upload_s3_hook.data === 'Error during file upload') {
                toast.error(upload_s3_hook.data)
            }
            else {

                toast.success("Uploaded Success")
                setfileUploaded(true)
            }

        }
        else if (upload_s3_hook.isError) {

            toast.error("Error in uploading file")
        }

    }, [upload_s3_hook.isError, upload_s3_hook.isSuccess])

    function handleCancel() {
        if (uploadCancelToken) {
            uploadCancelToken.cancel('Upload cancelled by user.')
        }
    }






    return (
        <>

            {upload_corpus_hook.isLoading ? <LoaderForAll /> :
                <>
                    <div className='pageWrap lightWhite withCopy'>
                        <div className='px-5 lg:px-8 pt-4'>
                            <div>
                                <button type="button" onClick={() => navigate({
                                    pathname: '/view_corpuses',
                                    search: `?${createSearchParams({ tabId: tabId })}`,
                                })} className='backTxtBtn' >Back</button>
                            </div>
                            <div className='block lg:flex gap-4'>
                                <div className='lg:w-1/2'>
                                    <div className='grayBox rounded-xl h-full mb-4'>
                                        <div className='flex items-center justify-center h-[360px] lg:h-[486px]'>
                                            <DragDrop file={file} setFile={setFile} />
                                        </div>
                                        <div className='px-4 pb-3 flex gap-3 items-center justify-center'>
                                            <div>{Progress !== 0 && <p className='text-primary'>{Progress}% <small>uploaded</small></p>}</div>
                                            <div>
                                                <button onClick={() => UploadToS3Handle()} className='btnPrimary text-white font-semibold py-3 px-5 inline-block'>{upload_s3_hook.isLoading ? 'Loading' : 'Upload'}</button>
                                            </div>
                                            <div>
                                                <button onClick={() => handleCancel()} className='btnOutline text-white font-semibold py-3 px-5 inline-block'>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='lg:w-1/2'>
                                    <CorpusContent formik={formik} />
                                </div>
                            </div>
                        </div>
                        <div className='copyFloat'><AuthCopy /></div>
                    </div>
                </>}
        </>
    )
}

export default UploadNew
import React, { useEffect, useState } from 'react'
import styles from '../../shared/common.module.css'
import { useNavigate } from 'react-router-dom'
import AuthCopy from '../../../auth/shared/auth-copyright'
import { useGetAllNERTags, useGetMyTags, useGetNewDocument, useUploadTags, uuseUploadTags } from './hooks';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TokenAnnotator } from "react-text-annotate";
import LoaderForAll from '../../../components/loader-for-all'
import { NER_TAGS, POS_TAGS } from '../../../config';
import HelpIcon from '@mui/icons-material/Help';
import { NerGuidelines } from './nerGuidelines';

export const NerCreateTag = () => {

    const navigate = useNavigate();
    const getNewDoc_hook = useGetNewDocument()
    const [Doc, setDoc] = useState(null)
    const [nertag, setnertag] = useState(NER_TAGS[0])
    const [NerValue, setNerValue] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const uploadNerTags_hook = useUploadTags()

    const get_my_tags_hook = useGetMyTags()
    const [tagList, settagList] = useState([])
    const [checkEmpty, setcheckEmpty] = useState(false)

    const get_tags = useGetAllNERTags()
    const [tags, settags] = useState([])



    useEffect(() => {
        if (get_tags.isSuccess) {
            if (get_tags.data.data.length === 0) {
                settags([])
            }
            else {
                settags(get_tags?.data?.data.sort())
            }


        }
        else if (get_tags.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [get_tags.isSuccess, get_tags.isError, get_tags.data])

    useEffect(() => {
        get_tags.refetch()
        return () => {
            get_tags.remove()
        }
    }, [])


    useEffect(() => {
        if (get_my_tags_hook.isSuccess) {
            if (get_my_tags_hook.data.data.length === 0) {
                settagList([])
            }

            else {
                let ids = get_my_tags_hook.data.data.sort(cmp).map(itm => itm.ner_request_id)
                settagList(ids)
            }
        }
        else if (get_my_tags_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [get_my_tags_hook.isSuccess, get_my_tags_hook.isError, get_my_tags_hook.data])


    useEffect(() => {
        get_my_tags_hook.refetch()
        return () => {
            get_my_tags_hook.remove()
        }
    }, [])

    function cmp(a, b) {
        const [dateParta, timeParta] = a.last_updated_on.split(' ');
        const [daya, montha, yeara] = dateParta.split('/');
        const [houra, minutea, seconda] = timeParta.split(':');
        const [datePartb, timePartb] = b.last_updated_on.split(' ');
        const [dayb, monthb, yearb] = datePartb.split('/');
        const [hourb, minuteb, secondb] = timePartb.split(':');


        const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
        const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
        return dateB - dateA;


    }




    const handleNerAnotationChange = (newValue) => {
        if (!isSubmit)
            setNerValue(newValue);
    };

    useEffect(() => {
        if (getNewDoc_hook.isSuccess) {
            if (getNewDoc_hook.data.message) {
                toast.success("No more data available")
            }
            else {
                setDoc(getNewDoc_hook.data.data)
            }


        }
        else if (getNewDoc_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [getNewDoc_hook.isSuccess, getNewDoc_hook.isError, getNewDoc_hook.data])

    useEffect(() => {
        getNewDoc_hook.refetch()
    }, [])


    function handleSubmit() {

        let body = {
            document_id: Doc.document_id,
            document: Doc.document,
            ner_tags: JSON.stringify(NerValue),
            ner_status: 'pending',
        }
        setIsSubmit(true)
        uploadNerTags_hook.mutate(body)


    }

    const notifySuccess = (msg) => {
        toast.success(msg, {
            onClose: () => {
                setIsSubmit(false);
                navigate('/nerContribution');
            }
        });

    }
    const notifyError = (msg) => {
        toast.error(msg, {
            onClose: ({ uid }) => setIsSubmit(false)
        });

    }


    useEffect(() => {
        if (uploadNerTags_hook.isSuccess) {
            // navigate('/nerContribution');
            toast.success("Uploaded Success")
            getNewDoc_hook.refetch()
            setcheckEmpty(false)
            // notifySuccess("Uploaded Success")
            // setNerValue([]);
            setIsSubmit(true);

        }
        else if (uploadNerTags_hook.isError) {
            toast.error("Error in uploading tags")
            // notifyError("Error in uploading tags")
        }

    }, [uploadNerTags_hook.isError, uploadNerTags_hook.isSuccess])

    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen((prev) => !prev);
    };


    function handleNext() {
        navigate(`/nerEditTag/${tagList[0]}`)
    }



    return (
        <>
            <div className='pageWrap lightWhite'>
                <div className='block lg:flex gap-0'>
                    <div className='grow lg:px-5 relative hScreen'>
                        {uploadNerTags_hook.isLoading ? <LoaderForAll /> :
                            <div className={`${styles.pagecontainer} ${styles.pxC}`}>
                                <button type="button" className='backTxtBtn' onClick={() => navigate('/nerContribution', { state: { selected_id: tagList[0] } })}>Back</button>
                                {getNewDoc_hook.isLoading ? <LoaderForAll /> :
                                    <>

                                        <>

                                            <div className='grayBox rounded-xl p-4 mb-4'>

                                                <div className='flex items-center mb-3'>
                                                    <h2 className='text-primary m-0 text-lg'>Named Entity Recognition Tags</h2>
                                                    <div className='pl-4'>
                                                        <select className='selectTag xPad' value={nertag} onChange={(e) => setnertag(e.target.value)} name="tags" id="tags">
                                                            {tags.map(itm => {
                                                                return (
                                                                    <option key={itm} value={itm}>{itm}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className='pl-3'>
                                                        <button title='Help' type="button" onClick={handleClick}><HelpIcon /></button>
                                                    </div>
                                                </div>
                                                {Doc != null ?

                                                    <TokenAnnotator
                                                        tokens={Doc.document}
                                                        value={NerValue}

                                                        onChange={handleNerAnotationChange}
                                                        getSpan={(span) => ({
                                                            ...span,
                                                            tag: nertag,

                                                        })}
                                                    />

                                                    : null}

                                            </div>

                                        </>



                                        <div className='flex gap-4 justify-between'>
                                            <div>
                                                <label>
                                                    <input type="checkbox" name="checkEmpty" id="checkEmpty" checked={checkEmpty} onChange={() => setcheckEmpty(p => !p)} />
                                                    <span className='inline-block ml-2'>There is no NER tag available</span>
                                                </label>
                                            </div>
                                            <div className='flex gap-4'>
                                                <div><button onClick={handleNext} className='btnOutline text-white font-semibold px-4 py-2'>Previous</button></div>
                                                <div>
                                                    <button disabled={(NerValue.length === 0 && checkEmpty === false) || uploadNerTags_hook.isLoading} onClick={handleSubmit} className='px-4 btnPrimary text-white font-semibold py-2 mb-3'>Submit</button>
                                                </div>
                                            </div>
                                        </div>


                                    </>


                                }


                            </div>
                        }
                        <div className='hidden lg:block copyFloat'><AuthCopy /></div>
                    </div>

                    {open ? (
                        <div className='flex-none lg:w-[380px]'>
                            <NerGuidelines setOpen={setOpen} />
                        </div>
                    ) : null}

                </div>


            </div>
        </>
    )
}

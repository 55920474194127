//import React from 'react';
import React, { useEffect, useState, useRef } from "react";
import styles from '../../shared/common.module.css'
import RecognitionFilter from './recognition-filter'
import StatusTable from './status-table'
import SpeechTextPane from './speech-textpane'
import SpeechTagEdit from './speechTagEdit'
import SpeechsActions from './speech-actions'
import AuthCopy from '../../../auth/shared/auth-copyright'
import Select from '../../services/select'
import { useGetDocumentForSpeechTag, useUpdateSpeechTags, useGetTagByReqId, useGetAllSpeechTagging } from './hooks';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom'
import LoaderForAll from "../../../components/loader-for-all";


function SpeechRecognitionDetails() {

    const navigate = useNavigate();
    const [sTagValue, setSTagValue] = useState([]);
    const { id } = useParams();
    const [speech_id, setspeech_id] = useState(parseInt(id))
    const getSpeeachTagHookData = useGetTagByReqId();
    const [loading, setloading] = useState(false)

    useEffect(() => {

        try {
            if (getSpeeachTagHookData.isSuccess) {
                if (getSpeeachTagHookData.data.message) {
                    toast.success("No more data available")
                }
                else {
                    setSTagValue(getSpeeachTagHookData.data.data)
                }

            }
            else if (getSpeeachTagHookData.isError) {
                toast.error("Error is Fetching Data")
            }
        } catch (error) {
            toast.error(error)
        }
        setloading(false)
    }, [getSpeeachTagHookData.isSuccess, getSpeeachTagHookData.isError, getSpeeachTagHookData.data])

    useEffect(() => {
        try {
            getSpeeachTagHookData.refetchWithId(speech_id)
            return () => {
                getSpeeachTagHookData.remove()
            }
        } catch (error) {
            toast.error(error)
        }
    }, [id])


    const [tagList, settagList] = useState([])
    const get_my_tags_hook = useGetAllSpeechTagging()


    useEffect(() => {
        try {
            if (get_my_tags_hook.isSuccess) {
                if (get_my_tags_hook.data.data.length === 0) {
                    settagList([])
                }
                else {
                    let ids = get_my_tags_hook.data.data.sort(cmp).map(itm => itm.speech_request_id)
                    settagList(ids)
                }


            }
            else if (get_my_tags_hook.isError) {
                toast.error("Error is Fetching Data")
            }
        } catch (error) {
            toast.error(error)
        }
    }, [get_my_tags_hook.isSuccess, get_my_tags_hook.isError])


    useEffect(() => {
        try {
            get_my_tags_hook.refetch()

            return () => {
                get_my_tags_hook.remove()
            }
        } catch (error) {
            toast.error(error)
        }
    }, [])

    function cmp(a, b) {
        const [dateParta, timeParta] = a.last_updated_on.split(' ');
        const [daya, montha, yeara] = dateParta.split('/');
        const [houra, minutea, seconda] = timeParta.split(':');
        const [datePartb, timePartb] = b.last_updated_on.split(' ');
        const [dayb, monthb, yearb] = datePartb.split('/');
        const [hourb, minuteb, secondb] = timePartb.split(':');


        const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
        const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
        return dateB - dateA;


    }


    function handleNext() {
        let ind = tagList.indexOf(parseInt(speech_id))
        if (ind < tagList.length - 1) {
            setspeech_id(tagList[ind + 1])
            // get_my_pos_tags_by_request_id_hook.refetchWithId(tagList[ind+1])
            navigate(`/edit_speech_tag/${tagList[ind + 1]}`)
            setloading(true)
        }
    }

    function handlePrev() {
        let ind = tagList.indexOf(speech_id)
        if (ind > 0) {
            setspeech_id(tagList[ind - 1])
            navigate(`/edit_speech_tag/${tagList[ind - 1]}`)
            setloading(true)
            // get_my_pos_tags_by_request_id_hook.refetchWithId(tagList[ind-1])
        }
    }




    return (
        <>
            {loading ? <LoaderForAll /> :
                <div className='pageWrap lightWhite withCopy'>
                    <div className={`${styles.pagecontainer} ${styles.pxC}`}>

                        <div className='flex justify-between gap-4 mb-3 items-center'>
                            <div className='flex gap-4 items-center'>
                                <div>
                                    <button type="button" className='backTxtBtn' onClick={() => navigate('/view_speech_taggings',{ state: { selected_id: id } } )}>Back</button>
                                </div>
                               
                            </div>
                            <div className='flex gap-4 items-center'>
                                <div>
                                    <button disabled={tagList.indexOf(speech_id) === tagList.length-1 ? true : false } onClick={handleNext} className='btnOutline text-white font-semibold py-2 px-4'>Previous</button>
                                </div>
                                <div>
                                    <button disabled={tagList.indexOf(speech_id) === 0 ? true : false } onClick={handlePrev} className='btnOutline text-white font-semibold py-2 px-4'>Next</button>
                                </div>
                                <div>
                                    <button type="button" className='px-4 btnPrimary text-white font-semibold py-2' onClick={() => navigate('/speech-recognition')}>Add New</button>
                                </div>
                            </div>
                        </div>

                        <div className='block lg:flex lg:gap-4'>                            
                            <div className='w-full'>
                                <div className='grayBox rounded-xl mb-4'>
                                    <SpeechTextPane tagDocument={sTagValue} />
                                </div>
                                <SpeechTagEdit tagDocument={sTagValue} />
                            </div>
                        </div>
                    </div>
                    <div className='copyFloat'><AuthCopy /></div>
                </div>
            }
        </>
    )
}

export default SpeechRecognitionDetails
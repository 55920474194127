import React, { useState } from 'react'
import { Link } from "react-router-dom"
import NavItems from './nav-items'
import AccButtons from './acc-buttons';
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom';


function Header() {
    const [navbar, setNavbar] = useState(false);
    let dispatch = useDispatch();
    function change() {
        
        dispatch({type: "TAB" , payload: {tab: 'dashboard'}})
    }

    return (
        <>
            <header className='mainHdr shadow'>
                <nav className="w-full">
                    <div className="justify-between px-4 lg:items-center lg:flex xl:px-8">
                        <div className='lg:items-center lg:flex'>
                            <div className='lg:mr-8 xl:mr-10'>
                                <div className="flex items-center justify-between py-3 lg:py-4 lg:block">
                                    <Link onClick={change} to='/home-admin' className='pbrand'>
                                        <h2 className="text-xl font-bold text-white m-0">Panjabi.AI</h2>
                                    </Link>
                                    <div className="lg:hidden">
                                        <button
                                            className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                                            onClick={() => setNavbar(!navbar)}
                                        >
                                            {navbar ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-6 h-6 text-white"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-6 h-6 text-white"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M4 6h16M4 12h16M4 18h16"
                                                    />
                                                </svg>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className={`flex-1 justify-self-center pb-3 mt-3 lg:block lg:pb-0 lg:mt-0 ${navbar ? "block" : "hidden"
                                        }`}
                                >
                                    <NavItems />

                                    <div className="mt-6 space-y-2 grid grid-rows-1 lg:hidden text-center">
                                        <AccButtons />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hidden lg:inline-block">
                            <AccButtons />
                        </div>
                    </div>
                </nav>
            </header>
            <Outlet/>
        </>
    )
}

export default Header
import React, { useEffect, useState } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useGetAllPOSTags, useGetAllTags, useGetPOSTagById, useUpdatePOSStatus } from './hooks';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TokenAnnotator } from "react-text-annotate";
import LoaderForAll from '../../components/loader-for-all';
import { POS_TAGS } from '../../config';
import styles from '../../pages/shared/common.module.css'
import { UpdatePosStatusPop } from './updatePop';
import { PosGuidelines } from '../../pages/contribution/pos/posGuidelines';
import HelpIcon from '@mui/icons-material/Help';



export const POSUpdateStatus = () => {
    const { id } = useParams()
    const [pos_request_id, setpos_request_id] = useState(parseInt(id))
    const navigate = useNavigate();
    const [Doc, setDoc] = useState(null)
    const [postag, setpostag] = useState(POS_TAGS[0])
    const [PosValue, setPosValue] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const get_pos_tags_by_request_id_hook = useGetPOSTagById()
    const update_pos_status_hook = useUpdatePOSStatus()
    const [comment, setcomment] = useState('')
    const [StatusPop, setStatusPop] = useState(null)
    const [loading, setloading] = useState(false)

    const get_tags = useGetAllPOSTags()
    const [tags, settags] = useState([])



    useEffect(() => {
        if (get_tags.isSuccess) {
            if (get_tags.data.data.length === 0) {
                settags([])
            }
            else {
                settags(get_tags?.data?.data.sort())
            }


        }
        else if (get_tags.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [get_tags.isSuccess, get_tags.isError, get_tags.data])

    useEffect(() => {
        get_tags.refetch()
        return () => {
            get_tags.remove()
        }
    }, [])


    useEffect(() => {
        if (get_pos_tags_by_request_id_hook.isSuccess) {
            if (get_pos_tags_by_request_id_hook.data.message) {
                toast.success(get_pos_tags_by_request_id_hook.data.message)
            }
            else {
                setDoc(get_pos_tags_by_request_id_hook.data.data)
                setPosValue(get_pos_tags_by_request_id_hook.data.data.pos_tags)
                setIsSubmit(false)
            }

        }
        else if (get_pos_tags_by_request_id_hook.isError) {
            toast.error("Error is Fetching Data")
        }
        setloading(false)
    }, [get_pos_tags_by_request_id_hook.isSuccess, get_pos_tags_by_request_id_hook.isError])

    useEffect(() => {
        get_pos_tags_by_request_id_hook.refetchWithId(pos_request_id)
        return () => {
            get_pos_tags_by_request_id_hook.remove()
        }
    }, [id])



    const notifySuccess = (msg) => {
        toast.success(msg, {
            onClose: ({ uid }) => setIsSubmit(false)
        });

    }
    const notifyError = (msg) => {
        toast.error(msg, {
            onClose: ({ uid }) => setIsSubmit(false)
        });

    }


    useEffect(() => {
        setStatusPop(null)
        if (update_pos_status_hook.isSuccess) {
            let ind = tagList.indexOf(pos_request_id)
            if (ind < tagList.length - 1) {
                setpos_request_id(tagList[ind + 1])
                navigate(`/adminPosUpdateStatus/${tagList[ind + 1]}`)
                setloading(true)

            }
            else {
                navigate('/adminPosList')
                toast.success("Uploaded Success")
                setIsSubmit(false)
            }
        }
        else if (update_pos_status_hook.isError) {
            notifyError("Error is uploading")
        }

    }, [update_pos_status_hook.isError, update_pos_status_hook.isSuccess])

    const handlePosAnotationChange = (newValue) => {

        if (!isSubmit) {
            setPosValue(newValue);
        }
    };
    function handleSubmit() {
        if (PosValue.length) {
            let body = {
                pos_request_id: Doc.pos_request_id,
                document: Doc.document,
                pos_tags: JSON.stringify(PosValue),
                pos_status: 'reviewed',
            }
            update_pos_status_hook.mutate(body)
            setIsSubmit(true)
        } else {
            toast.error('Please add some tags')
        }

    }

    const get_my_tags_hook = useGetAllTags()
    const [tagList, settagList] = useState([])

    function cmp(a, b) {
        const [dateParta, timeParta] = a.last_updated_on.split(' ');
        const [daya, montha, yeara] = dateParta.split('/');
        const [houra, minutea, seconda] = timeParta.split(':');
        const [datePartb, timePartb] = b.last_updated_on.split(' ');
        const [dayb, monthb, yearb] = datePartb.split('/');
        const [hourb, minuteb, secondb] = timePartb.split(':');


        const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
        const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
        return dateB - dateA;


    }

    useEffect(() => {
        if (get_my_tags_hook.isSuccess) {
            if (get_my_tags_hook.data.data.length === 0) {
                settagList([])
            }

            else {
                let ids = get_my_tags_hook.data.data.sort(cmp).map(itm => itm.pos_request_id)
                settagList(ids)
            }
        }
        else if (get_my_tags_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [get_my_tags_hook.isSuccess, get_my_tags_hook.isError, get_my_tags_hook.data])


    useEffect(() => {
        get_my_tags_hook.refetch()
        return () => {
            get_my_tags_hook.remove()
        }
    }, [])

    function handleNext() {
        let ind = tagList.indexOf(pos_request_id)
        if (ind < tagList.length - 1) {
            setpos_request_id(tagList[ind + 1])
            // get_my_ner_tags_by_request_id_hook.refetchWithId(tagList[ind+1])
            navigate(`/adminPosUpdateStatus/${tagList[ind + 1]}`)
            setloading(true)
        }
    }

    function handlePrev() {
        let ind = tagList.indexOf(pos_request_id)
        if (ind > 0) {
            setpos_request_id(tagList[ind - 1])
            navigate(`/adminPosUpdateStatus/${tagList[ind - 1]}`)
            setloading(true)
            // get_my_ner_tags_by_request_id_hook.refetchWithId(tagList[ind-1])
        }
    }


    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen((prev) => !prev);
    };


    return (
        <>
            <div className='pageWrap lightWhite'>
                <div className='block lg:flex gap-0'>
                    {update_pos_status_hook.isLoading || loading ? <LoaderForAll /> :

                        <div className='grow px-3 lg:px-8 pt-3'>
                            <button type="button" className='backTxtBtn' onClick={() => navigate('/adminPosList', { state: { selected_id: id } })}>Back</button>

                            <>

                                <div className='grayBox rounded-xl p-4 mb-4'>
                                    <div className='flex items-center mb-3'>
                                        <div className='flex gap-4'>
                                            <div>
                                                <h2 className='text-primary m-0 text-lg'>Part of Speach Recognition Tags</h2>
                                            </div>
                                            {/* <div>
                                            {Doc != null ?   <span className={`${styles.statLbl} ${Doc.pos_status}`}>{Doc.pos_status}</span> : null }
                                        </div> */}
                                        </div>
                                        <div className='pl-4'>
                                            <select className='selectTag xPad' value={postag} onChange={(e) => setpostag(e.target.value)} name="tags" id="tags">
                                                {tags.map(itm => {
                                                    return (
                                                        <option key={itm} value={itm}>{itm}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className='pl-3'>
                                            <button type="button" title='Help' onClick={handleClick}><HelpIcon /></button>
                                        </div>
                                    </div>
                                    {
                                        Doc != null ?

                                            <TokenAnnotator

                                                tokens={Doc.document}
                                                value={PosValue}
                                                onChange={handlePosAnotationChange}

                                                getSpan={(span) => ({
                                                    ...span,
                                                    tag: postag,

                                                })}
                                            />

                                            : null
                                    }

                                </div>

                                {/* <textarea value={comment} onChange={(e)=> setcomment(e.target.value)} name="comment_ner" id="comment_ner" cols="30" rows="10" placeholder='Enter any comments'></textarea> */}
                            </>


                            

                            <div className='flex justify-end gap-4 mb-3'>
                                <div>
                                    <button disabled={tagList.indexOf(pos_request_id) === tagList.length-1 ? true : false } onClick={handleNext} className='px-4 btnOutline text-white font-semibold py-2'>Previous</button>
                                </div>
                                <div>
                                    <button disabled={tagList.indexOf(pos_request_id) === 0 ? true : false } onClick={handlePrev} className='px-4 btnOutline text-white font-semibold py-2'>Next</button>
                                </div>
                                <div>
                                <button disabled={PosValue.length ? false : true || update_pos_status_hook.isLoading} onClick={handleSubmit} className='px-4 btnPrimary text-white font-semibold py-2 mb-3'>Review</button>
                                </div>
                            </div>


                        </div>
                    }
                    {open ? (
                        <div className='flex-none lg:w-[380px]'>
                            <PosGuidelines setOpen={setOpen} />
                        </div>
                    ) : null}
                </div>

            </div>
        </>
    )
}

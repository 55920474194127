import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ProgressBar from 'react-percent-bar'
import '../shared/page.css'
import { useLocation } from 'react-router-dom';
import classes from './dash.module.css'

function LeaderBoard(props) {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [isOpened, setIsOpened] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const toggle = (userId) => {
        if (userId == selectedUserId) {
            setIsOpened(!isOpened)
        } else {
            setIsOpened(true)
        }
        setSelectedUserId(userId)
    }
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <>
            <div className='pageWrap'>

                <div className='pageContWrap px-5 pt-3'>
                    <button type="button" className='backTxtBtn' onClick={() => navigate('/home-admin')}>Back</button>
                    <div className='tableListWrap'>
                        <div className='contTable min-w-[1200px]'>
                            <div className='tableHead flex items-center py-2'>
                                {/* <div className='flex-none text-start px-3'>Rank</div> */}
                                <div className='flex-1 px-3'>Name</div>
                                <div className='flex-1 px-3'>Progress</div>
                                {/* <div className='flex-1 px-3'>Time Spent</div> */}
                                <div className='flex-1 px-3'>Results</div>
                            </div>
                            <div className='tableBody'>
                                {/* Table Row Loop */}
                                {state?.length >= 1 && state.map((leader) => (
                                    <div key={leader?.register_id}>
                                        <div key={leader?.register_id} className='tBodyRow flex py-1 rounded-md'>
                                            {/* <div className='flex-none flex items-center text-start px-3'>
                                                <span className='badge'>{leader?.register_id}</span>
                                            </div> */}
                                            <div className='flex-1 items-center justify-start px-3 leading-3'>
                                                <div className='flex h-full items-center px-4 text-left'>
                                                    <div className='flex-none flex items-center px-3'>
                                                        {/* <span className='dp' style={{ backgroundImage: `url(${(leader.image_url)})` }}></span> */}
                                                        {leader.profile_picture_url?.length>=1 && 
                                    <span className='dp' style={{ backgroundImage: `url(${(leader.profile_picture_url)})` }}></span>
                                    }
                                    {!leader.profile_picture_url?.length>=1 && 
                                    <span className='dp fletter'>{leader?.name[0] || ''}</span> 
                                    }
                                                    </div>
                                                    <div className='flex-auto pl-3 text-left leading-normal'>
                                                        <p><strong>{capitalizeFirstLetter(leader?.name)}</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex-1 px-3'>
                                                <div className='h-full flex justify-center items-center'>
                                                    <div className='inline-block pr-3'>
                                                        <ProgressBar borderColor="#585858" percent={Math.round(leader?.overall_percentage || 0)} fillColor="#44A0DF" width="120px" height="6px" />
                                                    </div>
                                                    <strong className='text-primary'>{Math.round(leader?.overall_percentage || 0)}%</strong>
                                                </div>
                                            </div>
                                            {/* <div className='flex-1 px-3'>
                                                <div className='h-full flex items-center justify-center'>
                                                    {leader?.timeSpent || ''}
                                                </div>
                                            </div> */}
                                            <div className='flex-1 px-3'>
                                                <div className='h-full flex items-center justify-center'>
                                                    <button type='button' className='text-primary font-semibold hover:underline' onClick={() => { toggle(leader?.register_id) }}>View Results</button>
                                                </div>
                                            </div>



                                        </div>

                                        <div key='result`${leader?.register_id}`'>
                                            {isOpened && selectedUserId == leader?.register_id &&

                                                <div className="py-4">
                                                    <div className={classes.tagsTableWrap}>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>&nbsp;</th>
                                                                    <th>{leader?.total_ner_count || 0} <span>NER</span></th>
                                                                    <th>{leader?.total_pos_count || 0} <span>POS</span></th>
                                                                    <th>{leader?.total_speech_count || 0} <span>Speech</span></th>
                                                                    <th>{leader?.total_imagetag_count || 0} <span>Images</span></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className={classes.accepted}>
                                                                    <td>
                                                                        <img src="/svg/accepted-ico.svg" alt="" />
                                                                        <span className='pl-2'>reviewed</span>
                                                                    </td>
                                                                    <td>{leader?.reviewed_ner_count || 0}</td>
                                                                    <td>{leader?.reviewed_pos_count || 0}</td>
                                                                    <td>{leader?.reviewed_speech_count || 0}</td>
                                                                    <td>{leader?.reviewed_imagetag_count || 0}</td>
                                                                </tr>
                                                                {/* <tr className={classes.rejected}>
                                                                    <td>
                                                                        <img src="/svg/rejected-ico.svg" alt="" />
                                                                        <span className='pl-2'>Rejected</span>
                                                                    </td>
                                                                    <td>{leader?.rejected_ner_count || 0}</td>
                                                                    <td>{leader?.rejected_pos_count || 0}</td>
                                                                    <td>{leader?.rejected_speech_count || 0}</td>
                                                                    <td>{leader?.rejected_imagetag_count || 0}</td>
                                                                </tr> */}
                                                                <tr className={classes.pending}>
                                                                    <td>
                                                                        <img src="/svg/pending-ico.svg" alt="" />
                                                                        <span className='pl-2'>Pending</span>
                                                                    </td>
                                                                    <td>{leader?.pending_ner_count || 0}</td>
                                                                    <td>{leader?.pending_pos_count || 0}</td>
                                                                    <td>{leader?.pending_speech_count || 0}</td>
                                                                    <td>{leader?.pending_imagetag_count || 0}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeaderBoard
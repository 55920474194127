import React, { useState, useEffect } from 'react';
import LoaderForAll from '../components/loader-for-all';

import { useNavigate } from 'react-router-dom';
import { useChangePassword } from './hooks';
import { toast } from 'react-toastify';
import { ChangePasswordSchema } from './authSchema';
import { useFormik } from 'formik';

export const ChangePassword = ({ email , otp }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isCPasswordVisible, setIsCPasswordVisible] = useState(false);
    const [iOtp, setiOtp] = useState(parseInt(otp.reduce((acc , cur) => {
        return acc + cur 
      },"")))


    let navigate = useNavigate()
    const changePassword_hook = useChangePassword()

    const formik = useFormik({
        initialValues: {
            password: "",
            confirm_password: "",
        },
        validationSchema: ChangePasswordSchema,
        onSubmit: (values) => {
            const body = {
                email: email,
                new_password_1: values.password,
                new_password_2: values.confirm_password,
                otp: iOtp
            }
            changePassword_hook.mutate(body)
        }
    });



    useEffect(() => {
        if (changePassword_hook.isError) {
             if (changePassword_hook.error?.response?.data?.message) {
      
                toast.error(changePassword_hook.error?.response?.data?.message)
                
                }
            else{
            toast.error(changePassword_hook.error?.message)
            }

            
        }
        else if (changePassword_hook.isSuccess) {
            navigate("/")
        }
    }, [changePassword_hook.isError ,changePassword_hook.isSuccess ])












    return (
        <>
         <h2 className='uppercase text-2xl md:text-3xl mb-2'>Set New Password</h2>
            {changePassword_hook.isLoading ? <LoaderForAll /> :
                <div id="scndScreen">
                    <div className='mb-4' id="defaultScreen">
                        <form name='forgetPassword1'>
                            <div className='mb-3 relative'>
                                <label htmlFor='password' className='text-primary w-full font-semibold'>New Password</label>
                                <input onBlur={formik.handleBlur} name='password' id="password" value={formik.values.password} onChange={formik.handleChange} type={isPasswordVisible ? "text" : "password"}
                                    className={`${formik.errors.password && formik.touched.password ? 'error' : ''} w-full inpFld`} placeholder='Enter here' />
                                {formik.errors.password && formik.touched.password && <small className='error'>{formik.errors.password}</small>}
                                <button className="toggleBtn" onClick={(e) => {
                                    e.preventDefault()
                                    setIsPasswordVisible(p => !p)
                                }}>
                                    {isPasswordVisible ? 'Hide' : 'Show'}
                                </button>
                            </div>
                            <div className='mb-3 relative'>
                                <label htmlFor='confirm_password' className='text-primary w-full font-semibold'>Confirm Password</label>
                                <input onBlur={formik.handleBlur} name='confirm_password' id="confirm_password" value={formik.values.confirm_password} onChange={formik.handleChange} type={isCPasswordVisible ? "text" : "password"}
                                    className={`${formik.errors.confirm_password && formik.touched.confirm_password ? 'error' : ''} w-full inpFld`} placeholder='Enter here' />
                                {formik.errors.confirm_password && formik.touched.confirm_password && <small className='error'>{formik.errors.confirm_password}</small>}
                                <button className="toggleBtn" onClick={(e) => {
                                    e.preventDefault()
                                    setIsCPasswordVisible(p => !p)
                                }}>
                                    {isCPasswordVisible ? 'Hide' : 'Show'}
                                </button>
                            </div>

                            <div className='grid grid-cols-2 gap-4 pt-5'>
                                <div>
                                    <button type='button' onClick={formik.handleSubmit} className='btnPrimary text-white font-semibold py-3 w-full'>
                                    Save
                                    </button>
                                </div>
                                <div>
                                    &nbsp;
                                </div>
                            </div>
                        </form>
                    </div>
                </div>}
        </>

    );
};


import React , {useState , useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import { Link } from "react-router-dom";
import styles from '../../pages/shared/common.module.css'
import { UploadCorpusSchema } from "../../auth/authSchema";
import { BACKEND_URL } from "../../config";
import { useMutation } from "react-query";
import axios from "axios";
import { useDocumentImageByS3URI } from "./hooks";
import { CorpusImgExpansionPop } from "./expandImagePop";


export const AdminListObject = ({ itm }) => {

    const navigate = useNavigate()


    const [pop2, setpop2] = useState(false)




    




        function formatDate(inputDate) {
            const months = [
                "Jan" ,"January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            var date = inputDate.split(' ')
            var d = date[0]
            var t = date[1]
            d = d.split('/')
            t = t.split(':')
            var din = d[0]
            var month = months[parseInt(d[1])]
            var year = d[2]
            var tm = parseInt(t[0]) > 12 ? 'pm' : 'am'
            var hr = parseInt(t[0]) > 12 ? String(parseInt(t[0]) - 12) : t[0]
            var mn = t[1]
            var ans = din + ' ' + month + ' ' + year + ', ' + hr + ':' + mn + ' ' + tm
            return ans
        }

 


    return (
        <>

            <div className='tBodyRow flex items-center py-2 rounded-md'>
                <div className='flex-none px-3'>
                    <Link onClick={()=>setpop2(true)}><img className='w-[60px]' src={itm.s3_url} alt="" /></Link>
                </div>
                <div className='flex-1 px-3'>
                    <p to="">{itm.name}</p>
                </div>
                <div className='flex-1 px-3'>
                    <p title={formatDate(itm.last_updated_on)}>{formatDate(itm.last_updated_on)}</p>
                </div>
                <div className='flex-1 px-3'>
                    <p>{itm.is_assigned}</p>
                </div>
                <div className='flex-1 px-3'>
                    <p className='lStat'><span className={itm.overall_status}>{itm.overall_status}</span></p>
                </div>
                
                <div className='flex-none w140 px-3 leading-none text-right'>
                <button type='btn' onClick={()=>  navigate(`/adminStatusImageTag/${itm.image_id}`) } className='btnView mx-2' title='View'></button>
                    
                </div>
            </div>


            {pop2 ? <CorpusImgExpansionPop setpop2={setpop2} url={itm.s3_url} /> : null }


        </>
    )
}

import { useQuery , useMutation } from "react-query";
import axios from "axios";
import { useSelector } from "react-redux";
import { BACKEND_URL } from "../../../config";
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { redux_logout } from "../../../auth/authSlice";
import logoutFn from "../../../auth/logout_fn";
import { useState } from "react";


export const useViewCorpuses = ()=> {
  let navigate = useNavigate()
  let dispatch = useDispatch()
    const Data = useSelector(state => state)
    return useQuery(
        {queryKey: ['viewCorpuses1'],
          queryFn: async () => {
            
            // return await axios.get(BACKEND_URL + `/view_corpuses`,{
            //    headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            // }
            // ).then(res => res.json
            //  ).then(dataJson => setcorpaList(dataJson.data) )
            const response = await axios.request({
                headers: {
                  Authorization: `Bearer ${Data.auth["access_token"]}`
                },
                method: "GET",
                url: BACKEND_URL + `/view_corpuses`
              })
            return response.data ? response.data : []
          },
          onSuccess: (data) => {

          },
          onError: (error) => {
            if (error.response.data.message == "Please login first"){
              // logoutFn(Data.auth["access_token"])
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            }
          },
        }
      )
}


export const useUploadCorpusToS3 = () => {
    let navigate = useNavigate()
    let dispatch = useDispatch()
    const [Progress, setProgress] = useState(0);
    const [uploadCancelToken, setUploadCancelToken] = useState(null);

    const upload_s3_hook = useMutation(
        {
          mutationFn: async (props) => {
            try {

              const cancelTokenSource = axios.CancelToken.source();
              setUploadCancelToken(cancelTokenSource);
              return await axios.post(props.url, props.formData , {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(percentCompleted)
                  
                  // console.log(percentCompleted);
                },
                cancelToken: cancelTokenSource.token,
              });
    
            }
            catch (error) {
              if (axios.isCancel(error)) {
                return 'Upload cancelled by user.'
              } else {
                return 'Error during file upload' 
              }
            } finally {
              setUploadCancelToken(null);
            }
          },
          onSuccess: (data) => {
                                
          },
          onError: (error) => {
            if (error.response.data.message == "Please login first"){
              // logoutFn(Data.auth["access_token"])
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            }
          },
        }
      )
      return { upload_s3_hook: upload_s3_hook, Progress: Progress , setUploadCancelToken : setUploadCancelToken , uploadCancelToken:uploadCancelToken };
}

export const useUploadNewCorpus = () => {
    const Data = useSelector(state => state)
    let navigate = useNavigate()
    let dispatch = useDispatch()

    return useMutation(
        {
          mutationFn: async (data) => {
            
            return await axios.post(BACKEND_URL + `/upload_corpus`, data , {
               headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            }
            )
          },
          onSuccess: (data) => {
              
          },
          onError: (error) => {
            if (error.response.data.message == "Please login first"){
              // logoutFn(Data.auth["access_token"])
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            }
          },
        }
      )
}


export const useGetCategories = ()=> {
  let navigate = useNavigate()
  let dispatch = useDispatch()
    const Data = useSelector(state => state)
    return useQuery(
        {queryKey: ['getCats'],
          queryFn: async () => {
            
            // return await axios.get(BACKEND_URL + `/view_corpuses`,{
            //    headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            // }
            // ).then(res => res.json
            //  ).then(dataJson => setcorpaList(dataJson.data) )
            const response = await axios.request({
                headers: {
                  Authorization: `Bearer ${Data.auth["access_token"]}`
                },
                method: "GET",
                url: BACKEND_URL + `/read_catagory`
              })
            return response.data ? response.data : []
          },
          onSuccess: (data) => {

          },
          onError: (error) => {
            if (error.response.data.message == "Please login first"){
              // logoutFn(Data.auth["access_token"])
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            }
          },
        }
      )
}
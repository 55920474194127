import React, { useState, useEffect, useRef } from 'react'
import styles from '../../pages/shared/common.module.css'
import 'react-toastify/dist/ReactToastify.css';
import pfont from '../../pages/shared/pfont.module.css'

export const TextTagging = ({ tags }) => {

    const tagChange = (e) => {

        const id = parseInt(e.target.id);
        tags[id - 1].tags = e.target.innerHTML
    }
    return (
        <>
            <div id="idContent" className='p-4 h-full focus:outline-none overflow-auto' >
                {tags.map((itm, index) => {
                    return (
                        <>
                            <div className='relative inline-block tagMgn'>
                                <span className={styles.arNoHB}>{index + 1}</span>
                                <div onInput={tagChange} contentEditable={true} suppressContentEditableWarning={true} className={`hbbox inline-block px-2 p-0  ${pfont.panjabiFont}`} id={index + 1}>
                                    {itm?.tags ? itm.tags : ''}
                                </div>
                            </div>
                        </>

                    )

                })}
            </div>


        </>
    )
}

import React from 'react'
import styles from '../../shared/guidelines.module.css'
import classes from '../../dashboard/dash.module.css'
import CancelIcon from '@mui/icons-material/Cancel'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { POS_guide_tags } from '../../../config';

export const PosGuidelines = ({ setOpen }) => {



    return (
        <>
            <div className={`${styles.guideBox} relative text-white`}>
                <button type='button' className={styles.cancelBtn} onClick={() => setOpen(false)}>
                    <CancelIcon />
                </button>
                <div className={`${styles.guideInner} px-4 pb-4 pt-10 lg:pt-4`}>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                            <Typography>
                                <div className="flex gap-3">
                                    <div className='flex-none'>
                                        <label className={`${styles.tagLbl}`}>POS</label>
                                    </div>
                                    <div className='flex-none'>
                                        <h2 className='m-0'>Guidelines</h2>
                                    </div>
                                </div>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className={classes.tagsTableWrap}>
                                    <table className={classes.tagsTableBdr}>
                                        <thead>
                                            <tr>
                                                <th className='text-left opacity-75'>Label</th>
                                                <th className='text-left'>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(POS_guide_tags).map((itm ,ind) => (
                                                <tr key={ind}>
                                                    <td className='text-left opacity-75'>{itm}</td>
                                                    <td className='text-left'>{POS_guide_tags[itm]}</td>
                                                </tr>
                                            ))}
                                           
                                        </tbody>
                                    </table>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    {/* <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                            <Typography>Accordion 2</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                malesuada lacus ex, sit amet blandit leo lobortis eget.
                            </Typography>
                        </AccordionDetails>
                    </Accordion> */}
                </div>

            </div>
        </>
    )
}

import ProfileDtls from './prof-dtls'
import DataTableProfile from './data-table'
import PublicTopSection from '../../dashboard/public-sections/public-tops'
import { useGetUserProfileDetails } from './hooks'
import React, { useState, useEffect, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import LoaderForAll from '../../../components/loader-for-all'

function ProfilePage() {   


    const [userProfileDetails, setUserProfileDetails] = useState([])
    const getUserProfileDetails = useGetUserProfileDetails();
    useEffect(() => {
        try{
        if (getUserProfileDetails.isSuccess) {

            setUserProfileDetails(getUserProfileDetails.data);

        }
        else if (getUserProfileDetails.isError) {
            toast.error("Error in Fetching Data")
        }
    }catch(e){
        toast.error("Error in fetching admin dashboard data",e)
    }
    }, [getUserProfileDetails.isSuccess, getUserProfileDetails.isError])

    useEffect(() => {
        try{
            getUserProfileDetails.refetch();
        return () => {
            getUserProfileDetails.remove();
        }
    }catch(e){
        toast.error("Error in fetching admin dashboard data",e)
    }

    }, [])


    return (

        <>
        {getUserProfileDetails.isLoading ? <LoaderForAll /> :
            <div className='bgLight hScreen'>
                <ProfileDtls userProfileDetails = {userProfileDetails}/>
                {userProfileDetails?.role !='admin' && 
                <div>
                <div className='dashTopPanel py-3 px-6 lg:px-9'>
                <PublicTopSection adminDashboardDetails={userProfileDetails} isUser = {true} />
                </div>
                <DataTableProfile  userProfileDetails = {userProfileDetails}/>
                </div>
                }
            </div>
        }
        </>
    )
}

export default ProfilePage
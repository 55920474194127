import React, { useState, useRef, useEffect } from 'react';
import LoaderForAll from '../components/loader-for-all';
import { BACKEND_URL } from '../config';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import axios from 'axios';
import { redux_login } from './authSlice';
import { useRegisterUser, useSendotp } from './hooks';
import { toast } from 'react-toastify';

export const OtpInput = ({ formik }) => {
  const Data = useSelector(state => state)
  var length = 6;
  const [otp, setOtp] = useState(Array(length).fill(''));
  const inputRefs = useRef([]);
  let navigate = useNavigate()
  const resendOtp_hook = useSendotp()
  const registerUser_hook = useRegisterUser()

  const handleInputChange = (index, event) => {
    const value = event.target.value;

    if (isNaN(value)) {
      return;
    }

    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    // Focus on the next input field
    if (value !== '') {
      if (index === length - 1) {
        // If the current input is the last one, blur it
        inputRefs.current[index].blur();
      } else {
        // Otherwise, focus on the next input
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleInputPaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain').trim();
    if (pasteData.length === length) {
      setOtp(pasteData.split(''));
    }
  };

  const handleInputKeyDown = (index, event) => {
    if (event.key === 'Backspace' && otp[index] === '' && index > 0) {
      // If the current input is empty and Backspace is pressed,
      // move focus to the previous input field
      inputRefs.current[index - 1].focus();
    }
  };



  function handleSubmit() {
    let iOtp = otp.reduce((acc, cur) => {
      return acc + cur
    }, "")
    const data = {
      email: formik.values.email,
      password: formik.values.password,
      name: formik.values.name,
      otp: parseInt(iOtp),
      age: formik.values.age,
      gender: formik.values.gender

    }
    registerUser_hook.mutate(data)

  }

  useEffect(() => {

    if (registerUser_hook.isError) {
      toast.error(registerUser_hook.error?.response?.data.message)
    }

    else if (registerUser_hook.isSuccess) {
      if (Data.auth.isSignedIn) {
        if (Data.auth.auth_token?.length !== 0) {
          if (Data.auth.role == "admin") {
            navigate('/home-admin')
          }
          else {
            navigate('/home-public')
          }
        }
      }
    }
  }, [registerUser_hook.isError , registerUser_hook.isSuccess])


  useEffect(() => {
    if (resendOtp_hook.isError) {
      toast.error(resendOtp_hook.error?.response?.data.message)
    }
    else if (resendOtp_hook.isSuccess) {
      toast.success("OTP sent to your Email ID")
    }
  }, [resendOtp_hook.isError ,resendOtp_hook.isSuccess ])













  return (
    <>
      {registerUser_hook.isLoading ? <LoaderForAll /> :
        <div id="scndScreen">
          <p className='text-xs mb-5'>Enter the 6 digit verification code sent on {formik.values.email}</p>

          <form name='forgetPassword2'>
            <div className='flex justify-between text-sm mb-3'>
              <div>
                <label className='text-primary w-full font-semibold'>Enter Code</label>
              </div>
              {resendOtp_hook.isLoading ? null :
                <div>
                  <button onClick={resendOtp_hook.mutate}>Resend Code?</button>
                </div>}
            </div>

            <div className='flex gap-3 mb-3'>
              {otp.map((digit, index) => (
                <div className='flex-none'>
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onChange={(event) => handleInputChange(index, event)}
                    onPaste={handleInputPaste}
                    onKeyDown={(event) => handleInputKeyDown(index, event)}
                    ref={(ref) => (inputRefs.current[index] = ref)}
                    className={`${registerUser_hook.isError ? 'error' : ''} w-8 inpFld text-center px-0`}
                    placeholder='0'
                  />
                </div>
              ))}

            </div>

            <div className='grid grid-cols-2 gap-4 pt-5'>
              <div>
                <button onClick={(e) => {
                  e.preventDefault()
                  handleSubmit()
                }

                } className='btnPrimary text-white font-semibold py-3 w-full'>
                  Verify
                </button>
              </div>
              <div>
                &nbsp;
              </div>
            </div>
          </form>
        </div>}
    </>

  );
};


import React from 'react'
import { Link,useNavigate } from "react-router-dom"
import Slider from "react-slick"
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from 'react-redux'

function TopLeaders(adminLeaderboardData) {

    const leaderBoardData = adminLeaderboardData?.adminLeaderboardData;
    const Data = useSelector(item => item)
    let dispatch = useDispatch();

    let navigate = useNavigate(); 
    const routeChange = () =>{ 
      let path = `newPath`; 
      navigate(path);
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    }


    return (
        <>
            <div className='p-3 md:flex'>
                <div className='flex-auto md:pr-5'>
                    <div className='flex list'>
                        <div>
                            <h6>Leaderboard</h6> <div className='text-xs'>People who contributed the most</div>
                        </div>
                        <div>
                            <h6>{leaderBoardData?.length || 0}</h6> <div className='text-xs'>Top performers</div>
                        </div>
                    </div>
                </div>
                <div className='flex-none w-32 md:text-end md:pr-3 pt-3 md:pt-1 pl-3 md:pl-0'>
                    {leaderBoardData?.length>=1 && 
                    <Link className='btnMore' to={'/leaderboard'} state =  {leaderBoardData}>View More</Link>
                    }
                </div>
            </div>

            <div className='px-8 text-xs leaderC'>
                {leaderBoardData?.length && 
                <Slider {...settings}>
                    {leaderBoardData?.length >=1 && leaderBoardData.map((leader) => (
                        <div key={leader?.register_id} className='text-center md:text-left'>
                            <div className='inline-flex items-center px-4'>
                                <div className='flex-none w-3rem'>
                                    {leader.profile_picture_url?.length>=1 && 
                                    <span className='dp' style={{ backgroundImage: `url(${(leader.profile_picture_url)})` }}></span>
                                    }
                                    {!leader.profile_picture_url?.length>=1 && 
                                    <span className='dp fletter'>{leader?.name[0] || ''}</span> 
                                    }                                   
                                </div>
                                <div className='flex-auto pl-3 text-left leading-normal'>
                                    <p><strong>{capitalizeFirstLetter(leader?.name)}</strong></p>
                                    <p>Percentage: {Math.round(leader?.overall_percentage || 0)}%</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                }
            </div>
        </>
    )
}

export default TopLeaders
import React from 'react'
import LoaderForAll from '../components/loader-for-all'
import PopUpForAll from '../components/popup-for-all'

function Examples() {

    const [showModal, setShowModal] = React.useState(false);

    return (
        <>
            {/* We are using this page as draft to test internal common elements */}

            <LoaderForAll />

            <button
                className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Open regular modal
            </button>

            {showModal ? (
                <>
                    <PopUpForAll />
                </>
            ) : null}

        </>
    )
}

export default Examples
import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import AuthCopy from '../../auth/shared/auth-copyright';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDocumentCorpusByS3URI, useGetCorpusByURI, useUpdateCorpusData } from './hooks';
import axios from 'axios';
import LoaderForAll from '../../components/loader-for-all';
import { SaveDataPop } from './saveDataPop';
import { DocumentCorpusPop } from './documentCorpusPop';
import { DeleteCorpusPop } from './deletePop';

import { Controlled } from 'react-codemirror2';

import 'codemirror/lib/codemirror.css';

import 'codemirror/mode/xml/xml';
import 'codemirror/mode/css/css';
import 'codemirror/mode/javascript/javascript';

import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchtags';
import 'codemirror/addon/edit/matchbrackets';


export const ActionCorpus = () => {
    const location = useLocation()
    const [s3Url, sets3Url] = useState(null)
    const [value, setValue] = useState(null)

    const navigate = useNavigate()
    const getcorpus_by_s3_hook = useGetCorpusByURI(location.state)
    const update_corpus_data_hook = useUpdateCorpusData()
    const document_corpus_hook = useDocumentCorpusByS3URI(location.state)
    const [Doc, setDoc] = useState(null)


    useEffect(() => {
        getcorpus_by_s3_hook.refetch()
        return () => {
            getcorpus_by_s3_hook.remove()
        }
    }, [])

    useEffect(() => {

        if (getcorpus_by_s3_hook.isSuccess) {
            if (getcorpus_by_s3_hook.data.message) {
                toast.success("No more data available")
            }
            else {
                setDoc(getcorpus_by_s3_hook.data.data)
                sets3Url(getcorpus_by_s3_hook.data.data.s3_url)

            }

        }
        else if (getcorpus_by_s3_hook.isError) {
            toast.error("Error is Fetching Data")
        }

    }, [getcorpus_by_s3_hook.isSuccess, getcorpus_by_s3_hook.isError])


    useEffect(() => {
        async function getDataFromUrl(url) {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: url,
                headers: {},

            };

            axios.request(config)
                .then((response) => {
                    setValue(response.data)

                })
                .catch((error) => {
                    setValue("")
                    console.log(error);
                });

        }
        if (s3Url !== null) {

            getDataFromUrl(s3Url)
        }


    }, [s3Url])

    function handleChange(e) {

        setValue(e.target.value)

    }

    const [savePop, setsavePop] = useState(false)
    const [documentPop, setdocumentPop] = useState(false)



    useEffect(() => {

        if (update_corpus_data_hook.isSuccess) {
            console.log(update_corpus_data_hook.data)
            toast.success(update_corpus_data_hook.data.data.message)
            setsavePop(false)


        }
        else if (update_corpus_data_hook.isError) {
            toast.error("Error in Uploading Data")
        }



    }, [update_corpus_data_hook.isSuccess, update_corpus_data_hook.isError,update_corpus_data_hook.data])


    useEffect(() => {

        if (document_corpus_hook.isSuccess) {
            toast.success(document_corpus_hook.data.message)
            setdocumentPop(false)


        }
        else if (document_corpus_hook.isError) {
            toast.error("Error in Documenting Corpus")
        }



    }, [document_corpus_hook.isSuccess, document_corpus_hook.isError,document_corpus_hook.data])

    const [delete_s3, setdelete_s3] = useState(null)
    const [pop, setpop] = useState(false)

    const [isDeleted, setisDeleted] = useState(false)

    useEffect(() => {
        if (isDeleted) {
            navigate('/admin-list-corpus')
            toast.success("Deleted")
            setisDeleted(false)
        }
    }, [isDeleted])

    const changeCode = (editor, data, value) => {
        setValue(value);
    };

    console.log(value, "VAL")


    return (

        <>
            {value == null ? <LoaderForAll /> :
                <>

                    <div className='pageWrap lightWhite withCopy'>
                        <div className='px-5 py-4 lg:px-8'>
                            <div>
                                <button type="button" className='backTxtBtn' onClick={() => navigate('/admin-list-corpus')}>Back</button>
                            </div>
                            <div className='grayBox rounded-xl p-4'>                                

                                {update_corpus_data_hook.isLoading ? <LoaderForAll /> :
                                    <div >
                                        <Controlled
                                            value={value}
                                            onBeforeChange={changeCode}
                                            options={{
                                                mode: 'text',
                                                lint: true,
                                                lineNumbers: true,
                                                lineWrapping: true,
                                                spellcheck: true,
                                                autoCloseTags: true,
                                                autoCloseBrackets: true,
                                                matchTags: true,
                                                matchBrackets: true,
                                            }}
                                        />
                                    </div>}

                                    <div className='flex gap-4 justify-end pt-3'>
                                        <button onClick={() => {
                                            setdelete_s3(Doc.s3_uri)
                                            setpop(true)
                                        }} type='button' className='px-4 btnOutline text-white font-semibold py-2' title='Remove'>Delete</button>
                                        <button disabled={update_corpus_data_hook.isLoading} type="button" className='px-4 btnOutline text-white font-semibold py-2' onClick={() => setsavePop(true)}>Save Changes</button>
                                        <button disabled={document_corpus_hook.isLoading} type="button" className='px-4 btnPrimary text-white font-semibold py-2' onClick={() => setdocumentPop(true)}>Document corpus</button>
                                        

                                    </div>
                            </div>
                            {savePop && <SaveDataPop setsavePop={setsavePop} s3_uri={Doc.s3_uri} data={value} update_corpus_data_hook={update_corpus_data_hook} />}
                            {documentPop && <DocumentCorpusPop setdocumentPop={setdocumentPop} s3_uri={Doc.s3_uri} document_corpus_hook={document_corpus_hook} />}

                            {pop ? <DeleteCorpusPop setpop={setpop} s3_uri={delete_s3} set_s3uri={setdelete_s3} setisDeleted={setisDeleted} /> : null}
                        </div>
                        <div className='copyFloat'><AuthCopy /></div>
                    </div>
                </>}
        </>
    )
}

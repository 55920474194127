import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import APISelect from './select-dd'
import APIFaq from './api-faq'
import '../shared/page.css'
import AuthCopy from '../../auth/shared/auth-copyright'
import ApiPop from './api-pop'
import { DocContent } from './doc-content'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { API_Details } from '../../config'


function ApiIndex() {

    const [pop, setpop] = useState('translation')
    const [active, setactive] = useState(1)
    const [info, setinfo] = useState(API_Details['translation'])
    const [endpoint, setendpoint] = useState(API_Details['translation'].default)
    const [endpoints, setendpoints] = useState(Object.keys(API_Details['translation'].endpoint) )

    function handleClick(e) {
        setpop(e.target.id)
        setinfo(API_Details[e.target.id])
        setendpoints(Object.keys(API_Details[e.target.id].endpoint))
        setendpoint(API_Details[e.target.id].default)
    }

   // const codeString = '(num) => num + 1';
   // console.log(info.endpoint[endpoint])

    return (
        
            <div className='pageWrap lightWhite withCopy'>

                <div className='grayBox p-5 xl:p-8'>
                    <div className='max-w-3xl py-4 xl:py-8 mx-auto text-center'>
                        <h1 className='font-semibold text-2xl text-gradient mb-4'>Panjabi.AI - Python based NLP Toolkit for Punjabi language</h1>
                        <p className='mb-8 text-gray'>Integrate <Link className='text-primary underline hover:underline-offset-4' target="_blank" to="https://pypi.org/project/panjabi.AI/" ><b>Panjabi.AI</b></Link> for your various Punjabi NLP needs.</p>
                        <hr className='my-4 text-gray' />
                        {/* <p>Note: Learn more from <a target="_blank" className='underline hover:underline-offset-4' href=""><strong></strong></a></p> */}
                        <Link className='px-4 btnPrimary text-white font-semibold py-2 d-inline-block mx-2 mt-4' target="_blank" to="https://pypi.org/project/panjabi.AI/">Learn more about python pakage</Link>
                        {/* <div className='text-center'>
                            <button type='button' className="px-4 btnPrimary text-white font-semibold py-2 d-inline-block mx-2">
                                Sign up for free
                            </button>
                            <button type='button' className="px-4 btnOutline text-primary font-semibold py-2 d-inline-block mx-2">
                                Read the Docs
                            </button>
                        </div> */}
                    </div>
                </div>

                <div className='p-5 xl:py-12'>
                    <div className='max-w-6xl mx-auto'>
                        {/* <h1 className='text-gray font-semibold text-2xl mb-5 lg:mb-10 text-center'>The all of your translation needs in one place</h1> */}

                        <ul className='list-none m-0 p-0 flex gap-8 flex-wrap justify-center'>
                            <li className={`linkBox min-w-[240px] relative rounded-xl bgLight p-4 lg:py-8 text-gray text-center ${pop == 'translation' ? 'border-2' : ''}`}>
                                <span className='ico pu-trans'></span>
                                <div className='pt-5 text-sm'>
                                    <Link id='translation' onClick={(e) => handleClick(e)} to=''>Machine Translation</Link>
                                </div>
                            </li>
                            <li className={`linkBox min-w-[240px] relative rounded-xl bgLight p-4 lg:py-8 text-gray text-center ${pop == 'machine_transliteration' ? 'border-2' : ''}`}>
                                <span className='ico machine-trans'></span>
                                <div className='pt-5 text-sm'>
                                    <Link id='machine_transliteration' onClick={(e) => handleClick(e)} to=''>Machine Transliteration</Link>
                                </div>
                            </li>
                            {/* <li className={`linkBox min-w-[240px] relative rounded-xl bgLight p-4 lg:py-8 text-gray text-center ${pop == 'ner' ? 'border-2' : ''}`}>
                                <span className='ico doc'></span>
                                <div className='pt-5 text-sm'>
                                    <Link id='document_translation' onClick={(e) => handleClick(e)} to=''>Document Translation</Link>
                                </div>
                            </li> */}
                            <li className={`linkBox min-w-[240px] relative rounded-xl bgLight p-4 lg:py-8 text-gray text-center ${pop == 'ner' ? 'border-2' : ''}`}>
                                <span className='ico voice-recog'></span>
                                <div className='pt-5 text-sm'>
                                    <Link id='ner' onClick={(e) => handleClick(e)} to=''>Named Entity Recognition</Link>
                                </div>
                            </li>
                            <li className={`linkBox min-w-[240px] relative rounded-xl bgLight p-4 lg:py-8 text-gray text-center ${pop == 'pos' ? 'border-2' : ''}`}>
                                <span className='ico speech'></span>
                                <div className='pt-5 text-sm'>
                                    <Link id='pos' onClick={(e) => handleClick(e)} to=''>Part of Speech</Link>
                                </div>
                            </li>
                            {/* <li className={`linkBox min-w-[240px] relative rounded-xl bgLight p-4 lg:py-8 text-gray text-center ${pop == 'id' ? 'border-2' : ''}`}>
                                <span className='ico upload'></span>
                                <div className='pt-5 text-sm'>
                                    <Link id='upload_corpus' onClick={(e) => handleClick(e)} to=''>Upload Corpus</Link>
                                </div>
                            </li>
                            <li className={`linkBox min-w-[240px] relative rounded-xl bgLight p-4 lg:py-8 text-gray text-center ${pop == 'id' ? 'border-2' : ''}`}>
                                <span className='ico gurbani'></span>
                                <div className='pt-5 text-sm'>
                                    <Link id='gurbani' onClick={(e) => handleClick(e)} to=''>Gurbani</Link>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>


                <div className='px-8 py-5'>
                    <div className='grayBox rounded-xl overflow-hidden'>
                        <Tabs>
                            <TabList>
                                <Tab><span>Info</span></Tab>
                                {/* <Tab><span>API Documentation</span></Tab>
                                <Tab><span>Pricing</span></Tab>
                                <Tab><span>FAQ</span></Tab> */}
                            </TabList>
                            <TabPanel>
                                <div className='px-6 py-4'>
                                    <div className='boxBdr block lg:flex justify-between items-center p-5 mb-4'>
                                        <div>
                                            <div className='flex items-center'>
                                                <button type='button' className="px-4 py-2 btnOutlineGray text-slate-5 font-semibold d-inline-block uppercase mr-3">
                                                    {info.request_type}
                                                </button>
                                                <div>
                                                    <Link className='text-primary' to='#'>{info.endpoint[endpoint]['url']}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <APISelect endpoints={endpoints} setendpoints={setendpoints} endpoint={endpoint} setendpoint={setendpoint} />
                                        </div>
                                    </div>
                                    <div className='highBG mb-4'>
                                        <SyntaxHighlighter language="javascript" style={dark}>
                                            {info.endpoint[endpoint]["request_body"]}
                                        </SyntaxHighlighter>
                                    </div>
                                    <div className='highBG mb-4'>
                                        <SyntaxHighlighter language="javascript" style={dark}>
                                            {info.endpoint[endpoint]["response_body"]}
                                        </SyntaxHighlighter>
                                    </div>
                                </div>
                            </TabPanel>
                            {/* <TabPanel>
                                <div className='px-6 py-4'>
                                    <div className='boxBdr block lg:flex justify-between items-center p-5 mb-4'>
                                        <div>
                                            <div className='flex items-center'>
                                                <button type='button' className="px-4 py-2 btnOutlineGray text-slate-5 font-semibold d-inline-block uppercase mr-3">
                                                    Post
                                                </button>
                                                <div>
                                                    <Link className='text-primary' to='https://nlp-toolkit.panjabi.ai/ner_ktrain'>https://nlp-toolkit.panjabi.ai/ner_ktrain</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <APISelect />
                                        </div>
                                    </div>
                                    <div className='highBG mb-4'>
                                        <SyntaxHighlighter language="javascript" style={dark}>
                                            {codeString}
                                        </SyntaxHighlighter>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='px-6 py-4'>
                                    Lorem ipsum
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='px-6 py-4'>
                                    <APIFaq />
                                </div>
                            </TabPanel> */}
                        </Tabs>
                    </div>
                </div>


                <div className='copyFloat'><AuthCopy /></div>
            </div>
    )
}

export default ApiIndex
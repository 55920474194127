import React from 'react'
import { Link, useParams } from "react-router-dom"
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

function NavItems() {
    const [active, setactive] = useState('dsahboard')
    const Data = useSelector(item => item)
    let location = useLocation()
    const pathNameArray = location.pathname.split('/')
    const pathName = "/"+pathNameArray[1]

    
    return (
        <>
            <ul className="nav items-center justify-center lg:flex">
                <li className={`text-white hover:text-indigo-200 ${['/', '/home-public' , '/home-admin' , '/leaderboard'].includes(location.pathname) ? 'active' : ''}`}>
                    <Link id='dashboard' to='/home-public' >Dashboard</Link>
                </li>
                <li className={`text-white hover:text-indigo-200 ${['/ner', '/pos', '/translation', '/services' , '/transliteration' ].includes(location.pathname) ? 'active' : ''}`}>
                    <Link  id='services' to='/services'>Our Services</Link>                
                </li>
                <li className={`text-white hover:text-indigo-200 ${['/contribution', '/nerContribution', '/posContribution', '/view_corpuses' , '/view_taggings','/upload_new_tagging','/view_image_tagging','/add_image_tagging','/nerEditTag','/nerCreateNew','/posCreateNew','/posEditTag', '/upload_new_corpus', '/speech-recognition','/view_speech_taggings','/speech-recognition','/edit_speech_tag' ].includes(pathName) ? 'active' : ''}`}>
                    <Link id='contribution' to='/contribution'>Contribution</Link>
                </li>
                <li className={`text-white hover:text-indigo-200 ${location.pathname == '/api' ? 'active' : ''}`}>
                    <Link id='api' to='/api'>API</Link>
                </li>
                <li className={`text-white hover:text-indigo-200 ${location.pathname == '/gurmukhi' ? 'active' : ''}`}>
                    <Link id='gurmukhi' to='/gurmukhi'>Gurmukhi Alphabets</Link>
                </li>
                {/* <li className={`text-white hover:text-indigo-200 ${location.pathname == '/fonts' ? 'active' : ''}`}>
                    <Link id='fonts' to='/fonts'>Panjabi Fonts</Link>
                </li> */}
                {/* <li className={`text-white hover:text-indigo-200 ${location.pathname == '/dictionary' ? 'active' : ''}`}>
                    <Link id='dictionary' to='/dictionary'>Dictionary</Link>
                </li> */}
            </ul>
        </>
    )
}

export default NavItems
import * as React from 'react'
import styles from '../shared/common.module.css'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useNavigate } from 'react-router-dom'

function APISelect({endpoints , setendpoints , endpoint, setendpoint}) {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate()

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <>
            <div className='flex items-center'>
                <div>
                    <strong>End Point:</strong>
                </div>
                <div className='pl-3'>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <div className={styles.dropbox}>
                            <button type="button" onClick={handleClick}>
                                {endpoint}<ArrowDropDownOutlinedIcon />
                            </button>
                            {open ? (
                                <div  className={styles.dropped}>
                                    <div onClick={()=> setendpoint(endpoints[0])} className={styles.ddItem}>
                                        <input checked={endpoint==endpoints[0]} type='radio' name="selectname" />
                                        <label>{endpoints[0]}</label>
                                        <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                                    </div>
                                    <div onClick={()=> setendpoint(endpoints[1])} className={styles.ddItem}>
                                        <input checked={endpoint==endpoints[1]} type='radio' name="selectname" />
                                        <label>{endpoints[1]}</label>
                                        <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </ClickAwayListener>
                </div>
            </div>
        </>

    );
}

export default APISelect
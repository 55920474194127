import React, { useState, useEffect } from 'react'
import styles from '../../shared/common.module.css'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { grey } from '@mui/material/colors'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useGetCategories } from './hooks'
import { ToastContainer, toast } from 'react-toastify';
import { LABELS } from '../../../config'

function CorpusContent({ formik }) {

    const cat_hook = useGetCategories()
    const [cats, setcats] = useState({})

    useEffect(() => {
        if (cat_hook.isSuccess) {
            if (cat_hook.data.data.length === 0) {
                setcats({})

            }
            else {

                setcats(cat_hook.data.data[0])
            }

        }
        else if (cat_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [cat_hook.isSuccess, cat_hook.isError, cat_hook.data])

    useEffect(() => {
        cat_hook.refetch()

        return () => {
            cat_hook.remove()
        }
    }, [])



    return (
        <>
            <div className='grayBox rounded-xl h-full mb-5'>
                <h2 className={styles.contTitle}>Content Details</h2>
                <div className='p-4 lg:p-6'>
                    <form className={`${styles.bdrFields} block max-w-screen-md mx-auto`}>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-5'>
                            <div>
                                <label className='block mb-2'>Name</label>
                                <input name='name' id="name" value={formik.values.name} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                            </div>
                            <div>
                                <label className='block mb-2'>Source of Data</label>
                                <input name='source' id="source" value={formik.values.source} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                            </div>

                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-5'>
                            <div>
                                <label className='block mb-2' htmlFor="category">Category</label>
                                <select
                                    id="category"
                                    name="category"
                                    onChange={formik.handleChange}
                                    value={formik.values.category}
                                >
                                    <option value="" label="Select Category" />
                                    {Object.keys(cats).map(itm => {
                                        return (
                                            <option value={itm} label={itm.toUpperCase()} />
                                        )
                                    })}

                                </select>
                            </div>

                            <div>
                                <label className='block mb-2' htmlFor="sub_category">Sub Category</label>
                                <select
                                    id="sub_category"
                                    name="sub_category"
                                    onChange={formik.handleChange}
                                    value={formik.values.sub_category}
                                >
                                    <option value="" label="Select Sub Category" />
                                    {cats[formik.values.category]?.map(itm => {
                                        return (
                                            <option value={itm} label={itm.toUpperCase()} />
                                        )
                                    })}

                                </select>

                            </div>

                        </div>

                        <div className='mb-5'>
                            <label className='block mb-2' htmlFor="label">Label</label>
                            <select
                                id="label"
                                name="label"
                                onChange={formik.handleChange}
                                value={formik.values.label}
                            >
                                <option value="" label="Select Label" />
                                {LABELS.map(itm => {
                                    return (
                                        <option value={itm} label={itm.toUpperCase()} />
                                    )
                                })}

                            </select>

                        </div>


                        <div className='grid grid-cols-1 gap-4 mb-5'>
                            <div>
                                <label className='block mb-2'>Description of Data</label>
                                <input name='description' id="description" value={formik.values.description} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                            </div>
                        </div>

                        {/* <div className='mb-5'>
                            <FormControlLabel required control={<Checkbox sx={{
                                color: grey[400],
                                '&.Mui-checked': {
                                    color: grey[200],
                                },
                            }} />} label="The content shared above is licensed." />
                        </div> */}
                        
                        <div className='lg:pt-8'>
                            <button onClick={formik.handleSubmit} className='btnPrimary text-white font-semibold py-3 px-6'>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default CorpusContent

export const Codes = {
    ' ': ' ',
    '`': '`',
    '~': '੍ਹ',
    '1': '1',
    '!': '੍ਵ',
    '2': '2',
    '@': '੍ਯ',
    '3': '3',
    '#': '੍ਰ',
    '4': '4',
    '$': 'ੱ',
    '5': '5',
    '%': '%',
    '6': '6',
    '^': '^',
    '7': '7',
    '&': '&',
    '8': '8',
    '*': '*',
    '9': '9',
    '(': '(',
    '0': '0',
    ')': ')',
    '-': '-',
    '_': '_',
    '=': '=',
    '+': '+',
    'Backspace': '',
    'Tab': '',
    'Q': 'ਔ',
    'q': 'ੌ',
    'W': 'ਐ',
    'w': 'ੈ',
    'E': 'ਆ',
    'e': 'ਾ',
    'R': 'ਈ',
    'r': 'ੀ',
    'T': 'ਊ',
    't': 'ੂ',
    'Y': 'ਭ',
    'y':'ਬ',
    'U': 'ਙ',
    'u': 'ਹ',
    'I': 'ਘ',
    'i': 'ਗ',
    'O': 'ਧ',
    'o': 'ਦ',
    'P': 'ਝ',
    'p': 'ਜ',
    '{': 'ਢ',
    '[': 'ਡ',
    '}': 'ਞ',
    ']': '਼',
    '||': '',
    '\\': '', 
    'CapsLock': '',
    'A': 'ਓ',
    'a': 'ੋ',
    'S': 'ਏ',
    's': 'ੇ',
    'D':'ਅ',
    'd':'੍',
    'F': 'ਇ',
    'f': 'ਿ',
    'G': 'ਉ',
    'g': 'ੁ',
    'H': 'ਫ',
    'h': 'ਪ',
    'J': 'ੜ',
    'j': 'ਰ',
    'K': 'ਖ',
    'k': 'ਕ',
    'L': 'ਥ',
    'l': 'ਤ',
    ':': 'ਚ',
    ';': 'ਛ',
    '"': 'ਠ',
    "'" : 'ਟ',
    'Enter': '',
    'Shift': '',
    'Z': '',
    'z': '',
    'X': 'ਂ',
    'x': 'ੰ',
    'C': 'ਣ',
    'c': 'ਮ',
    'V': '',
    'v': 'ਨ',
    'B': 'ੲ',
    'b': 'ਵ',
    'N': 'ਲ਼',
    'n': 'ਲ਼',
    'M': 'ਸ਼',
    'm': 'ਸ',
    '<': '<',
    ',': ',',
    '>': '।',
    '.': '.',
    '?': '?',
    '/': 'ਯ',
}

export const repeatCodes = {
    '~': '੍ਹ',
    '!': '੍ਵ',
    '@': '੍ਯ',
    '#': '੍ਰ',
    '$': 'ੱ',
    'q': 'ੌ',
    'w': 'ੈ',
    'e': 'ਾ',
    'r': 'ੀ',
    't': 'ੂ',
    ']': '਼',
    'a': 'ੋ',
    's': 'ੇ',
    'd':'੍',
    'f': 'ਿ',
    'g': 'ੁ',
    'X': 'ਂ',
    'x': 'ੰ',
}


// let repeatKeys = {
//     65 : 'ੋ',
//     68 : '੍',
// 69 : 'ਾ',
// 70 : 'ਿ',
// 71 : 'ੁ',
// 81 : 'ੌ',
// 82 : 'ੀ',
// 83 : 'ੇ',
// 84 : 'ੂ',
// 87 : 'ੈ',
// 88 : 'ੰ',
// 221:  '਼',
// }

// let repeatShiftKeys = {
//     49 : '੍ਵ',
// 50 : '੍ਯ',
// 51 : '੍ਰ',
// 52 : 'ੱ',
// 88 : 'ਂ',
// 192:'੍ਹ',


// }


// let keys = {
// 16: '',
// 48 : '0', 
// 49 : '1',
// 50 : '2',
// 51 : '3',
// 52 : '4',
// 53 : '5',
// 54 : '6',
// 55 : '7',
// 56 : '8',
// 57 : '9',
// 59 : 'ਚ',
// 61 : '',
// 65 : 'ੋ',
// 66 : 'ਵ',
// 67 : 'ਮ',
// 68 : '੍',
// 69 : 'ਾ',
// 70 : 'ਿ',
// 71 : 'ੁ',
// 72 :'ਪ',
// 73 : 'ਗ',
// 74 : 'ਰ',
// 75 : 'ਕ',
// 76 : 'ਤ',
// 77 : 'ਸ',
// 78 : 'ਲ',
// 79 : 'ਦ',
// 80 : 'ਜ',
// 81 : 'ੌ',
// 82 : 'ੀ',
// 83 : 'ੇ',
// 84 : 'ੂ',
// 85 : 'ਹ',
// 86 : 'ਨ',
// 87 : 'ੈ',
// 88 : 'ੰ',
// 89 : 'ਬ',
// 219: 'ਡ',
// 221:  '਼',
// 186: 'ਚ',
// 222: 'ਟ',
// 188: ',',
// 190: '.',
// 191: 'ਯ',
// 192:'',
// }

// let shiftKeys = { 
// 16: '',
// 48 : ')', 
// 49 : '੍ਵ',
// 50 : '੍ਯ',
// 51 : '੍ਰ',
// 52 : 'ੱ',
// 53 : '5',
// 54 : '6',
// 55 : '7',
// 56 : '8',
// 57 : '(',
// 59 : 'ਛ',
// 61 : '',
// 65 : 'ਓ',
// 66 : 'ੲ',
// 67 : 'ਣ',
// 68 : 'ਅ',
// 69 : 'ਆ',
// 70 : 'ਇ',
// 71 : 'ਉ',
// 72 :'ਫ',
// 73 : 'ਘ',
// 74 : 'ੜ',
// 75 : 'ਖ',
// 76 : 'ਥ',
// 77 : 'ਸ਼',
// 78 : 'ਲ਼',
// 79 : 'ਧ',
// 80 : 'ਝ',
// 81 : 'ਔ',
// 82 : 'ਈ',
// 83 : 'ਏ',
// 84 : 'ਊ',
// 85 : 'ਙ',
// 86 : 'ਨ',
// 87 : 'ਐ',
// 88 : 'ਂ',
// 89 : 'ਭ',
// 219: 'ਢ',
// 221:  'ਞ',
// 186: 'ਛ',
// 222: 'ਠ',
// 188: ',',
// 190: '।',
// 191: 'ਯ',
// 192:'੍ਹ',
// }


export const cCodes = {
    '\n': '\n',
    ' ': ' ',
    '`':'!',
    '~':'-ੱ',
    '1':'1',
    '!':'',
    '2':'2',
    '@':'',
    '3':'3',
    '#':'',
    '4':'4',
    '$':'',
    '5':'5',
    '%':'',
    '6':'6',
    '^':'',
    '7':'7',
    '&':'',
    '8':'8',
    '*':'',
    '9':'9',
    '(':'',
    '0':'0',
    ')':'',
    '-':'',
    '_':'',
    '=':'',
    '+':'',
 
 
    'q':'੍ਰ',
    'Q':'੍ਹ',
    'w':'ਮ',
    'W':'ਰੁ',
    'e':'ਕ',
    'E':'ਥ',
    'r':'ਗ',
    'R':'ਞ',
    't':'ਵ',
    'T':'ੳ',
    'y':'ਖ',
    'Y':'ਢ',
    'u':'ਚ',
    'U':'ਓ',
    'i':'ਜ',
    'I':'ਜ਼',
    'o':'ਰ',
    'O':'+',
    'p':'ਬ',
    'P':'ਸ਼',
    '[':'ੁ',
    '{':'ੂ',
    ']':'',
    '}':'ਗ਼',
    '\\':'ਖ਼',
    '|':'ਫ਼',
 
    'a':'਼',
    'A':'ਂ',
    's':'ਤ',
    'S':'ਛ',
    'd':'ਦ',
    'D':'ਣ',
    'f':'ਿ',
    'F':'-',
    'g':'ਪ',
    'G':'ਭ',
    'h':'ੀ',
    'H':'.',
    'j':'ਹ',
    'J':'ੲ',
    'k':'ਾ',
    'K':'ਾਂ',
    'l':';',
    'L':':',
    ';':'ਸ',
    ':':'ਯ',
    "'":'ੋ',
    '"':'ੌ',
 
    'z':'ੰ',
    'Z':'ੱ',
    'x':'ਘ',
    'X':'ਧ',
    'c':'ਫ',
    'C':'ਙ',
    'v':'ਡ',
    'V':'ੜ',
    'b':'ਲ',
    'B':'ਨ',
    'n':'ਅ',
    'N':'ਟ',
    'm':'ਠ',
    'M':'ਝ',
    ',':',',
    '<':'?',
    '.':'',
    '>':'|',
    '/':'ੇ',
    '?':'ੈ'
 }
 
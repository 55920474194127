import DashCounter from './dash-shared/counter'
import ErrorsPercentage from './dash-shared/error-piechart'
import TopCatList from './dash-shared/topcat-list'
import TopLeaders from './dash-shared/topleaders'
import CategoriesChart from './dash-shared/categories-linechart'
import DtlsPie from './dash-shared/dtlsPiechart'
import AdminBarData from './admin-sections/admin-tags-bar'
import AdminNERDonut from './admin-sections/admin-ner-donutchart'
import classes from './dash.module.css'
import { useViewAdminDashboardDetails,useViewLeaderBoardDetails } from './dash-shared/hooks';
import React, { useState, useEffect, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';

function HomeAdmin() {


    const [childData, setChildData] = useState(null);
    const [userChartData, setUserChartData] = useState([]);
    const getAdminDashboardDetails = useViewAdminDashboardDetails()
    const getAdminLeaderBoards = useViewLeaderBoardDetails()
    // fetch all image tags
    const [adminDashboardDetails, setAdminDashboardDetails] = useState([])
    const [adminLeaderboardData, setAdminLeaderboardData] = useState([])
    useEffect(() => {
        try{
        if (getAdminDashboardDetails.isSuccess) {

            setAdminDashboardDetails(getAdminDashboardDetails.data);
            setChildData(0)

        }
        else if (getAdminDashboardDetails.isError) {
            toast.error("Error in Fetching Data")
        }
    }catch(e){
        toast.error("Error in fetching admin dashboard data",e)
    }
    }, [getAdminDashboardDetails.isSuccess, getAdminDashboardDetails.isError])


    useEffect(() => {

        try{

        if (getAdminLeaderBoards.isSuccess) {

            setAdminLeaderboardData(getAdminLeaderBoards.data);
            setChildData(0)

        }
        else if (getAdminLeaderBoards.isError) {
            toast.error("Error in fetching leaderboard data")
        }
        }catch(e){
            toast.error("Error in fetching leaderboard data",e)
        }
    }, [getAdminLeaderBoards.isSuccess, getAdminLeaderBoards.isError])


    useEffect(() => {
        getAdminDashboardDetails.refetch();
        getAdminLeaderBoards.refetch();
        return () => {
            getAdminDashboardDetails.remove();
            getAdminLeaderBoards.remove();
        }

    }, [])


    useEffect(() => {
        const allUsers = []
        for (const key in adminDashboardDetails?.user_tags) {
            allUsers.push(adminDashboardDetails?.user_tags[key])
        }
        setUserChartData(allUsers[childData])

    }, [childData])


    return (

        <>


<div className='dashTopPanel pt-3'>
                <div className='block lg:flex'>
                    <div className='lg:w-full'>
                        <DashCounter adminDashboardDetails={adminDashboardDetails} isUser={false}  />
                    </div>
                    {/* <div className='lg:w-1/3'>
                        <ErrorsPercentage adminDashboardDetails={adminDashboardDetails} />
                    </div> */}
                </div>
            </div>
            <TopLeaders adminLeaderboardData = {adminLeaderboardData?.data} />
            <div className='block lg:flex'>
                <div className='lg:w-2/3 bgLight'>
                    <CategoriesChart adminDashboardDetails={adminDashboardDetails?.category_bar_chart} />
                </div>
                <div className='lg:w-1/3'>
                    <TopCatList  adminDashboardDetails={adminDashboardDetails?.categories_progress}/>
                </div>
            </div>
        </>
    )
}

export default HomeAdmin
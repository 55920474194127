import classes from './dash.module.css'
import { useViewAdminDashboardDetails, useViewUserDashboardDetails } from './dash-shared/hooks';
import React, { useState, useEffect, useRef } from 'react'
import PublicTopSection from './public-sections/public-tops'
import PublicTablenBarData from './public-sections/public-tags-table-bar'
import NERDonut from './public-sections/ner-donutchart'
import { ToastContainer, toast } from 'react-toastify';

function PublicAdmin() {


     const [childData, setChildData] = useState(null);
     const [userChartData, setUserChartData] = useState([]);
     const getAdminDashboardDetails = useViewUserDashboardDetails()
    // fetch all image tags
    const [adminDashboardDetails, setAdminDashboardDetails] = useState([])
    useEffect(() => {

        if (getAdminDashboardDetails.isSuccess) {

            setAdminDashboardDetails(getAdminDashboardDetails.data);
            setChildData(0)

        }
        else if (getAdminDashboardDetails.isError) {
            toast.error("Error in Fetching Data")
        }
    }, [getAdminDashboardDetails.isSuccess, getAdminDashboardDetails.isError])


    useEffect(() => {
        getAdminDashboardDetails.refetch();
        return () => {
            getAdminDashboardDetails.remove();
        }

    }, [])


    useEffect(() => {        
        const allUsers = []
        for (const key in adminDashboardDetails?.user_tags) {
            allUsers.push(adminDashboardDetails?.user_tags[key])
        }
        setUserChartData(allUsers[childData])

    }, [childData])


    return (

        <>

            <div className='dashTopPanel py-3 px-6 lg:px-9'>
                <PublicTopSection adminDashboardDetails={adminDashboardDetails} isUser = {true} />
            </div>
            <div className='bgLight'>
                <div className={`${classes.darkBG} px-6 lg:px-9 py-4 mb-1`}>
                    <h4 className='text-md'>Tags</h4>
                    <p>Total progress of Tags as per total number of people.</p>
                </div>
 
                {(adminDashboardDetails?.ner_tags_assigned >= 1 || adminDashboardDetails?.pos_tags_assigned >= 1 || adminDashboardDetails?.speech_tags_assigned >= 1 || adminDashboardDetails?.image_tags_assigned >= 1) &&
                                  <div className={`${classes.darkBG} block lg:flex lg:gap-0`}>
                   <div className='lg:w-2/3'>
                        <PublicTablenBarData adminDashboardDetails={adminDashboardDetails} />
                    </div> 
                     <div className='lg:w-1/3'>
                        <NERDonut adminDashboardDetails={adminDashboardDetails} />
                    </div> 
                    </div>
                }
                 {!(adminDashboardDetails?.ner_tags_assigned >= 1 || adminDashboardDetails?.pos_tags_assigned >= 1 || adminDashboardDetails?.speech_tags_assigned >= 1 || adminDashboardDetails?.image_tags_assigned >= 1) &&
                     <div className={`${classes.darkBG} block lg:flex lg:gap-0`}>
                    <div className='lg:w-full'>
                        <PublicTablenBarData adminDashboardDetails={adminDashboardDetails} />
                    </div>
                    </div>
                }
                </div>

        </>
    )
}

export default PublicAdmin
import React, { useState, useRef , useEffect } from 'react';
import LoaderForAll from '../components/loader-for-all';
import { BACKEND_URL } from '../config';
import { useNavigate } from 'react-router-dom';
import { useSendOtpRegister, useVerifyOtp } from './hooks';
import { ChangePassword } from './changePassword';
import { Verified } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const OtpInput = ({ email}) => {
    let length = 6;
  const [otp, setOtp] = useState(Array(length).fill(''));
  const inputRefs = useRef([]);
  const [resendDis, setresendDis] = useState(false)
  const [load, setload] = useState(false)
  let navigate = useNavigate()
  const [varified, setvarified] = useState(false)
  const verifyOtp_hook = useVerifyOtp()
  const resendOtpRegister = useSendOtpRegister()


  const handleInputChange = (index, event) => {
    const value = event.target.value;

    if (isNaN(value)) {
      return;
    }

    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    // Focus on the next input field
    if (value !== '') {
      if (index === length - 1) {
        // If the current input is the last one, blur it
        inputRefs.current[index].blur();
      } else {
        // Otherwise, focus on the next input
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleInputPaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain').trim();
    if (pasteData.length === length) {
      setOtp(pasteData.split(''));
    }
  };

  const handleInputKeyDown = (index, event) => {
    if (event.key === 'Backspace' && otp[index] === '' && index > 0) {
      // If the current input is empty and Backspace is pressed,
      // move focus to the previous input field
      inputRefs.current[index - 1].focus();
    }
  };


async function verifyOtp() {
    let iOtp = otp.reduce((acc , cur) => {
        return acc + cur 
      },"")
    const data = {
        email : email ,
        otp : parseInt(iOtp)
    }
    verifyOtp_hook.mutate(data)
   

}

useEffect(() => {
  if (verifyOtp_hook.isError) {
    if (verifyOtp_hook.error?.response?.data?.message) {
      
      toast.error(verifyOtp_hook.error?.response?.data?.message)
     
    }
    else{
      toast.error(verifyOtp_hook.error?.message)
    }
}

  if (verifyOtp_hook.isSuccess) {
    setvarified(true)
  }
}, [verifyOtp_hook.isSuccess , verifyOtp_hook.isError])






const formatEmail = (email) => {
  const [username, domain] = email.split('@');
  const formattedUsername = username.substring(0, 3) + '*'.repeat(username.length - 3);
  return `${formattedUsername}@${domain}`;
};



  return (
    <>
    {verifyOtp_hook.isLoading ? <LoaderForAll /> : 
    <>
    {varified ? <ChangePassword email={email} otp={otp}/>: 
    <>
    <h2 className='uppercase text-2xl md:text-3xl mb-2'>Verify OTP</h2>
      <div id="scndScreen">
        <p className='text-xs mb-5'>Enter the 6 digit verification code sent on {formatEmail(email)}</p>
        
        <form name='forgetPassword2'>
            <div className='flex justify-between text-sm mb-3'>
                <div>
                    <label className='text-primary w-full font-semibold'>Enter Code</label>
                </div>
                {resendOtpRegister.isLoading ? null : 
                <div>
                    <button onClick={() =>resendOtpRegister.mutate({"email" : email})}>Resend Code?</button>
                </div> }
            </div> 

            <div className='flex gap-3 mb-3'>
            {otp.map((digit, index) => (
                <div className='flex-none'>
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      value={digit}
                      onChange={(event) => handleInputChange(index, event)}
                      onPaste={handleInputPaste}
                      onKeyDown={(event) => handleInputKeyDown(index, event)}
                      ref={(ref) => (inputRefs.current[index] = ref)}
                      className='w-8 inpFld text-center px-0' 
                      placeholder='0' 
                    />
                  </div>
                ))}
              
            </div>                              

            <div className='grid grid-cols-2 gap-4 pt-5'>
                <div>
                    <button onClick={verifyOtp} type='submit' className='btnPrimary text-white font-semibold py-3 w-full'>
                        Verify
                    </button>
                </div>
                <small className='error'>{Error.err}</small>
                <div>
                    &nbsp;
                </div>
            </div>
        </form>
    </div> 
    </> }
    
    </> }
      </>
     
  );
};


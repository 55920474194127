import React, { useState, useEffect } from 'react'
import styles from '../../shared/common.module.css'
import AuthCopy from '../../../auth/shared/auth-copyright'
import Select from '../../services/select'
import DragDrop from './dropfiles'
import CorpusContent from './corpus-content'
import CorpusListing from './tagging-listing'
import { useMutation, useQuery } from 'react-query'
import { BACKEND_URL } from '../../../config'
import axios from 'axios'
import { useFormik } from 'formik';
import { UploadCorpusSchema,UploadImageSchema } from '../../../auth/authSchema'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'
import LoaderForAll from '../../../components/loader-for-all'
import { useAddImageForTags } from './hooks'
import { Navigate, useNavigate, useSearchParams, createSearchParams } from 'react-router-dom'


function UploadTaggingImage() {

    const Data = useSelector(state => state)
    const [newupload, setnewupload] = useState(false)
    const fileTypes = ['txt', 'jpg', 'png']
    const [file, setFile] = useState(null);
    const [viewcorpus, setviewcorpus] = useState(false)
    const upload_tag_image_hook = useAddImageForTags()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const tabId = searchParams.get('tabId');

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            source: "",
            category: "",
            sub_category: ""
        },
        //validationSchema: UploadImageSchema,
        onSubmit: (values) => {          
            
           if(validateForm(values)){
            return;
           }
            const fd = new FormData()
            fd.append("name", values.name)
            fd.append("category", values.category)
            fd.append("sub_category", values.sub_category)
            fd.append("source", values.source)
            fd.append("description", values.description)
            fd.append("file", file)

            upload_tag_image_hook.mutate(fd)
        }
    });

    const validateForm = (values) =>{
        let isError = false;
        if(values.name===''){
            toast.error("Name field is empty")
            isError = true
        }else if(values.source===''){
            toast.error("Source field is empty")
            isError = true
        }else if(values.category===''){
            toast.error("Category field is empty")
            isError = true
        }else if(values.sub_category===''){
            toast.error("Sub category field is empty")
            isError = true
        }else if(values.description===''){
            toast.error("Description field is empty")            
            isError = true
        }else if(!file){
            toast.error("Upload a file")
            isError = true
           } 
        return isError;
    }

    useEffect(() => {
        if (upload_tag_image_hook.isSuccess) {
            navigate('/view_taggings')
            toast.success("Uploaded")
        }
        else if (upload_tag_image_hook.isError) {
            toast.error("Upload Failed")
        }
    }, [upload_tag_image_hook])






    return (
        <>

            {upload_tag_image_hook.isLoading ? <LoaderForAll /> :
                <>
                    <div className='pageWrap lightWhite withCopy'>
                        <div className='px-5 lg:px-8 pt-4'>
                            <div>
                                <button type="button" onClick={() => navigate({
                                    pathname: '/view_taggings',
                                    search: `?${createSearchParams({ tabId: tabId })}`,
                                })} className='backTxtBtn' >Back</button>
                            </div>
                            <div className='block lg:flex gap-4'>
                                <div className='lg:w-1/2'>
                                    <div className='grayBox rounded-xl h-full mb-4'>
                                        <div className='flex items-center justify-center h-[360px] lg:h-[480px]'>
                                            <DragDrop file={file} setFile={setFile} fileTypes={fileTypes} />
                                        </div>
                                    </div>
                                </div>
                                <div className='lg:w-1/2'>
                                    <CorpusContent formik={formik} />
                                </div>
                            </div>
                        </div>
                        <div className='copyFloat'><AuthCopy /></div>
                    </div>
                </>}


        </>
    )
}

export default UploadTaggingImage
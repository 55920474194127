import React from 'react'
import classes from '../dash.module.css'

function DataTableProfile(userProfileDetails) {

    const userProfile = userProfileDetails?.userProfileDetails;
    return (
        <>
            <div className='py-6 px-6 lg:px-9'>
                <div className={classes.tagsTableWrap}>
                    <table className={classes.tagsTableBdr}>
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>{userProfile?.ner_tags_assigned || 0} <span>NER</span></th>
                                <th>{userProfile?.pos_tags_assigned || 0} <span>POS</span></th>
                                <th>{userProfile?.speech_tags_assigned || 0} <span>Speech</span></th>
                                <th>{userProfile?.image_tags_assigned || 0} <span>Images</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={classes.accepted}>
                                <td>
                                    <img src="/svg/accepted-ico.svg" alt="" />
                                    <span className='pl-2'>Reviewed</span>
                                </td>
                                <td>{userProfile?.ner_tags_reviewed || 0}</td>
                                <td>{userProfile?.pos_tags_reviewed || 0}</td>
                                <td>{userProfile?.speech_tags_reviewed || 0}</td>
                                <td>{userProfile?.image_tags_reviewed || 0}</td>
                            </tr>
                            
                            <tr className={classes.pending}>
                                <td>
                                    <img src="/svg/pending-ico.svg" alt="" />
                                    <span className='pl-2'>Pending</span>
                                </td>
                                <td>{userProfile?.ner_tags_pending || 0}</td>
                                <td>{userProfile?.pos_tags_pending || 0}</td>
                                <td>{userProfile?.speech_tags_pending || 0}</td>
                                <td>{userProfile?.image_tags_pending || 0}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default DataTableProfile
import React from 'react'
import LoaderForAll from '../../../components/loader-for-all'
import { useState } from 'react'
import { BACKEND_URL } from '../../../config'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export function DeleteCorpusPop({setpop , s3_uri , set_s3uri , setisDeleted }) {

    const [load, setload] = useState(false)
    const Data = useSelector(state => state)

    async function handleDelete(e) {
        e.preventDefault()
        try {
            setload(true)
            
            
            //send otp
            let res = await fetch(BACKEND_URL + `/delete_corpus?s3_uri=${s3_uri}` , {
                method : "DELETE" , 
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' +  Data.auth.access_token
                }
            })
            if (res.status ==200){
                setisDeleted(true)
                setpop(false)
            }
            

            setload(false)

        } 
        catch (error) {
            
            setload(false)
        }
    }


    return (
        <>
        {load ? <LoaderForAll /> : 
        
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
               
                <div className="relative py-2 px-4 flex-auto">
                  <p className="my-4 text-black text-lg leading-relaxed">
                    Are you sure want to Delete ?
                  </p>
                </div>

                <div className='flex justify-center items-center pb-4'>
                    <div className='px-2'>
                        <button type='button' className="px-4 btnPrimary text-white font-semibold py-2 outline-none focus:outline-none" onClick={handleDelete}>Yes</button>
                    </div>
                    <div className='px-2'>
                        <button className="p-1 ml-auto bg-transparent border-0 text-black float-right leading-none font-semibold outline-none focus:outline-none" onClick={()=> {
                            set_s3uri(null)
                            setpop(false)}}>No</button>
                    </div>
                </div>
               
                
                
              </div>
            </div>
          </div>
}
          <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}


import React, { useState, useEffect } from 'react'
import styles from '../../shared/common.module.css'
import AuthCopy from '../../../auth/shared/auth-copyright'
import Select from '../../services/select'
import DragDrop from './dropfiles'
import CorpusContent from './corpus-content'
import TaggingListing from './tagging-listing'
import { useMutation, useQuery } from 'react-query'
import { BACKEND_URL } from '../../../config'
import axios from 'axios'
import { useFormik } from 'formik';
import { UploadCorpusSchema } from '../../../auth/authSchema'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'
import LoaderForAll from '../../../components/loader-for-all'
import { useAddImageForTags, useViewTagImages, useViewImagesTags } from './hooks';



function ViewTagging() {

    const Data = useSelector(state => state);
    const [newupload, setnewupload] = useState(false)

    const [file, setFile] = useState(null);
    const [viewcorpus, setviewcorpus] = useState(false)

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            source: "",
            category: "",
            sub_category: ""
        },
        validationSchema: UploadCorpusSchema,
        onSubmit: (values) => {
            if (!file) {
                return
            }
            var fd = new FormData()
            fd.append("name", values.name)
            fd.append("category", values.category)
            fd.append("sub_category", values.sub_category)
            fd.append("source", values.source)
            fd.append("description", values.description)
            fd.append("file", file)

            useAddImageForTags.mutate(fd)
        }
    });


    return (
        <>
            
                <div className='pageWrap lightWhite withCopy'>                    
                    <div className={`${styles.pagecontainer} ${styles.pxC}`}>
                        <TaggingListing />
                    </div>
                    <div className='copyFloat'><AuthCopy /></div>
                </div>
        </>
    )
}

export default ViewTagging
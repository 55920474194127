import React, { useEffect, useState, useRef } from 'react'
import styles from '../../shared/common.module.css'
import Slider from "react-slick"
import { useNavigate } from 'react-router-dom'
import { useUpdateSpeechTags, useUploadSpeechToS3 } from './hooks';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import AudioReactRecorder, { RecordState } from 'audio-react-recorder'

//import { TokenAnnotator } from "react-text-annotate";
//import LoaderForAll from '../../../components/loader-for-all'
//import { POS_TAGS } from '../../../config';
import { ErrorBoundary } from 'react-error-boundary';
import { useReactMediaRecorder } from "react-media-recorder";
//import SpeechTextPane from './speech-textpane'
import MicRecorder from "mic-recorder-to-mp3"
import AWS from 'aws-sdk';
import axios from 'axios'
import { BACKEND_URL } from '../../../config';
import { useSelector } from 'react-redux'
import { redux_logout } from "../../../auth/authSlice";
import { useDispatch } from 'react-redux'


const mimeType = "audio/mp4";
const SpeechTagEdit = (tagDetails) => {
    const Data = useSelector(state => state)
    let dispatch = useDispatch()


    const recorder = useRef(null) //Recorder
    const audioPlayer = useRef(null) //Ref for the HTML Audio Tag
    //const [blobURL, setBlobUrl] = useState(null)
    const [allBlobs, setAllBlobs] = useState([])
    const [oldBlob, setOldBlobs] = useState([])
    const [currentUrl, setCurrentUrl] = useState([])

    const [audioFile, setAudioFile] = useState(null)
    const [isRecording, setIsRecording] = useState(null)
    const sTagValue = tagDetails.tagDocument


    const [blobURL, setBlobUrl] = useState(sTagValue.speech_url)
    const [S3_key, setS3_key] = useState(sTagValue.s3_key)
    const mediaRecorder = useRef(null);
    const navigate = useNavigate();
    const [isSubmit, setIsSubmit] = useState(false);
    const updatePosTags_hook = useUpdateSpeechTags()
    const [second, setSecond] = useState("00");
    const [minute, setMinute] = useState("00");
    const [isActive, setIsActive] = useState(false);
    const [counter, setCounter] = useState(0);
    const [isPlay, setIsPlay] = useState(false);
    const audioSrc = ''
    let audioRef = useRef(new Audio(audioSrc));
    const [audioData, setAudioData] = useState({
        playing: false,
        currentTime: 0,
        duration: 0,
        volume: 1,
        currentTrack: 0,
        tracks: [],
    })
    const [combinedBlob, setCombinedBlob] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);

    const { upload_s3_hook, Progress , setUploadCancelToken , uploadCancelToken} = useUploadSpeechToS3()
    
    const [fileUploaded, setfileUploaded] = useState(false)

    const handleDeleteClick = () => {
        setShowConfirm(true);
    };

    const handleDelete = () => {
        try {
            setCounter(0)
            setSecond('00');
            setMinute('00');
            setAudioData({ playing: false })
            setAudioFile(null);
            setBlobUrl(null);
            setAllBlobs([])
            setIsPlay(false)
            setShowConfirm(false);
            setIsSubmit(false);
            audioRef.current = ''
        } catch (error) {
            return <ErrorHandler error={error} />
        }

    };

    const handleCancel = () => {
        setShowConfirm(false);
    };
    //this is used to record the audio
    useEffect(() => {
        try {
            let intervalId;
            if (isActive) {
                intervalId = setInterval(() => {

                    const secondCounter = counter % 60;
                    const minuteCounter = Math.floor(counter / 60);
                    if (counter == 1) {
                        //startRecording();
                    }
                    let computedSecond =
                        String(secondCounter).length === 1
                            ? `0${secondCounter}`
                            : secondCounter;
                    let computedMinute =
                        String(minuteCounter).length === 1
                            ? `0${minuteCounter}`
                            : minuteCounter;

                    setSecond(computedSecond);
                    setMinute(computedMinute);

                    setCounter((counter) => counter + 1);

                }, 1000);
            }

            return () => clearInterval(intervalId);
        } catch (e) {
            return <ErrorHandler error={e} />
        }
    }, [isActive, counter]);



    // //this function is used to show the errors
    const ErrorHandler = ({ error }) => {
        return (
            <div role="alert">
                <p>An error occurred:</p>
                <pre>{error.message}</pre>
            </div>
        )
    }
    // const { playing, currentTime, duration, volume, currentTrack, tracks } = audioData;

    useEffect(() => {
        try {
            recorder.current = new MicRecorder({ bitRate: 128 })
        } catch (error) {
            return <ErrorHandler error={error} />
        }
    }, [])

    const startRecording = async () => {

        try {
            recorder.current.start().then(() => {
                setIsRecording(true)
            })
        } catch (error) {
            return <ErrorHandler error={error} />
        }

    }

    useEffect(() => {

        let audioUrl = ''
        if (Object.keys(sTagValue || []).length && !isRecording  ) {
            audioRef.current = document.getElementById('audio');
            audioUrl = sTagValue.speech_url;
            audioUrl = sTagValue.speech_url;
            setBlobUrl(audioUrl)
            }
    },[sTagValue])

    useEffect(() => {

        try {
            let blobs = allBlobs
            if (blobs.length && isRecording) {

                const concatenatedBlob = new Blob(blobs, { type: 'audio/mpeg' });
                const newBlobUrl = URL.createObjectURL(concatenatedBlob)
                setBlobUrl(newBlobUrl)
                setIsSubmit(true);
                setIsRecording(false)
            }
        } catch (error) {
            return <ErrorHandler error={error} />
        }
    }, [allBlobs])

    const stopRecording = () => {

        try {
            recorder.current
                .stop()
                .getMp3()
                .then(([buffer, blob]) => {
                    const file = new File(buffer, "audio.mp3", {
                        type: 'audio/mpeg',
                        lastModified: Date.now(),
                    })
                    setAudioFile(file)
                    const blobs = [...allBlobs, blob]
                    setAllBlobs(blobs)

                })
                .catch((e) => console.log(e))
        } catch (error) {
            return <ErrorHandler error={error} />
        }

    }

    const submitSpeechTags = async () => {
        try {

            if (blobURL && blobURL.search('blob:') != -1) {
                const fd = new FormData()              
                fd.append("speech_request_id", sTagValue.speech_request_id)
                sTagValue.document.forEach((item) => fd.append("document[]", item));
                updatePosTags_hook.mutate(fd)
            } else {
                toast.success("No new audio found")
            }

        } catch (error) {
            return <ErrorHandler error={error} />
        }
    }

    useEffect(() => {
        try {
            if (updatePosTags_hook.isSuccess) {
                toast.success("Speech tags updated")
                navigate('/view_speech_taggings')
            }
            else if (updatePosTags_hook.isError) {
                toast.error("Upload Failed")
            }
        } catch (e) {
            return <ErrorHandler error={e} />
        }
    }, [updatePosTags_hook.isError, updatePosTags_hook.isSuccess])


    const uploadSpeechTos3 = async () => {

        if (blobURL && blobURL.search('blob:') != -1) {
            const audioBlob = await fetch(blobURL).then((r) => r.blob());
            const blobAudioFile = new File([audioBlob], 'voice.mp3', { type: 'audio/mpeg' });

            const response = await axios.request({
                headers: {
                  Authorization: `Bearer ${Data.auth["access_token"]}`
                },
                method: "GET",
                url: BACKEND_URL + `/generate_url_by_key?content_type=${'audio/mpeg'}&s3_key=${S3_key}`
              })
              if (response.status ===200) {
                
                let url = response.data.url.url
                var formData = new FormData()
                setS3_key(response.data.url.fields.key)
                console.log(response.data.url.fields.key , "KEY")
                formData.append('key', response.data.url.fields.key)
                formData.append("x-amz-algorithm", response.data.url.fields['x-amz-algorithm'])
                formData.append("x-amz-credential", response.data.url.fields['x-amz-credential'])
                formData.append("x-amz-date", response.data.url.fields['x-amz-date'])
                formData.append("policy", response.data.url.fields.policy)
                formData.append("x-amz-signature", response.data.url.fields['x-amz-signature'])
                formData.append("file", blobAudioFile)
                upload_s3_hook.mutate({url , formData})
    
              }
              else if (response.data.message == "Please login first"){
                    localStorage.clear()
                    dispatch(redux_logout())
                    navigate('/')
                    }
              else{
                toast.error("Unable to Connect to Server, Try again")
              }


            // const fd = new FormData()
            
            // fd.append("document_id", documentId)
            // doc.forEach((item) => fd.append("document[]", item))
            // fd.append("s3_key", response.data.url.fields.key);
            // addSpeechTags.mutate(fd)
        } else {
            toast.success("No new audio found")
        }
    }

    useEffect(() => {
        if (upload_s3_hook.isSuccess) {
            if (upload_s3_hook.data === 'Upload cancelled by user.' || upload_s3_hook.data ==='Error during file upload'  ){
                toast.error(upload_s3_hook.data)
            }
            else{

                toast.success("Uploaded Success")
                setfileUploaded(true)
                submitSpeechTags()
            }
                                   
        }
        else if (upload_s3_hook.isError) {
            
            toast.error("Error in uploading file")
        }

    }, [upload_s3_hook.isError, upload_s3_hook.isSuccess])















    return (

        <>
            <ErrorBoundary FallbackComponent={ErrorHandler}>

                <div className='w-full'>


                    <div className='actions grayBox rounded-xl'>

                        <Slider>
                            {/* Slider Loop (Index 0) */}
                            <div>
                                <div className={`${styles.actWrap} p-3`}>
                                    <div className='block lg:flex gap-4'>
                                        <div className='flex-1 mb-3 lg:mb-0'>
                                            <p className={`${styles.capt} mb-1`}>Speak and record the words above, to ensure its accuracy.</p>
                                            <div className={`${styles.recordsBtns} flex rounded-xl`}>
                                                <div className='flex-1'>
                                                    <button onClick={() => {
                                                        if (!isActive) {
                                                            startRecording();
                                                        } else {
                                                            stopRecording();
                                                        }
                                                        setIsActive(!isActive);
                                                    }} type='button' className={`${styles.recBtn} flex w-full h-full items-center`} title="Record">
                                                    <div className='flex-none w-6'>
                                                        {isActive ? 
                                                        <img src="/svg/mic-blue-icon-play.svg" alt="" /> 
                                                        : 
                                                        <img src="/svg/mic-blue-icon.svg" alt="" />
                                                        }                                                        
                                                    </div>
                                                        <div className='flex-1 text-primary text-left pl-3'>
                                                            <p>{minute}:{second}</p>
                                                            <p>{isActive ? "Pause" : "Start"} Recording</p>
                                                        </div>
                                                    </button>
                                                </div>

                                                <div className='flex-none w-14 flex h-full items-center text-center'>
                                                    <button disabled={isActive ? false : true} type='button' title='Save' className={`${styles.recOthBtn} ${styles.save}`}
                                                        onClick={() => {
                                                            stopRecording();
                                                            setIsActive(!isActive);
                                                        }}
                                                    >
                                                    </button>
                                                    {/* {
                                                        isActive ?
                                                            <button type='button' title='Save' className={`${styles.recOthBtn} ${styles.save}`}
                                                                onClick={() => {
                                                                   // pauseRecording();
                                                                    stopRecording();
                                                                    setIsActive(!isActive);
                                                                    //setIsSubmit(true);
                                                                }}
                                                            >
                                                            </button>
                                                            :
                                                            <button type='button' title='Undo' className={`${styles.recOthBtn} ${styles.undo}`}
                                                                onClick={() => {
                                                                    stopRecording();
                                                                }}
                                                            >
                                                            </button>
                                                    } */}
                                                </div>
                                                <div className='flex-none w-14 flex h-full items-center text-center'>
                                                    <button disabled={audioFile ? false : true} type='button' className={`${styles.recOthBtn} ${styles.delete}`} title="Remove" onClick={() => {
                                                        handleDeleteClick()
                                                    }}></button>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex-1 mb-3 lg:mb-0'>
                                            <p className={`${styles.capt} mb-1`}>Play and listen the recording, to ensure its accuracy.</p>
                                            <div className={`${styles.playBtnsBx} flex rounded-xl px-3 py-1`}>
                                                <div className='flex w-full items-center py-[2px]'>
                                                    <div className='flex-none w-full'>
                                                        <audio ref={audioPlayer} src={blobURL} controls='controls' id='audio' className='w-full' />
                                                        {/* <audio  className={`${styles.playBtn} w-full`} title="Play" src={audio?audio:audioUrl} id='audio' controls={true}  /> */}

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='flex-none w-full lg:w-[300px]'>
                                            <p className='text-primary pl-2'>{Progress}% <small>uploaded</small></p>
                                            <div className='flex'>
                                                <div className='px-1'>
                                                <button disabled={isSubmit ? false : true} className='w-full btnPrimary text-white font-semibold inline-block py-3 px-4 m-2' onClick={uploadSpeechTos3}>Upload File</button>
                                                </div>
                                                {/* <div className='px-1'>
                                                <button disabled={isSubmit ? false : true} className='w-full btnPrimary text-white font-semibold inline-block py-3 px-4 m-2' onClick={submitSpeechTags}>Save Changes</button>
                                                </div> */}
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                        {showConfirm && (
                            <>
                                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                        {/*content*/}
                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                            <div className="relative py-2 px-4 flex-auto">
                                                <p className="my-4 text-black text-lg leading-relaxed">
                                                    Are you sure want to Delete ?
                                                </p>
                                            </div>

                                            <div className='flex justify-center items-center pb-4'>
                                                <div className='px-2'>
                                                    <button type='button' className="px-4 btnPrimary text-white font-semibold py-2 outline-none focus:outline-none" onClick={handleDelete}>Yes</button>
                                                </div>
                                                <div className='px-2'>
                                                    <button className="p-1 ml-auto bg-transparent border-0 text-black float-right leading-none font-semibold outline-none focus:outline-none" onClick={handleCancel}>No</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="fixed inset-0 z-40 popTrnsBg"></div>
                            </>
                        )}
                    </div>
                </div>
            </ErrorBoundary>
        </>
    )
}


export default SpeechTagEdit

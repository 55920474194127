import React, { useState , useEffect} from 'react'
import { HeadTagBox } from './headTagBox'
import LoaderForAll from '../../components/loader-for-all'
import styles from '../../pages/shared/common.module.css'
import { useUpdateCats } from './hooks'
import { ToastContainer, toast } from 'react-toastify';


export function AddNewCatPop({get_cats_hook, setpop , CatsList, setCatsList }) {
    const [load, setload] = useState(false)

    const update_cats_hook = useUpdateCats()
    const [cat, setcat] = useState("")
    const [subcats, setsubcats] = useState([])


    function handleUpdate(cat , subcats) {
        if (Object.keys(CatsList).includes(cat)) {
            toast.error(`Category ${cat} already exists`)
        }
        else{

            let tp = {...CatsList};
            tp[cat] = subcats
            update_cats_hook.mutate(tp)
        }
    }

    

    useEffect(() => {
        if (update_cats_hook.isSuccess) {
            toast.success("Uploaded Success")
            get_cats_hook.refetch()
            setpop(false)
        }
        else if (update_cats_hook.isError) {
            toast.error("Error is Fetching Data")
        }

    }, [update_cats_hook.isError, update_cats_hook.isSuccess])




    return (
        <>
            {load ? <LoaderForAll /> :

                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative my-6 mx-auto w-[480px] max-w-[85%]">
                        <button type='button' className='clsPopBtn' onClick={() => setpop(false)}>&times;</button>
                        {/*content*/}
                        <div className={`${styles.darkModalBG} rounded-lg p-6`}>
                            <div className={`${styles.epfld} mb-4`}>
                                <label>Category</label>
                                <input type='text' className='focus:outline-none' placeholder='Enter here' value={cat} onChange={(e)=> setcat(e.target.value)} />
                            </div>
                            <div className='mb-4'>
                                <label className='block pl-3 mb-1'>Sub-category</label>
                                <HeadTagBox subcats={subcats} setsubcats={setsubcats} />
                            </div>
                            <div className='flex justify-center items-center pt-4'>
                                <div className='px-2'>
                                    <button onClick={()=> handleUpdate(cat , subcats)} type='button' className="px-6 py-2 btnPrimary text-white font-semibold outline-none focus:outline-none">Done</button>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="fixed inset-0 z-40 popTrnsBg"></div>
        </>
    )
}


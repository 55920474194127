import React, { useState, useEffect, useRef } from 'react'
import styles from '../../shared/common.module.css'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined'
import AuthCopy from '../../../auth/shared/auth-copyright'
import Select from '../../services/select'
import GurbaniFilter from '../gurbani/gurbani-filter'
import GurbaniStatusTable from '../gurbani/gurbani-status-table';
import { AreaSelector, IArea } from '@bmunozg/react-image-area'
import { TextTagging } from '../imagetagging/addTextTagging'
import { useAddImageTags, useUpdateImageTags, useGetImageForTagging, useViewImagesTags } from './hooks';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { Navigate, useNavigate, useSearchParams, createSearchParams } from 'react-router-dom'
//import { useSearchParams } from 'react-router-dom'
import KeyBoard from '../../../components/virtual-keyboard'



function ImageTagging() {
    const uploadImageTags = useAddImageTags();
    const updateImageTags = useUpdateImageTags();
    const tagImage = useGetImageForTagging();
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const tabId = searchParams.get('tabId');
    const [disabled, setDisabled] = useState(true);
    const [areas, setAreas] = useState([]);
    const [imageData, setImageData] = useState([]);
    const [deleteTag, setDeleteTag] = useState(null);

    const [isOpened, setIsOpened] = useState(true);
    function toggleKB() {
        setIsOpened(wasOpened => !wasOpened);
    }


    const [tagList, settagList] = useState([])
    const view_image_tag_hook = useViewImagesTags()


    useEffect(() => {

        if (view_image_tag_hook.isSuccess) {
            let ids = view_image_tag_hook.data.data.sort(cmp).map(itm => itm.image_id)
            settagList(ids)


        }
        else if (view_image_tag_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [view_image_tag_hook.isSuccess, view_image_tag_hook.isError, view_image_tag_hook.data])

    useEffect(() => {
        view_image_tag_hook.refetch()
    }, [])



    function cmp(a, b) {
        const [dateParta, timeParta] = a.last_updated_on.split(' ');
        const [daya, montha, yeara] = dateParta.split('/');
        const [houra, minutea, seconda] = timeParta.split(':');
        const [datePartb, timePartb] = b.last_updated_on.split(' ');
        const [dayb, monthb, yearb] = datePartb.split('/');
        const [hourb, minuteb, secondb] = timePartb.split(':');


        const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
        const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
        return dateB - dateA;


    }

    const formik = useFormik({
        initialValues: {
            image_id: "",
            coordinates: "",
            tags: "",
        },
        // validationSchema: UploadCorpusSchema,
        onSubmit: (values) => {
            // if (! file){
            //     return
            // }
            setDisabled(true)
            if (areas.length) {
                const tagsAreaCoords = [];
                const textTags = []
                areas.map((area, index) => {
                    if (area.x && area.y) {
                        tagsAreaCoords.push([area.x, area.y, area.height, area.width])
                        const spanId = index + 1;
                        const div = document.getElementById(spanId);
                        textTags.push(div.innerHTML)
                    }
                });
                const body = {
                    "image_id": imageData.image_id,
                    coordinates: tagsAreaCoords,
                    tags: textTags
                };
                uploadImageTags.mutate(body)
            } else {
                notifyError('No tags found')
            }
        }
    });

    const notifySuccess = (msg) => {
        toast.success(msg, {
            onClose: () => setDisabled(false)
        });

    }
    const notifyError = (msg) => {
        toast.error(msg, {
            onClose: () => setDisabled(false)
        });

    }

    //show success and error messages after update the tags
    useEffect(() => {

        if (uploadImageTags.isSuccess) {
            // navigate({
            //     pathname: '/view_taggings',
            //     search: `?${createSearchParams({ tabId: tabId })}`,
            // })
            tagImage.refetch()
            toast.success('Tags updated successfully')
            setDisabled(false)
            setAreas([])
            // notifySuccess('Tags updated successfully')          
            // setTimeout(() => {
            //     navigate({
            //         pathname: '/view_taggings',
            //         search: `?${createSearchParams({ tabId: tabId })}`,
            //     })
            // }, 5000);

            // setTagToBeDeleted([])
        }
        else if (uploadImageTags.isError) {
            toast.error('Error while updating tags')
            setDisabled(false)
            // notifyError('Error while updating tags')
        }
    }, [uploadImageTags.isSuccess, uploadImageTags.isError])


    useEffect(() => {

        if (tagImage.isSuccess && tagImage.data.data) {
            setImageData(tagImage.data.data);

        } else if (tagImage.isSuccess && !tagImage.data.data) {
            navigate({
                pathname: '/view_taggings'
            })
            toast.success("No image found for tagging");


        }
        else if (tagImage.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [tagImage.isSuccess, tagImage.isError, tagImage.data])

    useEffect(() => {
        let deleteIndex = deleteTag - 1;
        const allTags = [...areas];
        if (deleteIndex !== -1) {
            allTags.splice(deleteIndex, 1);
            setAreas(allTags);
            setDeleteTag(null)
        }
    }, [deleteTag])

    useEffect(() => {
        tagImage.refetch()
        return () => {
            tagImage.remove();
        }
    }, [])

    const onChangeHandler = (areas1) => {
        setAreas(areas1);
        setDisabled(false)
    }

    const customRender = (areaProps) => {
        if (!areaProps.isChanging && areaProps.height && areaProps.width) {

            return (
                <div className={styles.wrapTxt} key={areaProps.areaNumber}>
                    <button type='button' className={styles.wtRemove} onClick={() => {
                        setDeleteTag(areaProps.areaNumber);
                    }}></button>
                    <span className={styles.arNo}>{areaProps.areaNumber}</span>
                </div>
            );
        }
    };

    function handlePrev() {

        navigate(`/view_image_tagging/${tagList[0]}`)
        // get_my_pos_tags_by_request_id_hook.refetchWithId(tagList[ind-1])
    }



    return (
        <>
            <div className={`pageWrap lightWhite withCopy ${isOpened ? 'pbKBImgTg' : ''}`}>
                <div className={`${styles.pagecontainer} ${styles.pxC}`}>
                    <div className='w-full flex justify-between mb-3'>

                    <div>
                        <button type="button" className='backTxtBtn' onClick={() => navigate({
                            pathname: '/view_taggings'
                        })}>Back</button>
                    </div>
                    <div className='flex gap-4 items-center'>

                                <div>
                                    <button onClick={handlePrev} className='btnOutline text-white font-semibold py-2 px-4'>Previous</button>
                                </div>

                            </div>
                    </div>

                    <form>
                        <div className='block lg:flex lg:gap-4'>
                            

                            <div className='w-full lg:w-2/3 mb-4 lg:mb-0'>

                                <div className='grayBox rounded-xl flex h-full items-center'>
                                    <div className={`${styles.areaS}`}>
                                        <AreaSelector
                                            areas={areas}
                                            debug={true}
                                            minHeight={10}
                                            minWidth={10}
                                            wrapperStyle={{
                                                border: '2px dashed blue',
                                                backgroundColor: 'red',
                                            }}
                                            globalAreaStyle={{
                                                border: 'dashed 2px blue',
                                                backgroundColor: 'rgba(0,0,255,0.4)',
                                                opacity: '1'
                                            }}
                                            onChange={onChangeHandler}
                                            customAreaRenderer={customRender}
                                        >
                                            <img src={imageData?.s3_url}></img>
                                        </AreaSelector>
                                    </div>
                                </div>


                            </div>
                            <div className='w-full lg:w-1/3 mb-4 lg:mb-0'>
                                <div className={`${styles.textAreaPne} grayBox rounded-xl h-full`}>
                                    <TextTagging tags={areas}></TextTagging>
                                    {tagImage.isSuccess && tagImage.data.data &&
                                        <div className='flex justify-between px-2 pt-1 text-right'>
                                            <div>
                                                <button className={styles.kbtoggleBtn} onClick={toggleKB} title='Toggle Keyboard'><KeyboardAltOutlinedIcon /></button>
                                            </div>
                                            <div>
                                                <button disabled={disabled} onClick={formik.handleSubmit} className='btnPrimary text-white font-semibold py-3 px-6'>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
                <div className={`${styles.keyBoardFloat} ${isOpened ? 'block' : 'hidden'}`}>
                    <button type='button' className='p-0 btnCloseKB' title='Close' onClick={toggleKB}><HighlightOffIcon /></button>
                    <KeyBoard />
                </div>

                <div className='copyFloat'><AuthCopy /></div>
            </div>
        </>
    )
}

export default ImageTagging
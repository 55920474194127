import React from 'react'
import { Link } from 'react-router-dom'
import classes from '../dash.module.css'

function ProfileDtls(userProfileDetails) {
    const userProfile = userProfileDetails?.userProfileDetails;
    return (
        <>
            <div className={`${classes.topProfBox} py-6 text-center`}>
                {!userProfile?.profile_picture_url?.length >= 1 &&
                    <div className={`${classes.profLetter} flex flex-col mx-auto mb-4`}>
                        <span className='flex flex-col items-center'>{userProfile?.name && userProfile?.name[0] || ''}</span>
                    </div>

                }
                {userProfile?.profile_picture_url?.length >= 1 &&
                    // <div className={`${classes.profIco} mx-auto mb-4`}></div>
                    <div className={`${classes.profIco} mx-auto relative mb-4`} style={{ backgroundImage: `url(${(userProfile?.profile_picture_url)})` }}>
                                
                            </div>
                }
                <h1 className='text-4xl leading-6 font-semibold mb-2'>{userProfile?.name || ''}</h1>
                <p className={classes.gray}>{userProfile?.email || ''}</p>
                <p className={`${classes.txtPrime} text-xs`}><strong>&bull; {userProfile?.total_contribution || 0} Contributions</strong></p>
                <div className={`${classes.dataGrid} flex my-5 mx-auto text-xs`}>
                    <div className='flex-1'>
                        <strong className='font-semibold block'>{userProfile?.age || ''}</strong> Age
                    </div>
                    <div className='flex-1'>
                        <strong className='font-semibold block'>{userProfile?.gender || ''}</strong> Gender
                    </div>
                    <div className='flex-1'>
                        <strong className='font-semibold block'>{userProfile?.role || ''}</strong> Role
                    </div>
                </div>
                {userProfile?.role !='admin' && 
                <Link to='/editPublicProfile' state={userProfile} className='btnPrimary px-6 py-3'>Edit Profile</Link>
                }
                {userProfile?.role =='admin' && 
                <Link to='/edit-profile' state={userProfile} className='btnPrimary px-6 py-3'>Edit Profile</Link>
                }
            </div>
        </>
    )
}

export default ProfileDtls
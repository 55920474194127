import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useSearchParams, createSearchParams, useLocation } from 'react-router-dom'
import { DeleteTaggingImagePop } from './deleteTagImagepop';
import { toast } from 'react-toastify';
import { ListObject } from './listObject';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useViewImagesTags } from './hooks';
import '../../shared/page.css'


function TaggingListing({}) {
    const navigate = useNavigate();
    const [pop, setpop] = useState(false)
    const [delete_s3, setdelete_s3] = useState(null)
    const [imagesTagList, setimagesTagList] = useState([])
    const location = useLocation()
    const [Selected, setSelected] = useState(parseInt(location.state?.selected_id))
    const view_image_tag_hook = useViewImagesTags()


    useEffect(() => {

        if (view_image_tag_hook.isSuccess) {
            setimagesTagList(view_image_tag_hook.data.data)


        }
        else if (view_image_tag_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [view_image_tag_hook.isSuccess, view_image_tag_hook.isError, view_image_tag_hook.data])

    useEffect(() => {
        view_image_tag_hook.refetch()
    }, [])
    


    function cmp(a, b) {
        const [dateParta, timeParta] = a.last_updated_on.split(' ');
        const [daya, montha, yeara] = dateParta.split('/');
        const [houra, minutea, seconda] = timeParta.split(':');
        const [datePartb, timePartb] = b.last_updated_on.split(' ');
        const [dayb, monthb, yearb] = datePartb.split('/');
        const [hourb, minuteb, secondb] = timePartb.split(':');


        const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
        const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
        return dateB - dateA;


    }


    return (
        <>
            <div>
                <button type="button" className='backTxtBtn' onClick={() => navigate('/contribution')}>Back</button>
            </div>
            <div className='grayBox rounded-xl overflow-hidden'>
                <div className='mb-4 flex justify-between px-4 pt-4'>
                    <div>
                        <h2 className='text-primary mb-0 text-lg'>Image Tags</h2>
                    </div>
                    <div>
                        <button onClick={() => navigate('/add_image_tagging')} type='button' className='btnPrimary text-white font-semibold py-2 px-4'>
                            Add Tag
                        </button>
                    </div>
                </div>

                <div>
                    <div className='pb-3'>
                        <div className='tableListWrap pb-2'>
                            <div className='contTable text-xs min-w-[1000px] px-3'>
                                <div className='tableHead gray flex items-center py-2'>
                                    <div className='flex-none px-3'>&nbsp;</div>
                                    <div className='flex-1 px-3'>File Title</div>
                                    <div className='flex-1 px-3'>Upload Date and Time</div>
                                    <div className='flex-1 px-3'>Category</div>
                                    <div className='flex-1 px-3'>Sub Category</div>
                                    <div className='flex-1 px-3'>Status</div>
                                    <div className='flex-none w140 px-3'>&nbsp;</div>
                                </div>
                                <div className='tableBody'>
                                    {/* Table Row Loop */}
                                    {imagesTagList.sort(cmp).map((itm) => {
                                        if (Selected === itm.image_id) { 
                                            return (
                                            <div className='dBorder' key={itm.s3_uri}>
                                                <ListObject key={itm.s3_uri} isTag={true} itm={itm} setpop={setpop} />
                                            </div>

                                            )
                                        }
                                        return (
                                            <div key={itm.s3_uri}>
                                                <ListObject key={itm.s3_uri} isTag={true} itm={itm} setpop={setpop} />
                                            </div>
                                        )

                                    })}



                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default TaggingListing
import React from 'react'

function AuthCopy() {
    let today = new Date();
    let yearStamp = today.getFullYear();

    return (
        <>
            <div className='text-center md:text-right text-xs'>Copyright &copy; {yearStamp} Panjabi.AI. All rights reserved.</div>
        </>
    )
}

export default AuthCopy
import { useQuery , useMutation } from "react-query";
import axios from "axios";
import { useSelector } from "react-redux";

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {redux_logout} from '../../auth/authSlice'
import { BACKEND_URL } from "../../config";

export const useGetAllNERTags = ()=> {
    let navigate = useNavigate()
    let dispatch = useDispatch()

    const Data = useSelector(state => state)
    return useQuery(
        {queryKey: ['metaNerTags'],
          queryFn: async () => {
            
            // return await axios.get(BACKEND_URL + `/view_corpuses`,{
            //    headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            // }
            // ).then(res => res.json
            //  ).then(dataJson => setcorpaList(dataJson.data) )
            const response = await axios.request({
                headers: {
                  Authorization: `Bearer ${Data.auth["access_token"]}`
                },
                method: "GET",
                url: BACKEND_URL + `/read_ner_tags`
              })
            return response.data ? response.data : []
          },
          onSuccess: (data) => {

          },
          onError: (error) => {
            if (error.response.data.message == "Please login first"){
              // logoutFn(Data.auth["access_token"])
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            }
          },
          enabled: false,
        }
      )
}

export const useGetAllPOSTags = ()=> {
    let navigate = useNavigate()
    let dispatch = useDispatch()

    const Data = useSelector(state => state)
    return useQuery(
        {queryKey: ['metaPosTags'],
          queryFn: async () => {
            
            // return await axios.get(BACKEND_URL + `/view_corpuses`,{
            //    headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            // }
            // ).then(res => res.json
            //  ).then(dataJson => setcorpaList(dataJson.data) )
            const response = await axios.request({
                headers: {
                  Authorization: `Bearer ${Data.auth["access_token"]}`
                },
                method: "GET",
                url: BACKEND_URL + `/read_pos_tags`
              })
            return response.data ? response.data : []
          },
          onSuccess: (data) => {

          },
          onError: (error) => {
            if (error.response.data.message == "Please login first"){
              // logoutFn(Data.auth["access_token"])
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            }
          },
          enabled: false,
        }
      )
}

export const useGetAllCats = ()=> {
    let navigate = useNavigate()
    let dispatch = useDispatch()

    const Data = useSelector(state => state)
    return useQuery(
        {queryKey: ['metaCats'],
          queryFn: async () => {
            
            // return await axios.get(BACKEND_URL + `/view_corpuses`,{
            //    headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            // }
            // ).then(res => res.json
            //  ).then(dataJson => setcorpaList(dataJson.data) )
            const response = await axios.request({
                headers: {
                  Authorization: `Bearer ${Data.auth["access_token"]}`
                },
                method: "GET",
                url: BACKEND_URL + `/read_catagory`
              })
              console.log(response,"RRESS")
            return response.data ? response.data : []
          },
          onSuccess: (data) => {

          },
          onError: (error) => {
            if (error.response.data.message == "Please login first"){
              // logoutFn(Data.auth["access_token"])
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            }
          },
          enabled: false,
        }
      )
}


export const useUpdateCats = () => {
  const Data = useSelector(state => state)
  let navigate = useNavigate()
    let dispatch = useDispatch()



  return useMutation(
      {
        mutationFn: async (data) => {
          
          return await axios.post(BACKEND_URL + `/update_category`, data , {
             headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
          }
          )
        },
        onSuccess: (data) => {
            
        },
        onError: (error) => {
          if (error.response.data.message == "Please login first"){
            // logoutFn(Data.auth["access_token"])
          localStorage.clear()
          dispatch(redux_logout())
          navigate('/')
          }
        },
      }
    )
}


export const useUpdateNerTags = () => {
  const Data = useSelector(state => state)
  let navigate = useNavigate()
    let dispatch = useDispatch()



  return useMutation(
      {
        mutationFn: async (data) => {
          
          return await axios.post(BACKEND_URL + `/update_meta_ner_tags`, data , {
             headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
          }
          )
        },
        onSuccess: (data) => {
            
        },
        onError: (error) => {
          if (error.response.data.message == "Please login first"){
            // logoutFn(Data.auth["access_token"])
          localStorage.clear()
          dispatch(redux_logout())
          navigate('/')
          }
        },
      }
    )
}

export const useUpdatePosTags = () => {
  const Data = useSelector(state => state)
  let navigate = useNavigate()
    let dispatch = useDispatch()



  return useMutation(
      {
        mutationFn: async (data) => {
          
          return await axios.post(BACKEND_URL + `/update_meta_pos_tags`, data , {
             headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
          }
          )
        },
        onSuccess: (data) => {
            
        },
        onError: (error) => {
          if (error.response.data.message == "Please login first"){
            // logoutFn(Data.auth["access_token"])
          localStorage.clear()
          dispatch(redux_logout())
          navigate('/')
          }
        },
      }
    )
}
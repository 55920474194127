import React from 'react'
import Header from '../components/header'
import AuthCopy from '../auth/shared/auth-copyright'

export const Fonts = () => {
  return (
    <div className='pageWrap lightWhite withCopy'>
        
        <div>Fonts</div>


        

        <div className='copyFloat'><AuthCopy /></div>          
    </div> 
  )
}

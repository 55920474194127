//import React from 'react';
import React, { useEffect, useState, useRef } from "react";
import styles from '../../shared/common.module.css';
import { useParams, useNavigate } from 'react-router-dom'
import RecognitionFilter from './recognition-filter'
import StatusTable from './status-table'
import SpeechTextPane from './speech-textpane'
import SpeechsActions from './speech-actions'
import AuthCopy from '../../../auth/shared/auth-copyright'
import Select from '../../services/select'
import { useGetAllSpeechTagging, useGetDocumentForSpeechTag } from './hooks';
import { ToastContainer, toast } from 'react-toastify';
function SpeechRecognition() {

  const { id } = useParams()
  const get_document_tags_hook = useGetDocumentForSpeechTag();
  const [tagDocument, setTagDocument] = useState('')
  const navigate = useNavigate();
  useEffect(() => {

    try {

      if (get_document_tags_hook.isSuccess) {
        if (get_document_tags_hook.data && get_document_tags_hook.data.data && get_document_tags_hook.data.data.length === 0) {
          setTagDocument('')
        }
        else {
          setTagDocument(get_document_tags_hook.data.data)
        }


      }
      else if (get_document_tags_hook.isError) {
        toast.error("Error is Fetching Data")
      }
    } catch (error) {
      toast.error(error)
    }
  }, [get_document_tags_hook.isSuccess, get_document_tags_hook.isError, get_document_tags_hook.data])

  useEffect(() => {

    try {
      get_document_tags_hook.refetch()

      return () => {
        get_document_tags_hook.remove()
      }
    } catch (error) {
      toast.error(error)
    }
  }, [])


  const [tagList, settagList] = useState([])
  const get_my_tags_hook = useGetAllSpeechTagging()


  useEffect(() => {
    try {
      if (get_my_tags_hook.isSuccess) {
        if (get_my_tags_hook.data.data.length === 0) {
          settagList([])
        }
        else {
          let ids = get_my_tags_hook.data.data.sort(cmp).map(itm => itm.speech_request_id)
          settagList(ids)
        }


      }
      else if (get_my_tags_hook.isError) {
        toast.error("Error is Fetching Data")
      }
    } catch (error) {
      toast.error(error)
    }
  }, [get_my_tags_hook.isSuccess, get_my_tags_hook.isError])


  useEffect(() => {
    try {
      get_my_tags_hook.refetch()

      return () => {
        get_my_tags_hook.remove()
      }
    } catch (error) {
      toast.error(error)
    }
  }, [])

  function cmp(a, b) {
    const [dateParta, timeParta] = a.last_updated_on.split(' ');
    const [daya, montha, yeara] = dateParta.split('/');
    const [houra, minutea, seconda] = timeParta.split(':');
    const [datePartb, timePartb] = b.last_updated_on.split(' ');
    const [dayb, monthb, yearb] = datePartb.split('/');
    const [hourb, minuteb, secondb] = timePartb.split(':');


    const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
    const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
    return dateB - dateA;


  }


  function handlePrev() {
    navigate(`/edit_speech_tag/${tagList[0]}`)
    // get_my_pos_tags_by_request_id_hook.refetchWithId(tagList[ind-1])
  }


  return (
    <>
      <div className='pageWrap lightWhite withCopy'>
        <div className={`${styles.pagecontainer} ${styles.pxC}`}>

          <div className='flex justify-between gap-4 mb-3 items-center'>
            <div className='flex gap-4 items-center'>
              <div>
                <button type="button" className='backTxtBtn' onClick={() => navigate('/view_speech_taggings')}>Back</button>
              </div>
              <div>
                {/* <button type="button" className='px-4 btnPrimary text-white font-semibold py-2' onClick={() => navigate('/speech-recognition')}>Add New</button> */}
              </div>
            </div>
            <div className='flex gap-4 items-center'>
              <div>
                &nbsp;
              </div>
              <div>
                <button onClick={handlePrev} className='btnOutline text-white font-semibold py-2 px-4'>Previous</button>
              </div>
            </div>
          </div>



          <div className='block lg:flex lg:gap-4'>

            <div className='w-full'>

              <div className='grayBox rounded-xl mb-4'>
                {tagDocument &&
                  <SpeechTextPane tagDocument={tagDocument} />
                }
              </div>
              {tagDocument &&
                <SpeechsActions tagDocument={tagDocument} get_document_tags_hook={get_document_tags_hook}/>
              }

              {!tagDocument &&
                <div>No more data available</div>
              }
            </div>
          </div>
        </div>

        <div className='copyFloat'><AuthCopy /></div>
      </div>
    </>
  )
}

export default SpeechRecognition
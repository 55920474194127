import React from 'react'
import styles from '../../shared/common.module.css'
// Circular Progress
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


function GurbaniStatusTable() {

    const percentage = 66;

    return (
        <>
            <div className='pt-4'>
                <div className={`${styles.statTbl} text-xs`}>
                    {/* Table Header */}
                    <div className='flex text-center px-2'>
                        <div className='w-1/3 p-2'>Progress</div>
                        <div className='w-1/3 p-2'>Total Progress</div>
                        <div className='w-1/3 p-2'>Total Lines</div>
                    </div>

                    {/* If category and file are not selected then print this below box and hide others */}

                    {/* <div className="px-2 pt-2">
                        <div className={`${styles.nonSelectedStatusBox} rounded-xl flex items-center justify-center`}>
                            <div className="text-center">
                                <p className="mb-4">Select a category and file</p>
                                <div>
                                    <img className="inline-block" src="/svg/non-selected-box-status-ico.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* Table Body */}
                    <div className={styles.tBodyArea}>
                        <div className={`${styles.statTBRow} flex text-center rounded-xl`}>
                            <div className='w-1/3 flex items-center justify-center'>
                                <div className='inline-block' style={{ width: 60, height: 60 }}>
                                    <CircularProgressbar value={percentage} text={`${percentage}%`} />
                                </div>
                            </div>
                            <div className='w-1/3 flex items-center justify-center'>
                                <span className='badge'>3</span>
                            </div>
                            <div className='w-1/3 flex items-center justify-center'>13</div>
                        </div>
                        <div className={`${styles.statTBRow} flex text-center rounded-xl`}>
                            <div className='w-1/3 flex items-center justify-center'>
                                <div className='inline-block' style={{ width: 60, height: 60 }}>
                                    <CircularProgressbar value={percentage} text={`${percentage}%`} />
                                </div>
                            </div>
                            <div className='w-1/3 flex items-center justify-center'>
                                <span className='badge'>3</span>
                            </div>
                            <div className='w-1/3 flex items-center justify-center'>13</div>
                        </div>
                        <div className={`${styles.statTBRow} flex text-center rounded-xl`}>
                            <div className='w-1/3 flex items-center justify-center'>
                                <div className='inline-block' style={{ width: 60, height: 60 }}>
                                    <CircularProgressbar value={percentage} text={`${percentage}%`} />
                                </div>
                            </div>
                            <div className='w-1/3 flex items-center justify-center'>
                                <span className='badge'>3</span>
                            </div>
                            <div className='w-1/3 flex items-center justify-center'>13</div>
                        </div>
                        <div className={`${styles.statTBRow} flex text-center rounded-xl`}>
                            <div className='w-1/3 flex items-center justify-center'>
                                <div className='inline-block' style={{ width: 60, height: 60 }}>
                                    <CircularProgressbar value={percentage} text={`${percentage}%`} />
                                </div>
                            </div>
                            <div className='w-1/3 flex items-center justify-center'>
                                <span className='badge'>3</span>
                            </div>
                            <div className='w-1/3 flex items-center justify-center'>13</div>
                        </div>
                        <div className={`${styles.statTBRow} flex text-center rounded-xl`}>
                            <div className='w-1/3 flex items-center justify-center'>
                                <div className='inline-block' style={{ width: 60, height: 60 }}>
                                    <CircularProgressbar value={percentage} text={`${percentage}%`} />
                                </div>
                            </div>
                            <div className='w-1/3 flex items-center justify-center'>
                                <span className='badge'>3</span>
                            </div>
                            <div className='w-1/3 flex items-center justify-center'>13</div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default GurbaniStatusTable
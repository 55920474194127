import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import Slider from "react-slick"
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
}


const getOptions = (type, data, total, title) => ({


    chart: {
        type: 'pie',
        width: 460,
        height: 210,
    },

    plotOptions: {
        pie: {
            innerSize: '70%'
        }
    },

    colors: ['#44A0DF', '#CA3559'],

    title: '',
    series: [
        {
            borderWidth: 0,
            xAxis: 0,
            data: data,
            innerSize: '80%',
            showInLegend: true,
            dataLabels: {
                format: '<b>{point.percentage} %',
                enabled: true,
                crop: false,
                distance: '5%',
                style: {
                    fontWeight: '500px',
                    fontSize: '16px',
                },
                //format: '{point.percentage}',
                connectorWidth: 0
            },
            style: {
                fontSize: '8px',
                fontWeight: 300,
            }
        },
    ],

    legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'top',
        x: 0,
        y: 10,
    },

    credits: {
        enabled: false
    },
});


function ErrorsPercentage({ adminDashboardDetails }) {

    const pieChartData = {

    }
    const graphNerData = [['Correct words',75],['Incorrect words',25]];
    const graphImageData = [];
    const graphSpeechData = [];
    const graphPosData = [];

    if (adminDashboardDetails?.ner_tags && adminDashboardDetails?.ner_tags?.total) {
        graphNerData.push(['NER Reject', adminDashboardDetails?.ner_tags?.reject])
        graphNerData.push(['NER Pending', adminDashboardDetails?.ner_tags?.pending])
        graphNerData.push(['NER Accept', adminDashboardDetails?.ner_tags?.accept])
    }
    if (adminDashboardDetails?.image_tags && adminDashboardDetails?.image_tags?.total) {
        graphImageData.push(['Image Reject', adminDashboardDetails?.image_tags?.reject])
        graphImageData.push(['Image Pending', adminDashboardDetails?.image_tags?.pending])
        graphImageData.push(['Image Accept', adminDashboardDetails?.image_tags?.accept])
    }
    if (adminDashboardDetails?.speech_tags && adminDashboardDetails?.speech_tags?.total) {
        graphSpeechData.push(['Speech Reject', adminDashboardDetails?.speech_tags?.reject])
        graphSpeechData.push(['Speech Pending', adminDashboardDetails?.speech_tags?.pending])
        graphSpeechData.push(['Speech Accept', adminDashboardDetails?.speech_tags?.accept])
    }
    if (adminDashboardDetails?.pos_tags && adminDashboardDetails?.pos_tags?.total) {
        graphPosData.push(['POS Reject', adminDashboardDetails?.pos_tags?.reject])
        graphPosData.push(['POS Pending', adminDashboardDetails?.pos_tags?.pending])
        graphPosData.push(['POS Accept', adminDashboardDetails?.pos_tags?.accept])
    }


    return (
        <>
            <div className='errorPie mx-auto p-4 lg:px-4 lg:py-0'>


                <div className='px-6'>
                    <Slider {...settings}>
                        {adminDashboardDetails?.pos_tags?.total &&
                            <div>
                                <h6 className='m-0'>POS detail graph</h6>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={getOptions('pie', graphPosData, adminDashboardDetails?.pos_tags?.total, 'POS')}
                                />
                            </div>
                        }

                        {adminDashboardDetails?.speech_tags?.total &&
                            <div>
                                <h6 className='m-0'>Speech detail graph</h6>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={getOptions('pie', graphSpeechData, adminDashboardDetails?.speech_tags?.total, 'Speech')}
                                />
                            </div>
                        }

                        {adminDashboardDetails?.image_tags?.total &&
                            <div>
                                <h6 className='m-0'>Image detail graph</h6>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={getOptions('pie', graphImageData, adminDashboardDetails?.image_tags?.total, 'Image')}
                                />
                            </div>
                        }

                            <div>
                                <h6 className='m-0'>Errors Percentage</h6>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={getOptions('pie', graphNerData, adminDashboardDetails?.ner_tags?.total, 'NER')}
                                />
                            </div>
                    </Slider>
                </div>


            </div>

        </>
    )
}

export default ErrorsPercentage
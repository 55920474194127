import React, { useEffect, useState } from 'react'
import styles from '../../shared/common.module.css'
import { useNavigate } from 'react-router-dom'
import AuthCopy from '../../../auth/shared/auth-copyright'
import { useGetAllPOSTags, useGetMyTags, useGetNewDocument, useUploadTags, uuseUploadTags } from './hooks';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TokenAnnotator } from "react-text-annotate";
import LoaderForAll from '../../../components/loader-for-all'
import { POS_TAGS } from '../../../config';
import { PosGuidelines } from './posGuidelines';
import HelpIcon from '@mui/icons-material/Help';

export const PosCreateTag = () => {

    const navigate = useNavigate();
    const getNewDoc_hook = useGetNewDocument()
    const [Doc, setDoc] = useState(null)
    const [postag, setpostag] = useState(POS_TAGS[0])
    const [PosValue, setPosValue] = useState([]);
    const uploadPosTags_hook = useUploadTags()
    const [isSubmit, setIsSubmit] = useState(false);

    const get_my_tags_hook = useGetMyTags()
    const [tagList, settagList] = useState([])

    const get_tags = useGetAllPOSTags()
    const [tags, settags] = useState([])



    useEffect(() => {
        if (get_tags.isSuccess) {
            if (get_tags.data.data.length === 0) {
                settags([])
            }
            else {
                settags(get_tags?.data?.data.sort())
            }


        }
        else if (get_tags.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [get_tags.isSuccess, get_tags.isError, get_tags.data])

    useEffect(() => {
        get_tags.refetch()
        return () => {
            get_tags.remove()
        }
    }, [])


    useEffect(() => {
        if (get_my_tags_hook.isSuccess) {
          if (get_my_tags_hook.data.data.length === 0) {
            settagList([])
          }

          else {
            let ids = get_my_tags_hook.data.data.sort(cmp).map(itm => itm.pos_request_id)
            settagList(ids)
          }
        }
        else if (get_my_tags_hook.isError) {
          toast.error("Error is Fetching Data")
        }
      }, [get_my_tags_hook.isSuccess, get_my_tags_hook.isError, get_my_tags_hook.data])
    
    
      useEffect(() => {
        get_my_tags_hook.refetch()
        return () => {
          get_my_tags_hook.remove()
        }
      }, [])

      function cmp(a,b) {
        const [dateParta, timeParta] = a.last_updated_on.split(' ');
        const [daya, montha, yeara] = dateParta.split('/');
        const [houra, minutea, seconda] = timeParta.split(':');
        const [datePartb, timePartb] = b.last_updated_on.split(' ');
        const [dayb, monthb, yearb] = datePartb.split('/');
        const [hourb, minuteb, secondb] = timePartb.split(':');
    
    
        const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
        const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
        return dateB - dateA;
          
        
    }



    const handlePosAnotationChange = (newValue) => {
        if (!isSubmit)
            setPosValue(newValue);
    };

    useEffect(() => {
        if (getNewDoc_hook.isSuccess) {
            if (getNewDoc_hook.data.message) {
                toast.success("No more data available")
            }
            else {
                setDoc(getNewDoc_hook.data.data)
            }


        }
        else if (getNewDoc_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [getNewDoc_hook.isSuccess, getNewDoc_hook.isError, getNewDoc_hook.data])

    useEffect(() => {
        getNewDoc_hook.refetch()
    }, [])


    function handleSubmit() {
        if (PosValue.length) {
            let body = {
                document_id: Doc.document_id,
                document: Doc.document,
                pos_tags: JSON.stringify(PosValue),
                pos_status: 'pending',
            }
            uploadPosTags_hook.mutate(body)
            setIsSubmit(true)

        } else {
            toast.error('Please add some tags')
        }

    }

    useEffect(() => {
        if (uploadPosTags_hook.isSuccess) {
            // navigate('/posContribution');
            toast.success("Uploaded Success")
            getNewDoc_hook.refetch()
            setPosValue([])
            get_my_tags_hook.refetch()
            // notifySuccess("Uploaded Success");
            // setPosValue([]);
            setIsSubmit(false);
        }
        else if (uploadPosTags_hook.isError) {
            toast.error("Error is uploading tags")
            //    notifyError("Error is uploading tags")
        }

    }, [uploadPosTags_hook.isError, uploadPosTags_hook.isSuccess])

    const notifySuccess = (msg) => {
        toast.success(msg, {
            onClose: () => {
                setIsSubmit(false);
                navigate('/posContribution');
            }
        });

    }
    const notifyError = (msg) => {
        toast.error(msg, {
            onClose: ({ uid }) => setIsSubmit(false)
        });

    }

    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    function handleNext() {
        navigate(`/posEditTag/${tagList[0]}`)
    }


    return (
        <>
            <div className='pageWrap lightWhite'>

                <div className='block lg:flex gap-0'>
                    <div className='grow lg:px-5 relative hScreen'>
                        {uploadPosTags_hook.isLoading ? <LoaderForAll /> :

                            <div className={`${styles.pagecontainer} ${styles.pxC}`}>
                                <button type="button" className='backTxtBtn' onClick={() => navigate('/posContribution', { state: { selected_id: tagList[0] } })}>Back</button>
                                {getNewDoc_hook.isLoading ? <LoaderForAll /> :
                                    <>

                                        <>

                                            <div className='grayBox rounded-xl p-4 mb-4'>
                                                <div className='flex items-center mb-3'>
                                                    <h2 className='text-primary m-0 text-lg'>Part of Speach Recognition Tags</h2>
                                                    <div className='pl-4'>
                                                        <select className='selectTag xPad' value={postag} onChange={(e) => setpostag(e.target.value)} name="tags" id="tags">
                                                            {tags.map(itm => {
                                                                return (
                                                                    <option key={itm} value={itm}>{itm}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className='pl-3'>
                                                        <button type="button" title='Help' onClick={handleClick}><HelpIcon /></button>
                                                    </div>
                                                </div>
                                                {Doc != null ?

                                                    <TokenAnnotator
                                                        tokens={Doc.document}
                                                        value={PosValue}

                                                        onChange={handlePosAnotationChange}
                                                        getSpan={(span) => ({
                                                            ...span,
                                                            tag: postag,

                                                        })}
                                                    />

                                                    : null}

                                            </div>
                                            
                                        </>


                                        {/* <>

                                <div className='grayBox rounded-xl p-4'>
                                    <div className='flex items-center mb-3'>
                                        <h2 className='text-primary m-0 text-lg'>Speech Recognition Tags</h2>
                                        <div className='pl-4'>
                                            <select className='selectTag xPad' value={postag} onChange={(e) => setpostag(e.target.value)} name="tags" id="tags">
                                                {POS_TAGS.map(itm => {
                                                    return (
                                                        <option key={itm} value={itm}>{itm}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {Doc != null ?

                                        <TokenAnnotator
                                            tokens={Doc.document}
                                            value={PosValue}

                                            onChange={handlePosAnotationChange}
                                            getSpan={(span) => ({
                                                ...span,
                                                tag: postag,

                                            })}
                                        />

                                        : null}

                                </div>
                            </> */}


                                        <div className='flex gap-4 justify-between'>
                                            <div>
                                                &nbsp;
                                            </div>
                                            <div className='flex gap-4'>
                                                <div><button onClick={handleNext} className='btnOutline text-white font-semibold px-4 py-2'>Previous</button></div>
                                                <div>
                                                <button disabled={PosValue.length ==0 || uploadPosTags_hook.isLoading} onClick={handleSubmit} className='px-4 btnPrimary text-white font-semibold py-2 mb-3'>Submit</button>
                                                </div>
                                            </div>
                                        </div>


                                    </>


                                }

                            </div>

                        }
                        <div className='hidden lg:block copyFloat'><AuthCopy /></div>
                    </div>

                    {open ? (
                        <div className='flex-none lg:w-[380px]'>
                            <PosGuidelines setOpen={setOpen} />
                        </div>
                    ) : null}

                </div>


            </div>
        </>
    )
}

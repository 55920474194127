import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import classes from './cate.module.css'
import CateList from './cate-list'
import TagList from './tag-list'
import POSTaglist from './postag-list'

function CategoryFrontpage() {
    const navigate = useNavigate();

    return (
        <>
            <div className={`${classes.darkBG} hScreen`}>
                <div className='pt-4 px-6 lg:px-9'>
                    <button type="button" className='backTxtBtn' onClick={() => navigate('/home-admin')}>Back</button>
                </div>
                <div className="TabbedSection">
                    <Tabs>
                        <TabList>
                            <Tab>Categories</Tab>
                            <Tab>NER Tags</Tab>
                            <Tab>POS Tags</Tab>
                        </TabList>
                        <TabPanel>
                            <CateList />
                        </TabPanel>
                        <TabPanel>
                            <TagList />
                        </TabPanel>
                        <TabPanel>
                           <POSTaglist/>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </>
    )
}

export default CategoryFrontpage
import { useQuery , useMutation } from "react-query";
import axios from "axios";
import { useSelector } from "react-redux";
import { BACKEND_URL } from "../../../config";

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { redux_logout } from "../../../auth/authSlice";
import { redux_updateProfile } from "../../../auth/authSlice";


export const useGetUserProfileDetails = ()=> {

  const Data = useSelector(state => state.auth)
  let navigate = useNavigate()
  let dispatch = useDispatch()
  return useQuery(
      {
        queryKey: ['getUserProfileDetails'],
        queryFn: async () => {
          const response = await axios.request({
              headers: {
                Authorization: `Bearer ${Data["access_token"]}`
              },
              method: "GET",
              url: BACKEND_URL + `/public_profile`
            })
          return response.data ? response.data : []
        },
        onSuccess: (data) => {
            dispatch(redux_updateProfile({'name':data.name,'profile_picture_url':data.profile_picture_url}))
        },
        onError: (error) => {
          if (error.response.data.message == "Please login first"){
           
          localStorage.clear()
          dispatch(redux_logout())
          navigate('/')
          }
        },
      }
    )
}

export const useEditUserProfile = () => {
  const Data = useSelector(state => state);
  let navigate = useNavigate()
  let dispatch = useDispatch()
  return useMutation(
      {
        mutationFn: async (data) => {
          
          return await axios.post(BACKEND_URL + `/update_profile`, data , {
             headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
          }
          )
        },
        onSuccess: (data) => {
            
        },
        onError: (error) => {
          if (error.response.data.message == "Please login first"){
            // logoutFn(Data.auth["access_token"])
          localStorage.clear()
          dispatch(redux_logout())
          navigate('/')
          }
        },
      }
    )
}


export const useViewLeaderBoardDetails = ()=> {

  const Data = useSelector(state => state.auth)
  let navigate = useNavigate()
  let dispatch = useDispatch()
  return useQuery(
      {
        queryKey: ['getLeaderBoardDetails'],
        queryFn: async () => {
          const response = await axios.request({
              headers: {
                Authorization: `Bearer ${Data["access_token"]}`
              },
              method: "GET",
              url: BACKEND_URL + `/admin_leaderboard`
            })
          return response.data ? response.data : []
        },
        onSuccess: (data) => {

        },
        onError: (error) => {
          if (error.response.data.message == "Please login first"){
           
          localStorage.clear()
          dispatch(redux_logout())
          navigate('/')
          }
        },
      }
    )
}



export const useViewUserDashboardDetails = ()=> {

  const Data = useSelector(state => state.auth)
  let navigate = useNavigate()
  let dispatch = useDispatch()
  return useQuery(
      {
        queryKey: ['getUserDashboardDetails'],
        queryFn: async () => {
          const response = await axios.request({
              headers: {
                Authorization: `Bearer ${Data["access_token"]}`
              },
              method: "GET",
              url: BACKEND_URL + `/public_dashboard`
            })
          return response.data ? response.data : []
        },
        onSuccess: (data) => {

        },
        onError: (error) => {
          if (error.response.data.message == "Please login first"){
           
          localStorage.clear()
          dispatch(redux_logout())
          navigate('/')
          }
        },
      }
    )
}



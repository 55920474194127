import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import classes from '../dash.module.css'

const getOptions = (type,dashboardDetails) => ({
    chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    colors: ['#008000', '#4499DC', '#B6BA04'],
    xAxis: {
        categories: [
            'NER',
            'POS',
            'SPEECH',
            'IMAGES',
        ],
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Total no. of Tags'
        },
        allowDecimals: false, // Ensure Y-axis displays only whole numbers

    },
    credits: {
        enabled: false
   },  
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [{
        name: 'Assigned',
        data: [dashboardDetails?.ner_tags_assigned || 0, dashboardDetails?.pos_tags_assigned || 0,dashboardDetails?.speech_tags_assigned || 0, dashboardDetails?.image_tags_assigned || 0]

    }, {
        name: 'Reviewed',
        data: [dashboardDetails?.ner_tags_reviewed || 0, dashboardDetails?.pos_tags_reviewed || 0,dashboardDetails?.speech_tags_reviewed || 0, dashboardDetails?.image_tags_reviewed || 0]

    }, {
        name: 'Pending',
        data: [dashboardDetails?.ner_tags_pending || 0, dashboardDetails?.pos_tags_pending || 0,dashboardDetails?.speech_tags_pending || 0, dashboardDetails?.image_tags_pending || 0]

    }, ]
});

function PublicTablenBarData(adminDashboardDetails) {
    const dashboardDetails = adminDashboardDetails?.adminDashboardDetails
    return (
        <>
            <div className={classes.tagsTableWrap}>
                <table>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>{dashboardDetails?.ner_tags_assigned||0} <span>NER</span></th>
                            <th>{dashboardDetails?.pos_tags_assigned||0} <span>POS</span></th>
                            <th>{dashboardDetails?.speech_tags_assigned||0} <span>Speech</span></th>
                            <th>{dashboardDetails?.image_tags_assigned||0} <span>Images</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={classes.accepted}>
                            <td>
                                <img src="/svg/accepted-ico.svg" alt=""/>
                                <span className='pl-2'>Reviewed</span>
                            </td>
                            <td>{dashboardDetails?.ner_tags_reviewed||0}</td>
                            <td>{dashboardDetails?.pos_tags_reviewed||0}</td>
                            <td>{dashboardDetails?.speech_tags_reviewed||0}</td>
                            <td>{dashboardDetails?.image_tags_reviewed||0}</td>
                        </tr>
                        {/* <tr className={classes.rejected}>
                            <td>
                                <img src="/svg/rejected-ico.svg" alt=""/>
                                <span className='pl-2'>Rejected</span>
                            </td>
                            <td>{dashboardDetails?.ner_tags?.reject||0}</td>
                            <td>{dashboardDetails?.pos_tags?.reject||0}</td>
                            <td>{dashboardDetails?.speech_tags?.reject||0}</td>
                            <td>{dashboardDetails?.image_tags?.reject||0}</td>
                        </tr> */}
                        <tr className={classes.pending}>
                            <td>
                                <img src="/svg/pending-ico.svg" alt=""/>
                                <span className='pl-2'>Pending</span>
                            </td>
                            <td>{dashboardDetails?.ner_tags_pending||0}</td>
                            <td>{dashboardDetails?.pos_tags_pending||0}</td>
                            <td>{dashboardDetails?.speech_tags_pending||0}</td>
                            <td>{dashboardDetails?.image_tags_pending||0}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
           {(dashboardDetails?.ner_tags_assigned >=1 ||  dashboardDetails?.pos_tags_assigned >=1 || dashboardDetails?.speech_tags_assigned >=1 ||  dashboardDetails?.image_tags_assigned>=1) && 
            <div className='bgLight p-4'>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={getOptions('column',dashboardDetails)}
                />
            </div>
            }
        </>
    )
}

export default PublicTablenBarData
import React from 'react'
import { Link } from "react-router-dom"
import { LogoutPopup } from '../auth/logoutPopup'
import classes from '../components/acc.module.css'
import { useState } from 'react'
import { useSelector } from 'react-redux';
import { redux_login } from '../auth/authSlice';

function AccButtons() {

    const [pop, setpop] = useState(false)
    const Data = useSelector(state => state);

    return (
        <>
            {/* <Link
                to="/"
                className="px-4 btnPrimary text-white font-semibold py-2 lg:d-inline-block lg:mx-2"
            >
                Log in
            </Link> */}
            {/* <button
                onClick={()=> setpop(true)}
                className="px-0 btnTxt text-primary font-semibold py-2 lg:d-inline-block lg:mx-2"
            >
                Logout
            </button> */}
            <div className={classes.profAccWrap}>
                {/* <div className={classes.profIco}></div> */}
                {Data?.auth?.profile_picture_url?.length>=1 && 
                <div className={classes.profIco} style={{ backgroundImage: `url(${(Data.auth.profile_picture_url)})` }}></div>
                }
                {! Data?.auth?.profile_picture_url?.length>=1 && 
                    <div className={`${classes.profLetter} flex flex-col`}>
                    <span className='flex flex-col items-center'>{Data?.auth?.name[0] || ''}</span>
                    </div>
                }
                
                <div className={classes.navL}>
                    <Link to="/adminProfile">Profile</Link>
                    <button
                        onClick={() => setpop(true)}
                    >
                        Logout
                    </button>
                </div>
            </div>
            {pop ?
                <LogoutPopup setpop={setpop} /> : null}
        </>
    )
}

export default AccButtons
import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import Slider from "react-slick"
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
}

const getOptions = (type, selectedUserChartData) => ({

    chart: {
        type: 'pie',
        width: 860,
        height: 430,
    },

    plotOptions: {
        pie: {
            innerSize: '70%'
        }
    },

    colors: ['#CA3559', '#44A0DF', '#008000'],

    title: '',

    series: [
        {
            borderWidth: 0,
            xAxis: 0,
            data: selectedUserChartData,
            showInLegend: true,
            innerSize: '80%',
            dataLabels: {
                enabled: false,
                // format: '{point.y:.1f}%',
                // allowOverlap: false,
                // style: {
                //     fontSize: '10px',
                // }
            },
            style: {
                fontSize: '8px',
                fontWeight: 300,
            }
        },
    ],

    legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0
    },

    credits: {
        enabled: false
    },
});

function DtlsPie({ selectedUserChartData }) {

    const graphNerData = [];
    const graphImageData = [];
    const graphSpeechData = [];
    const graphPosData = [];
    // const allUsers = []
    // for (const key in adminDashboardDetails?.user_tags) {
    //         allUsers.push(adminDashboardDetails?.user_tags[key])
    // }
    if (selectedUserChartData?.image_total) {
        graphImageData.push(['Image Reject', selectedUserChartData?.image_reject])
        graphImageData.push(['Image Pending', selectedUserChartData?.image_pending])
        graphImageData.push(['Image Accept', selectedUserChartData?.image_accept])
    }
    if (selectedUserChartData?.ner_total) {
        graphNerData.push(['NER Reject', selectedUserChartData?.ner_reject])
        graphNerData.push(['NER Pending', selectedUserChartData?.ner_pending])
        graphNerData.push(['NER Accept', selectedUserChartData?.ner_accept])
    }
    if (selectedUserChartData?.speech_total) {
        graphSpeechData.push(['Speech Reject', selectedUserChartData?.speech_reject])
        graphSpeechData.push(['Speech Pending', selectedUserChartData?.speech_pending])
        graphSpeechData.push(['Speech Accept', selectedUserChartData?.speech_accept])
    }
    if (selectedUserChartData?.pos_total) {
        graphPosData.push(['POS Reject', selectedUserChartData?.pos_reject])
        graphPosData.push(['POS Pending', selectedUserChartData?.pos_pending])
        graphPosData.push(['POS Accept', selectedUserChartData?.pos_accept])
    }
    return (
        <>

          {(graphImageData.length >= 1 || graphNerData.length || graphPosData.length >= 1 || graphSpeechData.length >= 1) &&
            <div className='px-9'>
                <Slider {...settings}>
                    {graphImageData.length >= 1 &&
                        <div className='errorPie mx-auto p-6'>
                            <h6 className='m-0'>Image detail graph</h6>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={getOptions('pie', graphImageData)}
                            />
                        </div>
                    }
                    {graphNerData.length >= 1 &&
                        <div className='errorPie mx-auto p-6'>
                            <h6 className='m-0'>NER detail graph</h6>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={getOptions('pie', graphNerData)}
                            />
                        </div>
                    }

                    {graphPosData.length >= 1 &&
                        <div className='errorPie mx-auto p-6'>
                            <h6 className='m-0'>POS detail graph</h6>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={getOptions('pie', graphPosData)}
                            />
                        </div>
                    }

                    {graphSpeechData.length >= 1 &&
                        <div className='errorPie mx-auto p-6'>
                            <h6 className='m-0'>Speech Tags detail graph</h6>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={getOptions('pie', graphSpeechData)}
                            />
                        </div>
                    }
                </Slider>
            </div>
            }
        </>
    )
}

export default DtlsPie
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classes from '../dash.module.css'
import { useFormik } from 'formik';
import { useEditUserProfile } from './hooks'
import { ToastContainer, toast } from 'react-toastify';
import ImageCropper from "./ImageCropper";

//import { canvasPreview } from './canvasPreview'
//import { useDebounceEffect } from './useDebounceEffect'

//import 'react-image-crop/dist/ReactCrop.css'
//import ReactCrop from 'react-image-crop';

// import ReactCrop, {
//     centerCrop,
//     makeAspectCrop,
//     Crop,
//     PixelCrop,
//     convertToPixelCrop,
//   } from 'react-image-crop'
//   import { canvasPreview } from './canvasPreview'
//   import { useDebounceEffect } from './useDebounceEffect'

//   import 'react-image-crop/dist/ReactCrop.css'


function EditProfile() {
    const navigate = useNavigate();
    const editUserProfile = useEditUserProfile()
    const state = useLocation();
    const userProfileData = state?.state || {}
    const [selectedFile, setSelectedFile] = useState(userProfileData?.profile_picture_url);
    const [preview, setPreview] = useState(userProfileData?.profile_picture_url);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    //const [crop, setCrop] = useState({ aspect: 1 });

    const [imageToCrop, setImageToCrop] = useState(undefined);
    const [croppedImage, setCroppedImage] = useState(undefined);

    const onUploadFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                const image = reader.result;
                setImageToCrop(image);
            });
            reader.readAsDataURL(event.target.files[0]);
            setSelectedFile(event.target.files[0])
            // formik.errors.file = ''
            // const reader = new FileReader();
            // const url = reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = function (event) {
                //setPreview(reader.result)
            }.bind(this);
            setIsUpload(true)
        }
    };


    const validate = values => {
        const errors = {};

        if (!values.fullName) {
            errors.fullName = 'Required';
        } else if (values.fullName.length > 15) {
            errors.fullName = 'Must be 15 characters or less';
        }
        if (!values.age) {
            errors.age = 'Required';
        }
        if (!values.gender) {
            errors.gender = 'Required';
        }
        if (preview == '') {
            errors.file = 'Required';
        }
        return errors;
    };
   
    //const file = croppedImage?new File([croppedImage],{ type: "image/png" }):selectedFile;
  const file=  new File([selectedFile], `my_image${new Date()}.png`, {
        type: "image/png",
        lastModified: new Date(),
       // size: 2,
      });

    const formik = useFormik({
        initialValues: {
            fullName: userProfileData?.name,
            age: userProfileData?.age,
            email: userProfileData?.email,
            gender: userProfileData?.gender,
            role: userProfileData?.role,
            file: userProfileData?.profile_picture_url
        },
        validate,
        onSubmit: values => {
            const newValues = values
            const fd = new FormData()
            fd.append("name", newValues.fullName)
            fd.append("age", newValues.age)
            fd.append("gender", newValues.gender)
            fd.append("file", file)
            editUserProfile.mutate(fd)
            setIsDisabled(true)
        },
    });


    useEffect(() => {

        try {
            if (editUserProfile.isSuccess) {
                toast.success("Profile updated")
                setTimeout(() => {
                    if (userProfileData?.role !== 'admin')
                        navigate('/publicProfile')
                    else
                        navigate('/adminProfile')
                }, 3000)
            }
            else if (editUserProfile.isError) {
                toast.error("Profile update failed")
                setIsDisabled(false)
            }
        } catch (e) {
            toast.error("Profile update failed", e)
        }
    }, [editUserProfile.isSuccess, editUserProfile.isError])

    const setFile = (e) => {
        if(e?.blob){
        setSelectedFile(e?.file)
        //setPreview(e?.blob)
        formik.errors.file = ''
        const reader = new FileReader();
        const url = reader.readAsDataURL(e?.file);
        reader.onloadend = function (e) {
            setPreview(reader.result)
        }.bind(this);
    }else if(e?.image){
        const reader = new FileReader();
            // reader.addEventListener("load", () => {
            //     const image = reader.result;
            //     setImageToCrop(image);
            // });
            reader.readAsDataURL(selectedFile);
            //setSelectedFile(event.target.files[0])
            // formik.errors.file = ''
            // const reader = new FileReader();
            // const url = reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = function (event) {
                setPreview(reader.result)
            }.bind(this);
    }
    }


    return (

        <>
            <div className='bgLight2 hScreen py-4 px-6 lg:px-9'>
                {userProfileData?.role != 'admin' &&
                    <button type="button" className='backTxtBtn' onClick={() => navigate('/publicProfile')}>Back</button>
                }
                {userProfileData?.role == 'admin' &&
                    <button type="button" className='backTxtBtn' onClick={() => navigate('/adminProfile')}>Back</button>
                }
                <div className={`${classes.eFormWrap} mx-auto`}>

               {isUpload&& 
                <div>
                            <ImageCropper
                                imageToCrop={imageToCrop}
                                isPopUp = {true}
                                onImageCropped={(croppedImage) => setFile(croppedImage)}
                                uploadStatus = {setIsUpload}
                            />
                        </div>
                }
                        {/* {preview && (
                            <div>
                                <h2>Cropped Image</h2>
                                <img alt="Cropped Img" src={preview} />
                            </div>
                        )} */}
                    <form onSubmit={formik.handleSubmit}>

                        {!userProfileData?.profile_picture_url?.length >= 1 &&
                            <div className={`${classes.profLetter} flex flex-col mx-auto mb-4`} style={{ backgroundImage: `url(${(preview)})` }}>
                                {!preview ? <span className='flex flex-col items-center'>{userProfileData?.name[0]}</span> : ''}
                                <button type='button' className={classes.addThumb}>
                                    <input type='file' title='' accept='image/png, image/jpeg' onChange={(e) =>
                                        onUploadFile(e)
                                    } />
                                </button>
                                {formik.errors.file ? <div className={`${classes.error} pt-2`}>{formik.errors.file}</div> : null}
                            </div>
                        }

                        {userProfileData?.profile_picture_url?.length >= 1 &&
                            <div className={`${classes.profIco} mx-auto relative mb-4`} style={{ backgroundImage: `url(${(preview)})` }}>
                                <button type='button' className={classes.addThumb}>
                                    <input type='file' title='' accept='image/png, image/jpeg' onChange={(e) => onUploadFile(e)} />
                                </button>
                            </div>
                        }                       

                        <div className={`${classes.epfld} mb-4`}>
                            <label>Full Name</label>
                            <input type='text' className={formik.errors.fullName ? 'error' : ''} placeholder='Enter here' id='fullName' name='fullName' onChange={formik.handleChange} value={formik.values.fullName} />
                            {formik.errors.fullName ? <div className={`${classes.error} pt-2`}>{formik.errors.fullName}</div> : null}
                        </div>
                        <div className='flex gap-4 mb-4'>
                            <div className={`${classes.epfld} flex-1`}>
                                <label>Age</label>
                                <input type='text' className={formik.errors.age ? 'error' : ''} placeholder='Enter here' id='age' name='age' onChange={formik.handleChange} value={formik.values.age} />
                                {formik.errors.age ? <div className={`${classes.error} pt-2`}>{formik.errors.age}</div> : null}

                            </div>
                            <div className={`${classes.epfld} flex-1`}>
                                <label>Gender</label>
                                <select id='gender' name='gender' className={formik.errors.gender ? 'error' : ''} value={formik.values.gender} onChange={formik.handleChange}>
                                    <option>Select</option>
                                    <option value='male'>Male</option>
                                    <option value='female'>Female</option>
                                </select>
                                {formik.errors.gender ? <div className={`${classes.error} pt-2`}>{formik.errors.gender}</div> : null}

                            </div>
                        </div>
                        <div className={`${classes.epfld} mb-4`}>
                            <label>Email</label>
                            <input disabled type='email' className={formik.errors.email ? 'error' : ''} id='email' name='email' placeholder='Enter here' onChange={formik.handleChange} value={formik.values.email} />
                            {formik.errors.email ? <div className={`${classes.error} pt-2`}>{formik.errors.email}</div> : null}

                        </div>
                        <div className={`${classes.epfld} mb-4`}>
                            <label>Role</label>
                            <input disabled type='text' className={formik.errors.role ? 'error' : ''} placeholder='Enter here' id='role' name='role' onChange={formik.handleChange} value={formik.values.role} />
                        </div>

                        <div className='pt-2'>
                            <button disabled={isDisabled} type='submit' className='btnPrimary py-3 block w-full font-semibold text-lg text-white'>Done</button>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer theme="colored" />
        </>
    )
}

export default EditProfile
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useListImageTags } from "./hooks";
import styles from '../../pages/shared/common.module.css'
import AuthCopy from "../../auth/shared/auth-copyright";
import { AdminListObject } from "./listObj";
import LoaderForAll from '../../components/loader-for-all'

function AdminListImageTags() {
    const navigate = useNavigate();
    const [TagsList, setTagsList] = useState([])
    const get_Tags_list_hook = useListImageTags()
    const location = useLocation()
    const [Selected, setSelected] = useState(parseInt(location.state?.selected_id) )
  
  



    const [delete_s3, setdelete_s3] = useState(null)
    const [isDeleted, setisDeleted] = useState(false)

    useEffect(() => {
        if (isDeleted) {
            get_Tags_list_hook.refetch()
            toast.success("Deleted")
            setisDeleted(false)
        }
    }, [isDeleted])

    useEffect(() => {
        get_Tags_list_hook.refetch()

    }, [])

    useEffect(() => {
        if (get_Tags_list_hook.isSuccess) {
            setTagsList(get_Tags_list_hook.data.data)


        }
        else if (get_Tags_list_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [get_Tags_list_hook.isSuccess, get_Tags_list_hook.isError, get_Tags_list_hook.data])

    function cmp(a, b) {
        const [dateParta, timeParta] = a.last_updated_on.split(' ');
        const [daya, montha, yeara] = dateParta.split('/');
        const [houra, minutea, seconda] = timeParta.split(':');
        const [datePartb, timePartb] = b.last_updated_on.split(' ');
        const [dayb, monthb, yearb] = datePartb.split('/');
        const [hourb, minuteb, secondb] = timePartb.split(':');


        const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
        const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
        return dateB - dateA;


    }



    return (
        <>
            {get_Tags_list_hook.isLoading ? <LoaderForAll /> :
                <>
                    {/* <div>
                <button type="button" className='backTxtBtn' onClick={() => navigate('/contribution')}>Back</button>
            </div> */}
                    <div className='pageWrap lightWhite withCopy'>
                        <div className='px-5 py-4 lg:px-8'>
                            <div className='grayBox rounded-xl'>
                                <div className='mb-4 flex justify-between px-3 pt-3'>
                                    <div>
                                        <h2 className='text-primary mb-0 text-lg'>Image Tags</h2>
                                    </div>
                                    {/* <div>
                                        <button type="button" className='px-4 btnPrimary text-white font-semibold py-2' onClick={() => navigate('/upload_new_Tags')}>Upload File</button>
                                    </div> */}
                                </div>

                                <div className='tableListWrap'>
                                    <div className='contTable text-xs min-w-[1200px] px-3 pb-3'>
                                        <div className='tableHead nonSticky gray flex items-center py-2'>
                                            <div className='flex-none px-3'>&nbsp;</div>
                                            <div className='flex-1 px-3'>File Title</div>
                                            <div className='flex-1 px-3'>Last Updated on</div>
                                            <div className='flex-1 px-3'>Assignee Name</div>
                                            <div className='flex-1 px-3'>Status</div>
                                            <div className='flex-none w140 px-3'>&nbsp;</div>
                                        </div>
                                        <div className='tableBody'>
                                            {/* Table Row Loop */}
                                            {TagsList.sort(cmp).map((itm) => {
                                                if (Selected === itm.image_id){
                                                    return (
                                                      <div className='dBorder' key={itm.s3_uri}>
                                                        <AdminListObject key={itm.s3_uri} itm={itm} setdelete_s3={setdelete_s3} setisDeleted={setisDeleted} />
                                                      </div>
                                                    )
                                                  }

                                                return (
                                                    <div key={itm.s3_uri}>
                                                        <AdminListObject key={itm.s3_uri} itm={itm} setdelete_s3={setdelete_s3} setisDeleted={setisDeleted} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className='copyFloat'><AuthCopy /></div>
                    </div>

                </>}
        </>
    )
}

export default AdminListImageTags
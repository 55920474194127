import React, { useState , useEffect, useRef } from 'react'
import styles from '../shared/common.module.css'
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined'

import { useMutation } from 'react-query'
import axios from 'axios'

import {Codes , repeatCodes , cCodes} from "../../components/keyMap"
import { ElevatorSharp, StarsTwoTone } from '@mui/icons-material'
import pfont from '../shared/pfont.module.css'
import { TokenAnnotator } from "react-text-annotate";


function Tagging() {
  const [isOpened, setIsOpened] = useState(true);
  const [isToggled, setIsToggled] = useState(false);
  const [lang, setlang] = useState('punjabi')
  const [endpoint, setendpoint] = useState('pun2engtranslation')
  const [inpText, setinpText] = useState("")
  const [outputText, setoutputText] = useState("")

  const [Words, setWords] = useState([])
  const [hidden, sethidden] = useState(true)
  const [actualInp, setactualInp] = useState('')

  const [value, setValue] = useState([
    {
      start: 5,
      end: 20,
      tag: "PERSON",
    },
    { start: 15, end: 20, tag: "PERSON" },
    { start: 71, end: 75, tag: "DATE" },
  ]);

  const mutation = useMutation(
    {
      mutationFn: async () => {
        setoutputText("")
        return await axios.post(`https://nlp-toolkit.panjabi.ai/${endpoint}`, {
          "text": inpText
        }, {
          'Content-Type': 'application/json',
        })
      },
      onSuccess: (data) => {
          setoutputText(data?.data[endpoint])
      }


    }
  )

  function mapToPunjabi(inp){
    var mapped = ''
    for (var val of inp) {
      if (val in cCodes) {

        mapped += cCodes[val]
      }
      else{
        mapped += val
      }
    }
    return mapped
  }

  useEffect(() => {
    let acl = mapToPunjabi(inpText)
    setactualInp(acl)
  }, [inpText])




  function toggleKB() {
    setIsOpened(wasOpened => !wasOpened);
  }

  function toggleExpandB() {
    setIsToggled(wasToggled => !wasToggled);
  }

  function handleChange(e) {
    setinpText(e.target.value)
    // console.log(e.nativeEvent.data )
    // if (lang === 'punjabi' && e.nativeEvent.inputType === 'insertText' ) {
    //   var pos = e.target.selectionStart ; 
    //   var endPos = e.target.selectionEnd;
    //   var fInp;
    //   console.log(inpText , inpText[pos] , inpText[pos-1])
    //   if (inpText[pos-2] === Codes[e.nativeEvent.data] ){
    //     if ( repeatCodes[e.nativeEvent.data] !== inpText[pos-2]){
    //         let p = inpText
    //         fInp = p.slice(0,pos-1) + Codes[e.nativeEvent.data] + p.slice(endPos-1 , p.length)
    //     //   setinpText(p => p.slice(0,pos-1) + Codes[e.nativeEvent.data] + p.slice(endPos-1 , p.length))
    //     }
    //     else{
    //       fInp = inpText
    //       console.log('here' )
    //     }
    //   }
    //   else{
    //     let p = inpText
    //     fInp = p.slice(0,pos-1) + Codes[e.nativeEvent.data]+ p.slice(endPos-1 , p.length)
    //     // setinpText(p => p.slice(0,pos-1) + Codes[e.nativeEvent.data] + p.slice(endPos-1 , p.length))
    //   }
     
    //   setTimeout(() => {
    //     document.getElementById("editor").setSelectionRange(pos, pos);
    //   });

     
    // }
    // else{
    //     fInp = e.target.value
    // //   setinpText(e.target.value)
    // }
    // console.log(fInp,"FIN" , fInp.length)

    // setinpText(fInp)
    // if (e.nativeEvent.inputType ==='deleteContentBackward'){
    //   syncBox(e.target.selectionStart , fInp , e.nativeEvent.inputType)
    // }
    // else{
    //   syncBox(pos , fInp , e.nativeEvent.inputType)

    // }


    
  }

  function syncBox(start   , inp , type){
    var lInd = 0
    var w1 = null
    var w1_start = null 
    var w1_stop = null 
    var w2_start = null
    var w2_stop = null 
    var w2 = null
    var tWords = [...Words]
    var fWords = []
    if (type ==='insertText') {
        start -=1 
        for (var i =0 ;i<tWords.length ;i++) {
            if (start > tWords[i].stop){
              fWords.push(tWords[i])
            }
            else{
                if (tWords[i].start < start){
                  if (inp[start] ==' '){

                    w1 = inp.slice(tWords[i].start , start )
                    w1_start = tWords[i].start
                    w1_stop = start -1
                    w2 = inp.slice(start +1 ,tWords[i].stop+2)
                    w2_start = start +1
                    w2_stop = tWords[i].stop +1 
                    lInd = i 
                    fWords.push(
                      {
                        word: w1, 
                        start: w1_start,
                        stop: w1_stop,
                        lable: ''
                    }
                    )
                    fWords.push({
                      word: w2, 
                      start: w2_start,
                      stop: w2_stop,
                      lable: ''
                  })
                  }
                  else{
                    fWords.push({
                      word: inp.slice(tWords[i].start , tWords[i].stop+2), 
                      start: tWords[i].start ,
                      stop: tWords[i].stop+1,
                      lable: ''
                  })
                  }
                    
                }
                else{
                  fWords.push({
                    word: tWords[i], 
                    start: tWords[i].start+1 ,
                    stop: tWords[i].stop+1,
                    lable: tWords[i].lable
                  })
                    
                }
            }
        }

    }
    else if (type === 'deleteContentBackward') {
      for (var i =0 ;i<tWords.length ;i++) {
        if (start > tWords[i].stop){
           fWords.push(tWords[i])
        }
        else{
            if (start < tWords[i].start) {
              if (i-1>=0 && tWords[i-1].stop + 1 === start && start === tWords[i].start -1 ){
                fWords.pop()
                fWords.push({
                  word: tWords[i-1].word + tWords[i].word ,
                  start: tWords[i-1].start ,
                  stop: tWords[i].stop-1,
                  lable: ''

                })
              }
              else{
                fWords.push({
                  word: tWords[i].word ,
                  start: tWords[i].start -1,
                  stop: tWords[i].stop-1,
                  lable: tWords[i].lable
                })
              }
            }
            else{
              fWords.push({
                word: tWords[i].word.slice(0 ,start - tWords[i].start) + tWords[i].word.slice(start - tWords[i].start+1 , tWords[i].stop - start +2 )  ,
                start: tWords[i].start,
                stop: tWords[i].stop-1,
                lable: tWords[i].lable
              })

            }
        }
    }
    



    }

    setWords(fWords)
  }
  

    function WordFromText(inp) {

            var words = []


            var word_start = false 
            var word = ''
            var start = 0;
            var stop = 0;
            var lable = ''
            for (var i =0 ; i<inp.length;i++){
               
                if (inp[i] !==' '){
                    if (word_start === false){
                        word = inp[i]

                        start = i 
                        word_start = true
                    }
                    else{
                        word += inp[i]
                        }
                    }

                    else{
                        if (word_start) {
                            stop = i-1
                            lable= ''
                            words.push({
                                word, 
                                start,
                                stop,
                                lable
                            })
                            word_start= false
                            word = ''
                            
                        }
                        else{
                            //

                        }

                    }

                }
                if (word_start) {
                    stop = i-1
                    lable= ''
                    words.push({
                        word, 
                        start,
                        stop,
                        lable
                    })
                }

                
                
                setWords(words)
            }
  const [chunks, setchunks] = useState([])

  const handleAnotationChange = (newValue) => {
    
    setValue(newValue);
  };



            return (
                <>

                <button onClick={()=>WordFromText(inpText)} className='border-2'>Create words</button>
 
            
                  
                    
            
                      <div className={`${styles.transBox} mb-3 ${lang != 'punjabi' ? 'h-[550px]' : 'h-700'}`}>
                        <div className='block lg:flex h-full'>
                          <div className='lg:w-2/3 h-1/2 lg:h-full overflow-hidden'>
                            <div className={`fstBox ${isOpened ? 'h-[200px] lg:h-[444px]' : 'h-full'} ${lang != 'punjabi' ? 'lg:h-[700px]' : ''}`}>
                              <div className={`h-full ${styles.textAreaPne}`}>
                                <div className='flex h-full p-4 overflow-auto'>
                                  {/* Index */}
                                  <div className='flex-none opacity-40 leading-relaxed w-[2rem]'>
                                    <div>1</div>
                                    <div>&nbsp;</div>
                                    <div>3</div>
                                    <div>4</div>
                                  </div>
                                  <div className='flex-1'>
                                    <textarea id="editor" className={`p-0 absolute ${lang === 'punjabi' ? pfont.panjabiFont : ''}`} value={inpText} onChange={handleChange}  placeholder='Add the text here'></textarea>
                                  </div>
                                </div>                    
                                {lang =='punjabi' ? 
                                <div className={`flex justify-between p-3 ${styles.btnHldr}`}>
                                
                                  <div>
                                    <button className={styles.kbtoggleBtn} onClick={toggleKB} title='Toggle Keyboard'><KeyboardAltOutlinedIcon /></button>
                                  </div> 
                                  <div>
                                    <button className='px-4 btnPrimary text-white font-semibold py-2 lg:d-inline-block'>{mutation.isLoading ? 'loading...' : 'Translate' }</button>
                                  </div>
                                </div> : 
                                <div className={styles.nonPunjabiBtnHldr}>
                                <button className='px-4 btnPrimary text-white font-semibold py-2 lg:d-inline-block'>{mutation.isLoading ? 'loading...' : 'Translate' }</button>
                              </div> }
            
                              </div>
                            </div>
                            
                              
                            
                           
                          </div>
                          <div className={`lg:w-1/3 h-1/2 lg:h-full ${styles.rightSBox}`}>
                            <div className={`h-full relative ${styles.textAreaPne}`}>
            
                              {/* <textarea className='absolute' value={outputText} placeholder={mutation.isLoading ? 'Loading...' : ""}></textarea> */}
                              <div className='p-3 h-full w-full top-0 left-0 absolute '>
                              <TokenAnnotator
                                style={{
                                  fontFamily: "IBM Plex Sans",
                                  maxWidth: 500,
                                  lineHeight: 1.5,
                                }}
                                tokens={actualInp.split(" ")}
                                value={value}
              
                                onChange={handleAnotationChange}
                                getSpan={(span) => ({
                                  ...span,
                                  tag: 'person',
                                  color:'#84d2ff',
                                })}
                              />
                                
                                {/* <div onMouseUpCapture={(e)=> {
                                
                                }} >{actualInp}</div> */}
                                {/* {Words.length !==0 && Words.map((itm)=> {
                                  if (itm.lable=='') {
                                    return <div className='bbox inline-block p-[2px] '>
                                      {itm.word}
                                      
                                    </div>
            
                                  }
                                  else{
            
                                    return <div className='hbbox inline-block p-[2px] '>
                                      {itm.word} {itm.lable !=='' ? 
                                      <select className='selectTag '>
                                        <option>NN</option>
                                        <option>PP</option>
                                        <option>DD</option>
                                      </select> :
                                       '' } 
                                      <button className='clos-b'>&times;</button>
                                      </div>
                                  }
            
                                })} */}
                               
                              </div>
            
                              
                  
                            </div>
                          </div>
                        </div>
                      </div>
            
                
            
                     
                </>
              )
}

export default Tagging
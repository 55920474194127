import React , {useState , useEffect} from 'react'
import styles from '../shared/common.module.css'
import { Link } from 'react-router-dom'
import AuthCopy from '../../auth/shared/auth-copyright'

import { useNavigate } from 'react-router-dom'
function ServicesFront() {

    const navigate = useNavigate()
     



    return (
        <>
           <div className='pageWrap lightWhite withCopy'>
                       

                <div className={`${styles.pagecontainer} ${styles.pxC}`}>
                    
                <div className='grayBox rounded-xl pb-5'>
            <div className='text-center'>
                <div className='inline-block'><img src="/svg/themeIcon.svg" alt="" /></div>
            </div>
            <div className={styles.selOptBox}>
                <h2 className='text-primary mb-3 text-lg'>Select an Option</h2>
                <ul className='list-none'>
                    <li>
                        <button onClick={()=> navigate('/ner')}>Named Entity Recognition</button>
                    </li>
                    <li>
                    <button onClick={()=> navigate('/pos')}> Part of Speech Recognition</button>
                    </li>
                    <li>
                    <button onClick={()=> navigate('/translation')}>Translation</button>
                    </li>
                    <li>
                    <button onClick={()=> navigate('/transliteration')}> Machine Transliteration</button>
                    </li>
                </ul>
            </div>
        </div>

                </div>

                <div className='copyFloat'><AuthCopy /></div>          
            </div> 
        </>
    )
}

export default ServicesFront
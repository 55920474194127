
import React from 'react'
import styles from '../shared/common.module.css'
import AuthCopy from '../../auth/shared/auth-copyright'

export const Gurmukhi = () => {
  return (
    <>
      <div className='pageWrap lightWhite withCopy'>
        <div className={`${styles.pagecontainer} ${styles.pxC}`}>
          <div className='py-3'>
            <img src="/alphabets/alphabets-img.svg" alt="" />
          </div>
        </div>

        <div className='copyFloat'><AuthCopy /></div>
      </div>
    </>

  )
}

import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

//const fileTypes = ["txt"];

function DragDrop({file , setFile, fileTypes}) {
    
    const handleChange = (file) => {
        setFile(file);
    };
    return (
        <>
            <div className="uplBoxOuter">
                <div className='uplBox text-center mb-5'>
                    <FileUploader
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}>
                        <p className='mb-3 inline-block'><img src='/svg/upload-icon.svg' alt="" /></p>
                        <p className='mb-3'>Drag & drop file or <span>Browse</span></p>
                        <p className="text-xs">Supported formats: {fileTypes.join()}</p>
                    </FileUploader>
                </div>
                <p className="text-sm text-center">{file ? `File name: ${file.name}` : ""}</p>
            </div>
        </>
    );
}

export default DragDrop;
//import React from 'react';
import React, { useEffect, useState, useRef } from "react";
import styles from '../../pages/shared/common.module.css'
//import RecognitionFilter from './recognition-filter'
import StatusTable from './status-table'
import SpeechTextPane from './speech-textpane'
import SpeechTagEdit from './speechTagEdit'
import SpeechsActions from './speech-actions'
import AuthCopy from '../../auth/shared/auth-copyright'
//import Select from '../../services/select'
import { useGetDocumentForSpeechTag, useUpdateSpeechTags, useGetTagByReqId, useGetAllSpeechTagging } from './hooks';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom'
import { NerGuidelines } from "../../pages/contribution/ner/nerGuidelines";
import HelpIcon from '@mui/icons-material/Help';


function SpeechRecognitionDetails() {

    const navigate = useNavigate();
    const [sTagValue, setSTagValue] = useState([]);
    const { id } = useParams()
    const [speech_request_id, setspeech_request_id] = useState(parseInt(id))
    const getSpeeachTagHookData = useGetTagByReqId();
    const [loading, setloading] = useState(false)

    useEffect(() => {
        if (getSpeeachTagHookData.isSuccess) {
            if (getSpeeachTagHookData.data.message) {
                toast.success("No more data available")
            }
            else {
                //setDoc(getSpeeachTagHookData.data.data)
                setSTagValue(getSpeeachTagHookData.data.data)
                // setIsSubmit(false)
            }

        }
        else if (getSpeeachTagHookData.isError) {
            toast.error("Error is Fetching Data")
        }
        setloading(false)
    }, [getSpeeachTagHookData.isSuccess, getSpeeachTagHookData.isError, getSpeeachTagHookData.data])

    useEffect(() => {
        getSpeeachTagHookData.refetchWithId(speech_request_id)
        return () => {
            getSpeeachTagHookData.remove()
        }
    }, [id])


    const [tagList, settagList] = useState([])
    const get_my_tags_hook = useGetAllSpeechTagging()

    useEffect(() => {
        if (get_my_tags_hook.isSuccess) {
            if (get_my_tags_hook.data.data.length === 0) {
                settagList([])

            }
            else {
                let ids = get_my_tags_hook.data.data.sort(cmp).map(itm => itm.speech_request_id)
                settagList(ids)
            }
        }
        else if (get_my_tags_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [get_my_tags_hook.isSuccess, get_my_tags_hook.isError])

    useEffect(() => {
        get_my_tags_hook.refetch()

        return () => {
            get_my_tags_hook.remove()
        }
    }, [])

    function cmp(a, b) {
        const [dateParta, timeParta] = a.last_updated_on.split(' ');
        const [daya, montha, yeara] = dateParta.split('/');
        const [houra, minutea, seconda] = timeParta.split(':');
        const [datePartb, timePartb] = b.last_updated_on.split(' ');
        const [dayb, monthb, yearb] = datePartb.split('/');
        const [hourb, minuteb, secondb] = timePartb.split(':');


        const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
        const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
        return dateB - dateA;


    }





    function handleNext() {
        let ind = tagList.indexOf(speech_request_id)
        if (ind < tagList.length - 1) {
            setspeech_request_id(tagList[ind + 1])
            // get_my_ner_tags_by_request_id_hook.refetchWithId(tagList[ind+1])
            navigate(`/admin_speech_tag_view/${tagList[ind + 1]}`)
            setloading(true)
        }
    }

    function handlePrev() {
        let ind = tagList.indexOf(speech_request_id)
        if (ind > 0) {
            setspeech_request_id(tagList[ind - 1])
            navigate(`/admin_speech_tag_view/${tagList[ind - 1]}`)
            setloading(true)
            // get_my_ner_tags_by_request_id_hook.refetchWithId(tagList[ind-1])
        }
    }


    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    return (
        <>
            <div className='pageWrap lightWhite'>
                <div className='block lg:flex gap-0'>
                    <div className='grow px-3 lg:px-8 pb-3'>
                        <div className={`${styles.pagecontainer}`}>

                            <div className="block lg:flex justify-between items-center mb-3 lg:pr-5">
                                <div>
                                    <button type="button" className='backTxtBtn' onClick={() => navigate('/admin-speech-tags', { state: { selected_id: id } })}>Back</button>
                                </div>
                                <div className="flex gap-4 items-center">
                                    {/* <div>
                                        <button type="button" title="Help" onClick={handleClick}><HelpIcon /></button>
                                    </div> */}
                                    <div>
                                        <button disabled={tagList.indexOf(speech_request_id) === tagList.length-1 ? true : false } onClick={handleNext} className='btnOutline text-white font-semibold py-2 px-4'>Previous</button>
                                    </div>
                                    <div>
                                        <button disabled={tagList.indexOf(speech_request_id) === 0 ? true : false } onClick={handlePrev} className='btnOutline text-white font-semibold py-2 px-4'>Next</button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='grayBox rounded-xl mb-4'>
                                    <SpeechTextPane tagDocument={sTagValue} />
                                </div>
                                <SpeechTagEdit tagDocument={sTagValue} id={speech_request_id} tagList={tagList} setspeech_request_id={setspeech_request_id} />
                            </div>
                        </div>
                    </div>

                    {/* {open ? (
                        <div className='flex-none lg:w-[380px]'>
                            <NerGuidelines setOpen={setOpen} />
                        </div>
                    ) : null} */}
                </div>
            </div>
        </>
    )
}

export default SpeechRecognitionDetails
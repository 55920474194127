import React, { useState, useEffect, useRef } from 'react'
import styles from '../../pages/shared/common.module.css'
import AuthCopy from '../../auth/shared/auth-copyright'
import { AreaSelector, IArea } from '@bmunozg/react-image-area'
import { TextTagging } from './addTextTagging'
//import { useUpdateImageTagsStatus, useGetImageTagById } from './hooks';
import { useFormik } from 'formik';
import { useSearchParams, useNavigate, createSearchParams, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { cCodes } from "../../components/keyMap"
import { UpdateImageTagStatusPop } from './updatePop';
import LoaderForAll from '../../components/loader-for-all'
import { useUpdateImageTags, useGetImageTaggingByID, useListImageTags } from './hooks';

import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import KeyBoard from '../../components/virtual-keyboard'
import TagList from '../category-management/tag-list'
// function AdminEditImageTagging() {
//     const navigate = useNavigate();
//     const {id} = useParams()
//     const update_image_tag_status_hook = useUpdateImageTagsStatus();
//     const tagImage = useGetImageTagById(id);
//     const [areas, setAreas] = useState([]);
//     const [oldAreas, setOldAreas] = useState([]);
//     const [imageData, setImageData] = useState([]);
//     const [deleteTag, setDeleteTag] = useState(null);
//     const [tagToBeDeleted, setTagToBeDeleted] = useState([]);
//     const [disabled, setDisabled] = useState(false);
//     const [submitPop, setsubmitPop] = useState(false)

//     const [StatusPop, setStatusPop] = useState(null)
//     //const [inpText, setinpText] = useState("")
//     //const [actualInp,setactualInp] = useState("")
//     // function handleChange(e) {
//     //     setinpText(e.target.value)    

//     // }
//     const [loading, setloading] = useState(true);
//     const counter = useRef(0);
//     const imageLoaded = () => {
//         counter.current += 1;
//         if (counter.current >= 1) {
//             setloading(false);
//         }
//     }

//     function mapToPunjabi(inp) {
//         let mapped = ''
//         for (let  val of inp) {
//           if (val in cCodes) {

//             mapped += cCodes[val]
//           }
//           else {
//             mapped += val
//           }
//         }
//         return mapped
//       }

//     //   useEffect(() => {
//     //       setactualInp(mapToPunjabi(inpText))

//     //   }, [inpText])


//     const formik = useFormik({
//         initialValues: {
//             image_id: "",
//             coordinates: "",
//             tags: "",
//         },
//         // validationSchema: UploadCorpusSchema,
//         onSubmit: (values) => {
//             // if (! file){
//             //     return
//             // }
//             setDisabled(true)
//             if (areas.length) {
//                 const requestsArray = [];
//                 const oldRequests = oldAreas.requests;
//                 const oldRequestKeys = [];
//                 for (let key of oldRequests.keys()) {
//                     oldRequestKeys.push(key)
//                 }
//                 const allOldRequestsArray = [];
//                 oldRequests.map((area, index) => {

//                     if (oldRequestKeys.includes(index)) {
//                         allOldRequestsArray.push({
//                             "image_request_id": area.image_request_id,
//                             "image_tag_id": area.image_tag_id,
//                             "coordinates": area.coordinates,
//                             "tags": area.tags,
//                             "status": area.status
//                         })
//                     }
//                     // }
//                 });
//                 areas.map((area, index) => {

//                     if (area.x && area.y) {
//                         const tagsAreaCoords = [area.x, area.y, area.height, area.width]
//                         const spanId = index + 1;
//                         const div = document.getElementById(spanId);
//                         if (!oldRequestKeys.includes(index)) {
//                             requestsArray.push({
//                                 "coordinates": tagsAreaCoords,
//                                 "tags": mapToPunjabi(div.innerHTML),
//                                 "status": "pending"
//                             })
//                         } else {
//                             if (allOldRequestsArray[index] && allOldRequestsArray[index].coordinates)
//                                 allOldRequestsArray[index].coordinates = tagsAreaCoords;
//                             if (allOldRequestsArray[index])
//                                 allOldRequestsArray[index].tags = mapToPunjabi(div.innerHTML)
//                         }
//                     }
//                 });
//                 const body = {
//                     status : [{ image_request_id : 2 , status: StatusPop }]
//                 };
//                 update_image_tag_status_hook.mutate(body);

//             }
//         }
//     });


//     const notifySuccess = (msg) => {
//         toast.success(msg, {
//           onClose: ({ uid }) => setDisabled(false)
//         });

//       }
//       const notifyError = (msg) => {
//         toast.error(msg, {
//           onClose: ({ uid }) => setDisabled(false)
//         });

//       }
//     //show success and error messages after update the tags
//     useEffect(() => {

//         if (update_image_tag_status_hook.isSuccess) {
//             notifySuccess('Tags updated successfully');
//             setTagToBeDeleted([])
//             setStatusPop(null)
//             tagImage.refetch();
//         }
//         else if (update_image_tag_status_hook.isError) {
//             notifyError('Error while updating tags');
//         }
//     }, [update_image_tag_status_hook.isSuccess, update_image_tag_status_hook.isError])

//     // fetch all image tags
//     useEffect(() => {

//         if (tagImage.isSuccess) {
//             const oldTags = tagImage.data.data.requests;
//             const allTags = []
//             oldTags.map((data) => {
//                 const coords = data.coordinates;
//                 allTags.push({ x: coords[0], y: coords[1], height: coords[2], width: coords[3], unit: 'px', isNew: false, isChanging: false, tags: data.tags, image_request_id: data.image_request_id, image_tag_id: data.image_tag_id })
//             })
//             setAreas(allTags);
//             setOldAreas(tagImage.data.data);
//             setImageData(tagImage.data.data);

//         }
//         else if (tagImage.isError) {
//             toast.error("Error in Fetching Data")
//         }
//     }, [tagImage.isSuccess, tagImage.isError , tagImage.data])



//     useEffect(() => {
//         tagImage.refetch();
//         return ()=>{
//             tagImage.remove();
//         }

//     }, [])


//     // function to get all tags and set in a state
//     const onChangeHandler = (areas1) => {
//         try{
//         setAreas(areas);
//         return false;
//         }catch (e) {
//             console.log('error',e)
//         }


//     }

//     //function to show delete btn and number on image tag
//     const customRender = (areaProps) => {
//         if (!areaProps.isChanging && areaProps.height && areaProps.width) {

//             return (
//                 <div className={styles.wrapTxt} key={areaProps.areaNumber}>
//                     {/* <button type='button' className={styles.wtRemove} onClick={() => {
//                         setDeleteTag(areaProps.areaNumber);
//                     }}></button> */}
//                     <span className={styles.arNo}>{areaProps.areaNumber}</span>
//                 </div>
//             );
//         }
//     };


//     return (
//         <>
//             <div className='pageWrap lightWhite withCopy'>
//             <div style={{ display: loading ? "block" : "none" }}>
//                 <LoaderForAll />
//                 </div>
//                 <div style={{ display: loading ? "none" : "block" }}  className={`${styles.pagecontainer} ${styles.pxC}`}>
//                     <div className='flex gap-4'>
//                         <div>
//                             <button type="button" className='backTxtBtn' onClick={() => navigate('/admin-image-tags')}>Back</button>
//                         </div>
//                         <div>
//                             <span className={`${styles.statLbl} ${imageData.overall_status}`}>{imageData.overall_status}</span>
//                         </div>
//                     </div>

//                     <form>
//                         <div className='block lg:flex lg:gap-4'>

//                             <div className='w-full lg:w-2/3 mb-4 lg:mb-0'>

//                                 <div className='grayBox rounded-xl flex h-full items-center'>
//                                 {!loading &&
//                                     <div className={`${styles.areaS} ${styles.tagDisabled}`}>
//                                         <AreaSelector
//                                             areas={areas}
//                                             debug={true}
//                                             minHeight={10}
//                                             minWidth={10}
//                                             //maxAreas = {0}
//                                             wrapperStyle={{
//                                                 border: '2px dashed blue',
//                                                 backgroundColor: 'red',
//                                             }}
//                                             globalAreaStyle={{
//                                                 border: 'dashed 2px blue',
//                                                 backgroundColor: 'rgba(0,0,255,0.4)',
//                                                 opacity: '1'
//                                             }}
//                                             onChange={onChangeHandler}
//                                             customAreaRenderer={customRender}
//                                         >
//                                             <img src={imageData?.s3_url}></img>
//                                         </AreaSelector>
//                                     </div>
//                                 }
//                                  {loading &&
//                                         <img src={imageData?.s3_url} onLoad={imageLoaded} ></img>
//                                     }
//                                 </div>


//                             </div>
//                             <div className='w-full lg:w-1/3 mb-4 lg:mb-0'>
//                                 <div className={`${styles.textAreaPne} grayBox rounded-xl h-full`}>
//                                     <TextTagging tags={areas}></TextTagging>
//                                     <div className='flex justify-end gap-2 px-2 pt-1'>
//                                         <button disabled={disabled} onClick={(e)=>{
//                                             e.preventDefault()
//                                             setStatusPop('reject')
//                                         }
//                                         } className='btnOutline text-white font-semibold py-3 px-6'>
//                                             Reject
//                                         </button>
//                                         <button disabled={disabled} onClick={(e)=> {
//                                             e.preventDefault()
//                                             setStatusPop('accept')
//                                         }

//                                         } className='btnPrimary text-white font-semibold py-3 px-6'>
//                                             Accept
//                                         </button>                                        
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>
//                     </form>
//                 </div>

//                 <div className='copyFloat'><AuthCopy /></div>
//             </div>
//             {StatusPop !== null && <UpdateImageTagStatusPop StatusPop={StatusPop} setStatusPop={setStatusPop} update_image_tag_status_hook={update_image_tag_status_hook} areas={areas} />}
//         </>
//     )
// }

function AdminEditImageTagging() {
    const navigate = useNavigate();
    const { id } = useParams()
    const [image_id, setimage_id] = useState(parseInt(id))

    //const image_id = searchParams.get('id');
    //console.log('image_id',id)
    const editImageTags = useUpdateImageTags();
    const tagImage = useGetImageTaggingByID();
    const [areas, setAreas] = useState([]);
    const [oldAreas, setOldAreas] = useState([]);
    const [imageData, setImageData] = useState([]);
    const [deleteTag, setDeleteTag] = useState(null);
    const [tagToBeDeleted, setTagToBeDeleted] = useState([]);
    const [disabled, setDisabled] = useState(false);
    //const [inpText, setinpText] = useState("")
    //const [actualInp,setactualInp] = useState("")
    // function handleChange(e) {
    //     setinpText(e.target.value)    

    // }
    const get_Tags_list_hook = useListImageTags()
    const [TagsList, setTagsList] = useState([])
    const [isOpened, setIsOpened] = useState(true);
    function toggleKB() {
        setIsOpened(wasOpened => !wasOpened);
    }

    const [loading, setloading] = useState(true);
    const counter = useRef(0);
    const imageLoaded = () => {
        counter.current += 1;
        if (counter.current >= 1) {
            setloading(false);
        }
    }
    function mapToPunjabi(inp) {
        let mapped = ''
        for (let val of inp) {
            if (val in cCodes) {

                mapped += cCodes[val]
            }
            else {
                mapped += val
            }
        }
        return mapped
    }

    //   useEffect(() => {
    //       setactualInp(mapToPunjabi(inpText))

    //   }, [inpText])


    const formik = useFormik({
        initialValues: {
            image_id: "",
            coordinates: "",
            tags: "",
        },
        // validationSchema: UploadCorpusSchema,
        onSubmit: (values) => {
            // if (! file){
            //     return
            // }
            setDisabled(true)
            if (areas.length) {
                const requestsArray = [];
                const oldRequests = oldAreas.requests;
                const oldRequestKeys = [];
                for (let key of oldRequests.keys()) {
                    oldRequestKeys.push(key)
                }
                const allOldRequestsArray = [];
                oldRequests.map((area, index) => {

                    if (oldRequestKeys.includes(index)) {
                        allOldRequestsArray.push({
                            "image_request_id": area.image_request_id,
                            "image_tag_id": area.image_tag_id,
                            "coordinates": area.coordinates,
                            "tags": area.tags,
                            "status": 'reviewed'
                        })
                    }
                    // }
                });
                areas.map((area, index) => {

                    if (area.x && area.y) {
                        const tagsAreaCoords = [area.x, area.y, area.height, area.width]
                        const spanId = index + 1;
                        const div = document.getElementById(spanId);
                        if (!oldRequestKeys.includes(index)) {
                            requestsArray.push({
                                "coordinates": tagsAreaCoords,
                                "tags": mapToPunjabi(div.innerHTML),
                                "status": "reviewed"
                            })
                        } else {
                            if (allOldRequestsArray[index] && allOldRequestsArray[index].coordinates)
                                allOldRequestsArray[index].coordinates = tagsAreaCoords;
                            if (allOldRequestsArray[index])
                                allOldRequestsArray[index].tags = mapToPunjabi(div.innerHTML)
                        }
                    }
                });
                const body = {
                    "image_id": imageData.image_id,
                    requests: allOldRequestsArray,
                    new_tags: requestsArray,
                    "delete_request_ids": tagToBeDeleted
                };
                editImageTags.mutate(body);

            }
        }
    });

    useEffect(() => {
        get_Tags_list_hook.refetch()

    }, [])

    useEffect(() => {
        if (get_Tags_list_hook.isSuccess) {
            if (get_Tags_list_hook.data.data.length === 0) {
                setTagsList([])
            }

            else {
                let ids = get_Tags_list_hook.data.data.sort(cmp).map(itm => itm.image_id)
                setTagsList(ids)
            }


        }
        else if (get_Tags_list_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [get_Tags_list_hook.isSuccess, get_Tags_list_hook.isError, get_Tags_list_hook.data])

    function cmp(a, b) {
        const [dateParta, timeParta] = a.last_updated_on.split(' ');
        const [daya, montha, yeara] = dateParta.split('/');
        const [houra, minutea, seconda] = timeParta.split(':');
        const [datePartb, timePartb] = b.last_updated_on.split(' ');
        const [dayb, monthb, yearb] = datePartb.split('/');
        const [hourb, minuteb, secondb] = timePartb.split(':');


        const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
        const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
        return dateB - dateA;


    }


    const notifySuccess = (msg) => {
        toast.success(msg, {
            onClose: ({ uid }) => setDisabled(false)
        });

    }
    const notifyError = (msg) => {
        toast.error(msg, {
            onClose: ({ uid }) => setDisabled(false)
        });

    }
    //show success and error messages after update the tags
    useEffect(() => {

        if (editImageTags.isSuccess) {
            notifySuccess('Tags updated successfully');
            setTagToBeDeleted([])

            let ind = TagsList.indexOf(image_id)
            if (ind < TagsList.length - 1) {
                setimage_id(TagsList[ind + 1])
                // get_my_ner_tags_by_request_id_hook.refetchWithId(tagList[ind+1])
                navigate(`/adminStatusImageTag/${TagsList[ind + 1]}`)
                setloading(true)
            }

        }
        else if (editImageTags.isError) {
            notifyError('Error while updating tags');
        }
    }, [editImageTags.isSuccess, editImageTags.isError])

    // fetch all image tags
    useEffect(() => {

        if (tagImage.isSuccess) {
            const oldTags = tagImage.data.data.requests;
            const allTags = []
            oldTags.map((data) => {
                const coords = data.coordinates;
                allTags.push({ x: coords[0], y: coords[1], height: coords[2], width: coords[3], unit: 'px', isNew: false, isChanging: false, tags: data.tags, image_request_id: data.image_request_id, image_tag_id: data.image_tag_id })
            })
            setAreas(allTags);
            setOldAreas(tagImage.data.data);
            setImageData(tagImage.data.data);
        }
        else if (tagImage.isError) {
            toast.error("Error in Fetching Data")
        }
        setloading(false)
    }, [tagImage.isSuccess, tagImage.isError, tagImage.data])


    //delete a image tag
    useEffect(() => {
        let deleteIndex = deleteTag - 1
        const allTags = [...areas];

        const deletedTags = tagToBeDeleted.length ? [...tagToBeDeleted] : []
        if (deleteIndex !== -1) {
            const deletedTag = allTags[deleteIndex];
            allTags.splice(deleteIndex, 1);
            if (deletedTag.image_tag_id)
                deletedTags.push(deletedTag.image_tag_id)
            setTagToBeDeleted(deletedTags)
            setAreas(allTags);
            setDeleteTag(null)
        }
    }, [deleteTag])

    useEffect(() => {
        tagImage.refetchWithId(image_id)
        return () => {
            tagImage.remove();
        }

    }, [id])


    // function to get all tags and set in a state
    const onChangeHandler = (areas1) => {
        setAreas(areas1);
    }

    //function to show delete btn and number on image tag
    const customRender = (areaProps) => {
        if (!areaProps.isChanging && areaProps.height && areaProps.width) {

            return (
                <div className={styles.wrapTxt} key={areaProps.areaNumber}>
                    <button type='button' className={styles.wtRemove} onClick={() => {
                        setDeleteTag(areaProps.areaNumber);
                    }}></button>
                    <span className={styles.arNo}>{areaProps.areaNumber}</span>
                </div>
            );
        }
    };


    function handleNext() {
        let ind = TagsList.indexOf(image_id)
        if (ind < TagsList.length - 1) {
            setimage_id(TagsList[ind + 1])
            // get_my_ner_tags_by_request_id_hook.refetchWithId(tagList[ind+1])
            navigate(`/adminStatusImageTag/${TagsList[ind + 1]}`)
            setloading(true)
        }
    }

    function handlePrev() {
        let ind = TagsList.indexOf(image_id)
        if (ind > 0) {
            setimage_id(TagsList[ind - 1])
            navigate(`/adminStatusImageTag/${TagsList[ind - 1]}`)
            setloading(true)
            // get_my_ner_tags_by_request_id_hook.refetchWithId(tagList[ind-1])
        }
    }


    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen((prev) => !prev);
    };


    return (
        <>
            <div className={`pageWrap lightWhite withCopy ${isOpened ? 'pbKBImgTg' : ''}`}>
                <div style={{ display: loading ? "block" : "none" }}>
                    <LoaderForAll />
                </div>
                <div style={{ display: loading ? "none" : "block" }} className={`${styles.pagecontainer} ${styles.pxC}`}>
                    <div className='flex justify-between items-center mb-3'>
                        <div>
                            <button type="button" className='backTxtBtn' onClick={() => navigate('/admin-image-tags', { state: { selected_id: id } })}>Back</button>
                        </div>
                        <div className='flex gap-4'>
                            <div>
                                <button disabled={TagsList.indexOf(image_id) === TagsList.length-1 ? true : false } onClick={handleNext} className='btnOutline text-white font-semibold py-2 px-5'>Previous</button>
                            </div>
                            <div>
                                <button disabled={TagsList.indexOf(image_id) === 0 ? true : false } onClick={handlePrev} className='btnOutline text-white font-semibold py-2 px-5'>Next</button>
                            </div>
                        </div>
                    </div>

                    <form>

                        <div className='block lg:flex lg:gap-4'>

                            <div className='w-full lg:w-2/3 mb-4 lg:mb-0'>

                                <div className='grayBox rounded-xl flex h-full items-center'>
                                    {!loading &&
                                        <div className={`${styles.areaS}`}>
                                            <AreaSelector
                                                areas={areas}
                                                debug={true}
                                                minHeight={10}
                                                minWidth={10}
                                                wrapperStyle={{
                                                    border: '2px dashed blue',
                                                    backgroundColor: 'red',
                                                }}
                                                globalAreaStyle={{
                                                    border: 'dashed 2px blue',
                                                    backgroundColor: 'rgba(0,0,255,0.4)',
                                                    opacity: '1'
                                                }}
                                                onChange={onChangeHandler}
                                                customAreaRenderer={customRender}
                                            >
                                                <img src={imageData?.s3_url}  ></img>
                                            </AreaSelector>
                                        </div>
                                    }
                                    {loading &&
                                        <img src={imageData?.s3_url} onLoad={imageLoaded} ></img>
                                    }
                                </div>


                            </div>
                            <div className='w-full lg:w-1/3 mb-4 lg:mb-0'>
                                <div className={`${styles.textAreaPne} grayBox rounded-xl h-full`}>
                                    <TextTagging tags={areas}></TextTagging>

                                    <div className='flex justify-between px-2 pt-1 text-right'>
                                        <div>
                                            <button className={styles.kbtoggleBtn} onClick={toggleKB} title='Toggle Keyboard'><KeyboardAltOutlinedIcon /></button>
                                        </div>
                                        <div>
                                            <button onClick={formik.handleSubmit} className='btnPrimary text-white font-semibold py-3 px-6'>
                                                Review
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </form>
                </div>
                <div className={`${styles.keyBoardFloat} ${isOpened ? 'block' : 'hidden'}`}>
                    <button type='button' className='p-0 btnCloseKB' title='Close' onClick={toggleKB}><HighlightOffIcon /></button>
                    <KeyBoard />
                </div>

                <div className='copyFloat'><AuthCopy /></div>
            </div>
        </>
    )
}

export default AdminEditImageTagging
import React from 'react'
import { Link } from "react-router-dom"

function SideBox() {
    return (
        <>
            <div className='h-96 md:h-screen'>
                <div className='sideAuthBox'>
                    <div className='captionBox w-full md:w-96'>
                        <h1 className='uppercase text-2xl md:text-3xl mb-2'>
                            <Link to="/">PANJABI.AI</Link>
                        </h1>
                        <p className='m-0'>Panjabi.AI is a Python based NLP Toolkit for Punjabi language.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SideBox
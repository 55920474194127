import React from 'react'
import { Link, useParams } from "react-router-dom"
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

function AdminNavItems() {
    const [active, setactive] = useState('dsahboard')
    const Data = useSelector(item => item)
    let location = useLocation()
    const pathNameArray = location.pathname.split('/')
    const pathName = "/"+pathNameArray[1]

    
    return (
        <>
            <ul className="nav items-center justify-center lg:flex">
                <li className={`text-white hover:text-indigo-200 ${['/', '/home-public' , '/home-admin' , '/leaderboard'].includes(location.pathname) ? 'active' : ''}`}>
                    <Link id='admin_dashboard' to='/home-admin'>Dashboard</Link>
                </li>
                <li className={`text-white hover:text-indigo-200 ${['/category'].includes(pathName) ? 'active' : ''}`}>
                    <Link id='admin_metadata' to='/category'>Meta Data</Link>
                </li>
                <li className={`text-white hover:text-indigo-200 ${['/admin-list-corpus'].includes(pathName) ? 'active' : ''}`}>
                    <Link id='admin_corpus' to='/admin-list-corpus'>Text Corpus</Link>
                </li>
                <li className={`text-white hover:text-indigo-200 ${['/adminNerList' , '/adminNerUpdateStatus'].includes(pathName) ? 'active' : ''}`}>
                    <Link id='admin_ner' to='/adminNerList'>NER Tags</Link>
                </li>
                <li className={`text-white hover:text-indigo-200 ${['/adminPosList' , '/adminPosUpdateStatus'].includes(pathName) ? 'active' : ''}`}>
                    <Link id='admin_ner' to='/adminPosList'>POS Tags</Link>
                </li>
                
                {/* <li className={`text-white hover:text-indigo-200 ${['/admin-list-images'].includes(pathName) ? 'active' : ''}`}>
                    <Link id='admin_ner' to='/admin-list-images'>Image Corpus</Link>
                </li> */}
                <li className={`text-white hover:text-indigo-200 ${['/admin-image-tags' , '/adminStatusImageTag'].includes(pathName) ? 'active' : ''}`}>
                    <Link id='admin_ner' to='/admin-image-tags'>Image Tags</Link>
                </li>
                
                <li className={`text-white hover:text-indigo-200 ${['/admin-speech-tags' , '/admin_speech_tag_view'].includes(pathName) ? 'active' : ''}`}>
                    <Link id='admin_ner' to='/admin-speech-tags'>Speech Tags</Link>
                </li>
                
                
                
                
            </ul>
        </>
    )
}

export default AdminNavItems
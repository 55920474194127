import React, { useState, useEffect } from 'react'
import styles from '../../shared/common.module.css'
import AuthCopy from '../../../auth/shared/auth-copyright'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'
import LoaderForAll from '../../../components/loader-for-all'
import { useGetMyTags } from './hooks'
import { useNavigate } from 'react-router-dom'
import ListObj from './listObj'
import { PosCreateTag } from './posCreateTag';
import { PosEditTag } from './posEditTag';
import { useLocation } from 'react-router-dom';


function PosFront() {

  const navigate = useNavigate();
  const [tagList, settagList] = useState([])
  const get_my_tags_hook = useGetMyTags()

  const location = useLocation();
  const [Selected, setSelected] = useState(parseInt(location.state?.selected_id))




  useEffect(() => {
    if (get_my_tags_hook.isSuccess) {
      if (get_my_tags_hook.data.data.length ===0) {
        settagList([])
      }
      else{

        settagList(get_my_tags_hook.data.data)
      }


    }
    else if (get_my_tags_hook.isError) {
      toast.error("Error is Fetching Data")
    }
  }, [get_my_tags_hook.isSuccess, get_my_tags_hook.isError, get_my_tags_hook.data])


useEffect(() => {
  get_my_tags_hook.refetch()

  return () => {
    get_my_tags_hook.remove()
  }
}, [])

function cmp(a,b) {
  const [dateParta, timeParta] = a.last_updated_on.split(' ');
  const [daya, montha, yeara] = dateParta.split('/');
  const [houra, minutea, seconda] = timeParta.split(':');
  const [datePartb, timePartb] = b.last_updated_on.split(' ');
  const [dayb, monthb, yearb] = datePartb.split('/');
  const [hourb, minuteb, secondb] = timePartb.split(':');


  const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
  const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
  return dateB - dateA;
    
  
}


  return (
    <>
      {get_my_tags_hook.isLoading ? <LoaderForAll /> :
        <div className='pageWrap lightWhite withCopy'>
          
          <div className={`${styles.pagecontainer} ${styles.pxC}`}>
            <button type="button" className='backTxtBtn' onClick={() => navigate('/contribution')}>Back</button>
            
            <div className='grayBox rounded-xl p-0'>
              <div className='mb-4 flex justify-between px-4 pt-4'>
                <div>
                  <h2 className='text-primary mb-0 text-lg'>Part of Speech Recognition</h2>
                </div>
                <div>
                  <button type="button" className='px-4 btnPrimary text-white font-semibold py-2' onClick={() => navigate('/posCreateNew')}>Get New Document</button>
                </div>
              </div>
              <div className='tableListWrap pb-2'>
                <div className='contTable text-xs min-w-[1000px] px-3'>
                  <div className='tableHead gray flex items-center py-2'>
                    <div className='flex-1 px-3'>Document ID</div>
                    <div className='flex-1 px-3'>Document</div>
                    <div className='flex-1 px-3'>POS Status</div>
                    <div className='flex-1 px-3'>Last Update On</div>
                    <div className='flex-1 px-3'>&nbsp;</div>
                  </div>
                  <div className='tableBody'>
                    { tagList.sort(cmp).map((itm) => {
                      if (itm.pos_request_id ==Selected) {
                        return (
                          <div className='dBorder' key={itm.s3_uri}>
                            <ListObj itm={itm} />
                          </div>
                        )
                      }
                      return (
                        <div key={itm.s3_uri}>
                          <ListObj itm={itm}/>
                        </div>
                      )

                    })}



                  </div>
                </div>
              </div>
            </div> 
            
       </div> 
          <div className='copyFloat'><AuthCopy /></div>
        </div>}
    </>
  )
}

export default PosFront
import React, { useState, useEffect } from 'react'
import classes from './cate.module.css'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { useGetAllNERTags, useUpdateNerTags } from './hooks'
import { ToastContainer, toast } from 'react-toastify';
import ClearIcon from '@mui/icons-material/Clear';


function TagList() {
    const [toggle, setToggle] = useState(false);
    const handleClick = () => {
        setToggle(!toggle);
    };
    const get_tags = useGetAllNERTags()
    const update_tags_hook = useUpdateNerTags()
    const [tags, settags] = useState([])
    const [mySet, setMySet] = useState(new Set());
    const [Disable, setDisable] = useState(true)



    useEffect(() => {
        if (get_tags.isSuccess) {
            if (get_tags.data.data.length === 0) {
                settags([])

            }
            else {

                settags(get_tags?.data?.data)
                const newSet = new Set(get_tags?.data?.data);
                setMySet(newSet);
            }


        }
        else if (get_tags.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [get_tags.isSuccess, get_tags.isError, get_tags.data])

    useEffect(() => {
        get_tags.refetch()
        return () => {
            get_tags.remove()
        }
    }, [])

    function handleSave() {
        let body = {
            tags: tags 
        }
        update_tags_hook.mutate(body)
    }

    function handleAddTag() {
        let tx = [...tags]
        //tx.push("")
        settags(['', ...tx])
        setDisable(false)
    }

    function handleDeleteTag(ind) {
        let tx = [...tags]
        settags(tx.slice(0, ind).concat(tx.slice(ind + 1)))
        setDisable(false)
    }

    function handleChange(e,ind) {
        let tx = [...tags]
        if (tx.includes(e.target.value)) {
            toast.error("Tag already Exists")
        }
        else{
            tx[ind] = e.target.value
            settags(tx)
            setDisable(false)
        }
        
    }
    useEffect(() => {
        if (update_tags_hook.isSuccess) {
            toast.success("Uploaded Success")
            get_tags.refetch()
            setDisable(true)
        }
        else if (update_tags_hook.isError) {
            toast.error("Error is Fetching Data")
        }

    }, [update_tags_hook.isError, update_tags_hook.isSuccess])

    

    return (
        <>
            <div className='lg:p-4'>
                <div className='px-4 lg:px-5 py-2 text-right mb-2'>
                    <button disabled={Disable} onClick={()=> handleSave()} type='button' className='btnOutline px-4 py-2 ml-2'>
                        Save
                    </button>
                    <button onClick={()=> handleAddTag()} type='button' className='btnPrimary px-4 py-2 ml-2'>
                        <AddIcon /> Add Tag
                    </button>
                </div>
                <div className={`${classes.catFrontLWrap} px-4`}>
                    {/* Tag List Loop */}
                    {tags.map((tg , ind) => (
                        <div className='mb-3'>
                            <div className={`${classes.lBox} flex items-center justify-between py-4 pl-6 pr-2 rounded-lg`}>
                                <div className='flex items-center'>
                                    <div>
                                        <span class="badge">{ind+1}</span>
                                    </div>
                                    <div className='px-5 lg:px-8'>
                                        <input disabled={mySet.has(tg)} value={tg} onChange={(e)=> handleChange(e,ind)} className={`${classes.listLBL} w-[200px] lg:w-[400px]`}/>                                            
                                    </div>
                                </div>
                                <div className='flex items-center'>
                                    <div className='px-3'>
                                        <button onClick={()=>{ 
                                            const newSet = new Set(mySet);
                                            newSet.delete(tg);
                                            setMySet(newSet);

                                        }} type='button' title='Edit'>
                                            <EditIcon fontSize='small' />
                                        </button>
                                    </div>
                                    <div className='px-3'>
                                        <button onClick={()=> handleDeleteTag(ind)} type='button' title='Remove'>
                                            <ClearIcon fontSize='medium' />
                                        </button>
                                    </div>
                                    {/* <div className='px-3'>
             <button type='button' onClick={handleClick}>
                 {toggle ?
                     <ArrowDropUpIcon fontSize='medium' />
                     :
                     <ArrowDropDownIcon fontSize='medium' />
                 }
             </button>
         </div> */}
                                </div>
                            </div>


                        </div>

                    ))}




                </div>

            </div>
        </>
    )
}

export default TagList
import React, { useEffect, useState } from 'react'
import styles from '../../shared/common.module.css'
import AuthCopy from '../../../auth/shared/auth-copyright'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useGetAllNERTags, useGetMyTags, useGetNewDocument, useGetTagByReqId, useUpdateTags, useUploadTags, uuseUploadTags } from './hooks';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TokenAnnotator } from "react-text-annotate";
import LoaderForAll from '../../../components/loader-for-all'
import { NER_TAGS, POS_TAGS } from '../../../config';
import HelpIcon from '@mui/icons-material/Help';
import { NerGuidelines } from './nerGuidelines';


export const NerEditTag = () => {
    const { id } = useParams()
    const [Ner_ID, setNer_ID] = useState(parseInt(id))
    const navigate = useNavigate();
    const [Doc, setDoc] = useState()
    const [nertag, setnertag] = useState(NER_TAGS[0])
    const [postag, setpostag] = useState(POS_TAGS[0])
    const [NerValue, setNerValue] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const updateNerTags_hook = useUpdateTags()
    const [loading, setloading] = useState(false)
    const [checkEmpty, setcheckEmpty] = useState(false)

    const get_tags = useGetAllNERTags()
    const [tags, settags] = useState([])



    useEffect(() => {
        if (get_tags.isSuccess) {
            if (get_tags.data.data.length === 0) {
                settags([])
            }
            else {
                settags(get_tags?.data?.data.sort())
            }


        }
        else if (get_tags.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [get_tags.isSuccess, get_tags.isError, get_tags.data])

    useEffect(() => {
        get_tags.refetch()
        return () => {
            get_tags.remove()
        }
    }, [])


    const get_my_ner_tags_by_request_id_hook = useGetTagByReqId();

    const get_my_tags_hook = useGetMyTags()
    const [tagList, settagList] = useState([])

    function cmp(a, b) {
        const [dateParta, timeParta] = a.last_updated_on.split(' ');
        const [daya, montha, yeara] = dateParta.split('/');
        const [houra, minutea, seconda] = timeParta.split(':');
        const [datePartb, timePartb] = b.last_updated_on.split(' ');
        const [dayb, monthb, yearb] = datePartb.split('/');
        const [hourb, minuteb, secondb] = timePartb.split(':');


        const dateA = new Date(Date.UTC(parseInt(yeara), parseInt(montha) - 1, parseInt(daya), parseInt(houra), parseInt(minutea), parseInt(seconda)));
        const dateB = new Date(Date.UTC(parseInt(yearb), parseInt(monthb) - 1, parseInt(dayb), parseInt(hourb), parseInt(minuteb), parseInt(secondb)));
        return dateB - dateA;


    }

    useEffect(() => {
        if (get_my_tags_hook.isSuccess) {
            if (get_my_tags_hook.data.data.length === 0) {
                settagList([])
            }

            else {
                let ids = get_my_tags_hook.data.data.sort(cmp).map(itm => itm.ner_request_id)
                settagList(ids)
            }
        }
        else if (get_my_tags_hook.isError) {
            toast.error("Error is Fetching Data")
        }
    }, [get_my_tags_hook.isSuccess, get_my_tags_hook.isError, get_my_tags_hook.data])


    useEffect(() => {
        get_my_tags_hook.refetch()
        return () => {
            get_my_tags_hook.remove()
        }
    }, [])



    useEffect(() => {
        if (get_my_ner_tags_by_request_id_hook.isSuccess) {
            if (get_my_ner_tags_by_request_id_hook.data.message) {
                toast.success("No more data available")
            }
            else {
                setDoc(get_my_ner_tags_by_request_id_hook.data.data)
                setNerValue(get_my_ner_tags_by_request_id_hook.data.data.ner_tags)
                setIsSubmit(false)
            }

        }
        else if (get_my_ner_tags_by_request_id_hook.isError) {
            toast.error("Error is Fetching Data")
        }
        setloading(false)
    }, [get_my_ner_tags_by_request_id_hook.data, get_my_ner_tags_by_request_id_hook.isSuccess, get_my_ner_tags_by_request_id_hook.isError])

    useEffect(() => {
        get_my_ner_tags_by_request_id_hook.refetchWithId(Ner_ID)

        return () => {
            get_my_ner_tags_by_request_id_hook.remove()
        }
    }, [id])


    const handleNerAnotationChange = (newValue) => {
        if (!isSubmit) {
            setNerValue(newValue);
        }

    };

    function handleSubmit() {
        let body = {
            ner_request_id: Doc.ner_request_id,
            document: Doc.document,
            ner_tags: JSON.stringify(NerValue),
            ner_status: 'pending',
        }
        setIsSubmit(true)
        updateNerTags_hook.mutate(body);


    }
    const notifySuccess = (msg) => {
        toast.success(msg, {
            onClose: ({ uid }) => setIsSubmit(false)
        });

    }
    const notifyError = (msg) => {
        toast.error(msg, {
            onClose: ({ uid }) => setIsSubmit(false)
        });

    }


    useEffect(() => {
        if (updateNerTags_hook.isSuccess) {
            // navigate('/nerContribution');
            toast.success("Update Success")
            // notifySuccess("Uploaded Success")
        }
        else if (updateNerTags_hook.isError) {
            toast.error("Error in updating tags")
            // notifyError("Error is uploading tags")
        }

    }, [updateNerTags_hook.isError, updateNerTags_hook.isSuccess])


    function handleNext() {
        let ind = tagList.indexOf(Ner_ID)
        if (ind < tagList.length - 1) {
            setNer_ID(tagList[ind + 1])
            console.log(tagList, "LLS")
            // get_my_ner_tags_by_request_id_hook.refetchWithId(tagList[ind+1])
            navigate(`/nerEditTag/${tagList[ind + 1]}`)
            setloading(true)
        }
    }

    function handlePrev() {
        let ind = tagList.indexOf(Ner_ID)
        console.log(tagList, Ner_ID, "ER")
        if (ind > 0) {
            setNer_ID(tagList[ind - 1])
            navigate(`/nerEditTag/${tagList[ind - 1]}`)
            setloading(true)
            // get_my_ner_tags_by_request_id_hook.refetchWithId(tagList[ind-1])
        }
    }


    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    return (
        <>
            <div className='pageWrap lightWhite'>
                <div className='flex gap-0'>
                    <div className='grow lg:px-5 relative hScreen'>
                        {updateNerTags_hook.isLoading || loading ? <LoaderForAll /> :

                            <div className={`${styles.pagecontainer} ${styles.pxC}`}>
                                <button type="button" className='backTxtBtn' onClick={() => navigate('/nerContribution', { state: { selected_id: id } })}>Back</button>

                                <>

                                    <div className='grayBox rounded-xl p-4 mb-4'>

                                        <div className='flex items-center justify-between mb-3'>
                                            <div className='grow flex items-center'>
                                                <h2 className='text-primary m-0 text-lg'>Named Entity Recognition Tags</h2>

                                                <div className='pl-4'>
                                                    <select className='selectTag xPad' value={nertag} onChange={(e) => setnertag(e.target.value)} name="tags" id="tags">
                                                        {tags.map(itm => {
                                                            return (
                                                                <option key={itm} value={itm}>{itm}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <div className='pl-3'>
                                                    <button type="button" title='Help' onClick={handleClick}><HelpIcon /></button>
                                                </div>
                                            </div>
                                            <div>
                                                <button type="button" className='px-4 btnPrimary text-white font-semibold py-2' onClick={() => navigate('/nerCreateNew')}>Get New Document</button>
                                            </div>
                                        </div>
                                        {
                                            Doc != null ?

                                                <TokenAnnotator
                                                    tokens={Doc.document}
                                                    value={NerValue}

                                                    onChange={handleNerAnotationChange}
                                                    getSpan={(span) => ({
                                                        ...span,
                                                        tag: nertag,

                                                    })}
                                                />

                                                : null
                                        }

                                    </div>

                                </>

                                <div className='flex gap-4 justify-between'>
                                    <div>
                                        <label>
                                            <input type="checkbox" name="checkEmpty" id="checkEmpty" checked={checkEmpty} onChange={() => setcheckEmpty(p => !p)} />
                                            <span className='inline-block ml-2'>There is not NER tag available</span>
                                        </label>
                                    </div>
                                    <div className='flex gap-4'>
                                        
                                        <div><button disabled={tagList.indexOf(Ner_ID) === tagList.length-1 ? true : false } onClick={handleNext} className='btnOutline text-white font-semibold px-4 py-2'>Previous</button></div>
                                        <div><button disabled={tagList.indexOf(Ner_ID) === 0 ? true : false } onClick={handlePrev} className='btnOutline text-white font-semibold px-4 py-2'>Next</button></div>
                                        <div>
                                            <button disabled={(NerValue.length === 0 && checkEmpty === false) || updateNerTags_hook.isLoading} onClick={handleSubmit} className='px-4 btnPrimary text-white font-semibold py-2'>Update</button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        }
                        <div className='copyFloat'><AuthCopy /></div>
                    </div>

                    {open ? (
                        <div className='flex-none lg:w-[380px]'>
                            <NerGuidelines setOpen={setOpen} />
                        </div>
                    ) : null}
                </div>


            </div>
        </>
    )
}

import * as React from 'react'
import styles from '../shared/common.module.css'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { MODAL_OPTION } from '../../config';

function SelectModal({modal , setmodal , services , setendpoint}) {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const getModalText = () => {
        switch (modal) {
            case 'ner_allennlp':
                return MODAL_OPTION?.ner_allennlp || '';

            case 'ner_ktrain':
                return MODAL_OPTION?.ner_ktrain || '';
            case 'pos':
                    return MODAL_OPTION?.pos || '';
            case 'pos_rule_based':
                        return MODAL_OPTION?.pos_rule_based || ''; 

            default:
                return 'Select Modal';
        }
    }
    function handlemodalChange(newmodal) {
        
        if (services === 'ner'){
            if (newmodal ==='ner_allennlp'){
                setmodal('ner_allennlp')
                setendpoint('ner_allennlp')
            }
            else{
                setmodal('ner_ktrain')
                setendpoint('ner_ktrain')
            }
        }
        else if (services =='pos') {
            if (newmodal ==='pos'){
                setmodal('pos')
                setendpoint('pos')
            }
            else{
                setmodal('pos_rule_based')
                setendpoint('pos_rule_based')
            }
        }
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={styles.dropbox}>
                <button type="button" onClick={handleClick}>
                {getModalText()} <ArrowDropDownOutlinedIcon />
                </button>
                {open ? (
                    <>
                     {services =='ner' ? 
                    <div className={styles.dropped}>
                       
                         <div className={styles.ddItem}>
                            <input checked={modal==='ner_allennlp'} onChange={()=>handlemodalChange('ner_allennlp')} type='radio' name="selectname" />
                            <label>ner_allennlp</label>
                            <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                        </div>
                        <div className={styles.ddItem}>
                            <input checked={modal==='ner_ktrain'} onChange={()=>handlemodalChange('ner_ktrain')} type='radio' name="selectname" />
                            <label>ner_ktrain</label>
                            <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                        </div>
                       
                       
                    </div>
                    : 
                    <div className={styles.dropped}>
                       
                         <div className={styles.ddItem}>
                            <input checked={modal==='pos'} onChange={()=>handlemodalChange('pos')} type='radio' name="selectname" />
                            <label>pos</label>
                            <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                        </div>
                        <div className={styles.ddItem}>
                            <input checked={modal==='pos_rule_based'} onChange={()=>handlemodalChange('pos_rule_based')} type='radio' name="selectname" />
                            <label>pos_rule_based</label>
                            <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                        </div>
                       
                       
                    </div> }
                    </>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}

export default SelectModal
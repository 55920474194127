import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function ListObj({ itm , setEditItm }) {
    const navigate = useNavigate();

    function formatDate(inputDate) {
        const months = [
           "Jan", "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        var date = inputDate.split(' ')
        var d = date[0]
        var t = date[1]
        d = d.split('/')
        t = t.split(':')
        var din = d[0]
        var month = months[parseInt(d[1])]
        var year = d[2]
        var tm = parseInt(t[0]) > 12 ? 'pm' : 'am'
        var hr = parseInt(t[0]) > 12 ? String(parseInt(t[0]) - 12) : t[0]
        var mn = t[1]
        var ans = din + ' ' + month + ' ' + year + ', ' + hr + ':' + mn + ' ' + tm
        return ans
    }




    return (
        <div className='tBodyRow flex items-center py-2 rounded-md'>
       
        
        <div className='flex-1 px-3'>
            <p>{itm.document_id}</p>
        </div>
        

        <div className='flex-1 px-3'>
            <p className='mw10' title={itm.document}>{itm.document}</p>
        </div>
        <div className='flex-1 px-3'>
            <p className='lStat' title={itm.is_assigned}>{itm.is_assigned}</p>
        </div>
        <div className='flex-1 px-3'>
            <p className='lStat'><span className={itm.speech_status}>{itm.speech_status}</span></p>
        </div>
        <div className='flex-1 px-3'>
            <p title={formatDate(itm.last_updated_on)}>{formatDate(itm.last_updated_on)}</p>
        </div>
        <div className='flex-1 px-3 leading-none text-right'>
            <button type='btn' onClick={()=> navigate(`/admin_speech_tag_view/${itm.speech_request_id}`)} className='btnView mx-2' title='View'></button>
        </div>
    </div>
    )
}

export default ListObj
import React from 'react'
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import "./shared/auth.css"
import SideBox from './shared/sideart-box'
import AuthCopy from './shared/auth-copyright'
import LoaderForAll from '../components/loader-for-all'
import { BACKEND_URL } from '../config'
import { OtpInput } from './signupOtp'
import { useFormik } from 'formik';
import { useMutation } from 'react-query'
import axios from 'axios'
import { SignUpSchema } from './authSchema'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSendotp } from './hooks'


function SignUp() {
    const [isPasswordVisible, setIsPasswordVisible] = useState(true);
    const [isCPasswordVisible, setIsCPasswordVisible] = useState(true);
    const sendOtp_hook = useSendotp()

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            password: "",
            confirm_password: "",
            age: "",
            gender: "",
            otp: ""
        },
        validationSchema: SignUpSchema,
        onSubmit: (values) => {
            const data = { email: values.email }
            sendOtp_hook.mutate(data)
        }
    });

    useEffect(() => {
        if (sendOtp_hook.isError) {
            toast.error(sendOtp_hook.error?.response?.data.message)
        }

    }, [sendOtp_hook.isError])

    function handleKeyUp(e) { 
        if (e.key === 'Enter') {
            formik.handleSubmit()
        }
    }




    return (
        <>
            <div className='authWrapper relative block md:flex'>
                <div className='flex-none w-full md:w-2/4'>
                    <SideBox />
                </div>
                <div className='grow md:h-screen'>
                    <div className='p-8 md:px-12 h-full md:flex'>

                        <div className='authModBox lg:w-96 max-w-full'>
                            <h2 className='uppercase text-2xl md:text-3xl mb-2'>Sign Up</h2>
                            <p className='text-xs mb-5'>You are invited to be a contributor to this project. Please Signup to access the NLP toolkit.</p>
                            {sendOtp_hook.isSuccess ? <OtpInput formik={formik} />
                                :
                                <>
                                    {sendOtp_hook.isLoading ? <LoaderForAll /> :

                                        <form onKeyUp={handleKeyUp} name='signup'>
                                            <div className='mb-3'>
                                                <label htmlFor='name' className='text-primary w-full font-semibold'>Full Name</label>
                                                <input onBlur={formik.handleBlur} name='name' id="name" value={formik.values.name} onChange={formik.handleChange} type='text' className={`${(formik.errors.name && formik.touched.name) ? 'error' : ''} w-full inpFld`} placeholder='Enter here' />
                                                {formik.errors.name && formik.touched.name && <small className='error'>{formik.errors.name}</small>}
                                            </div>
                                            <div className='grid grid-cols-2 gap-4 mb-3'>
                                                <div>
                                                    <label htmlFor='age' className='text-primary w-full font-semibold'>Age</label>
                                                    <input onBlur={formik.handleBlur} name='age' id="age" value={formik.values.age} onChange={formik.handleChange} type='text' className={`${(formik.errors.age && formik.touched.age) ? 'error' : ''} w-full inpFld`} placeholder='Enter here' />
                                                    {formik.errors.age && formik.touched.age && <small className='error'>{formik.errors.age}</small>}
                                                </div>
                                                <div>
                                                    <label htmlFor='gender' className='text-primary w-full font-semibold'>Gender</label>
                                                    <select id="gender" selected={formik.values.gender} className={`${formik.errors.gender && formik.touched.gender ? 'error' : ''} w-full inpFld`} onChange={formik.handleChange}  >
                                                        <option value="" label='Select Gender'>select gender</option>
                                                        <option value={'male'} label='male'>Male</option>
                                                        <option value={'female'} label='female'>Female</option>
                                                    </select>
                                                    {formik.errors.gender && formik.touched.gender && <small className='error'>{formik.errors.gender}</small>}
                                                </div>
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor='email' className='text-primary w-full font-semibold'>Email Address</label>
                                                <input onBlur={formik.handleBlur} name='email' id="email" value={formik.values.email} onChange={formik.handleChange} type='text' className={`${(formik.errors.email && formik.touched.email) ? 'error' : ''} w-full inpFld`} placeholder='Enter here' />
                                                {formik.errors.email && formik.touched.email && <small className='error'>{formik.errors.email}</small>}
                                            </div>
                                            <div className='mb-3 relative'>
                                                <label htmlFor='password' className='text-primary w-full font-semibold'>Password</label>
                                                <input onBlur={formik.handleBlur} name='password' id="password" value={formik.values.password} onChange={formik.handleChange} type={isPasswordVisible ? "text" : "password"}
                                                    className={`${formik.errors.password && formik.touched.password ? 'error' : ''} w-full inpFld`} placeholder='Enter here' />
                                                {formik.errors.password && formik.touched.password && <small className='error'>{formik.errors.password}</small>}
                                                <button type='button' className="toggleBtn" onClick={(e) => {
                                                    e.preventDefault()
                                                    setIsPasswordVisible(p => !p)
                                                }}>
                                                    {isPasswordVisible ? 'Hide' : 'Show'}
                                                </button>
                                            </div>
                                            <div className='mb-3 relative'>
                                                <label htmlFor='confirm_password' className='text-primary w-full font-semibold'>Confirm Password</label>
                                                <input onBlur={formik.handleBlur} name='confirm_password' id="confirm_password" value={formik.values.confirm_password} onChange={formik.handleChange} type={isCPasswordVisible ? "text" : "password"}
                                                    className={`${formik.errors.confirm_password && formik.touched.confirm_password ? 'error' : ''} w-full inpFld`} placeholder='Enter here' />
                                                {formik.errors.confirm_password && formik.touched.confirm_password && <small className='error'>{formik.errors.confirm_password}</small>}
                                                <button type='button' className="toggleBtn" onClick={(e) => {
                                                    e.preventDefault()
                                                    setIsCPasswordVisible(p => !p)
                                                }}>
                                                    {isCPasswordVisible ? 'Hide' : 'Show'}
                                                </button>
                                            </div>
                                            <div className='flex justify-between text-xs mb-5'>
                                                <div>
                                                    <input onBlur={formik.handleBlur} type='checkbox' className='align-middle mr-2' /> Remember Me
                                                </div>
                                                <div>
                                                    <Link to='/forget-password'>Forgot Password?</Link>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-2 gap-4'>
                                                <div>
                                                    <button onClick={formik.handleSubmit} className='btnPrimary text-white font-semibold py-3 w-full'>
                                                        Sign up
                                                    </button>
                                                </div>
                                                <div>
                                                    <Link to="/" className='btnOutline text-primary font-semibold py-3 w-full'>
                                                        Log in
                                                    </Link>
                                                </div>
                                            </div>

                                            {/* <small className='error'>{Err}</small> */}
                                        </form>}

                                </>

                            }
                        </div>
                    </div>
                </div>
                <div className='copyTxt text-sm p-3'>
                    <AuthCopy />
                </div>
            </div>

        </>
    )
}

export default SignUp
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSignedIn: false,
  access_token: null,
  email: null,
  mobile: null,
  name: null,
  profile: null,
  role: null,
  register_id: null,
  username: null,
  loginError: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    redux_login: (state, action) => {
      state.isSignedIn = true;
      state.access_token = action.payload.auth_token;
      state.name = action.payload.name;
      state.role = action.payload.role;
      state.applied_for = action.payload.applied_for;
      state.loginError = null;
      state.register_id = action.payload.register_id;
      state.email = action.email;
      state.profile_picture_url = action?.payload?.profile_picture_url || ''
    },
    redux_updateProfile: (state, action) => {
      state.name = action.payload.name;
      state.profile_picture_url = action?.payload?.profile_picture_url || ''
    },
    redux_logout: (state) => {
      state.isSignedIn = false;
      state.access_token = null;
      state.email = null;
      state.mobile = null;
      state.name = null;
      state.profile = null;
      state.role = null;
      state.register_id = null;
      state.username = null;
      state.loginError = null;
      state.applied_for = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { redux_login, redux_logout, redux_updateProfile } = authSlice.actions;

export default authSlice.reducer;

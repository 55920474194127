import { useQuery , useMutation } from "react-query";
import axios from "axios";
import { useSelector } from "react-redux";
import { BACKEND_URL } from "../../config";

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {redux_logout} from '../../auth/authSlice'

export const useListImageTags = ()=> {
    let navigate = useNavigate()
    let dispatch = useDispatch()

    const Data = useSelector(state => state)
    return useQuery(
        {queryKey: ['adminListimageTagss'],
          queryFn: async () => {
            
            // return await axios.get(BACKEND_URL + `/view_corpuses`,{
            //    headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            // }
            // ).then(res => res.json
            //  ).then(dataJson => setcorpaList(dataJson.data) )
            const response = await axios.request({
                headers: {
                  Authorization: `Bearer ${Data.auth["access_token"]}`
                },
                method: "GET",
                url: BACKEND_URL + `/admin_get_images_tags`
              })
            return response.data ? response.data : []
          },
          onSuccess: (data) => {

          },
          onError: (error) => {
            if (error.response.data.message == "Please login first"){
              // logoutFn(Data.auth["access_token"])
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            }
          },
          enabled: false,
        }
      )
}


  export const useGetImageTagById = (image_id)=> {
    let navigate = useNavigate()
      let dispatch = useDispatch()
  
  
    const Data = useSelector(state => state)
    return useQuery(
        {queryKey: ['adminDocumentImage'],
          queryFn: async () => {
            
            // return await axios.get(BACKEND_URL + `/view_corpuses`,{
            //    headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            // }
            // ).then(res => res.json
            //  ).then(dataJson => setcorpaList(dataJson.data) )
            const response = await axios.request({
                headers: {
                  Authorization: `Bearer ${Data.auth["access_token"]}`
                },
                method: "GET",
                url: BACKEND_URL + `/admin_get_images_tags_by_id?image_id=${image_id}`
              })
            return response.data ? response.data : []
          },
          onSuccess: (data) => {
  
          },
          onError: (error) => {
            if (error.response.data.message == "Please login first"){
              // logoutFn(Data.auth["access_token"])
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            }
          },
          enabled: false,
        }
      )
  }

  export const useUpdateImageTagsStatus = () => {
    const Data = useSelector(state => state);
    let navigate = useNavigate()
    let dispatch = useDispatch()
  
    return useMutation(
        {
          mutationFn: async (data) => {
            return await axios.post(BACKEND_URL + `/admin_update_image_tags_status`, data , {
               headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            }
            )
          },
          onSuccess: (data) => {
              
          },
          onError: (error) => {
            if (error.response.data.message == "Please login first"){
              // logoutFn(Data.auth["access_token"])
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            }
          },
        }
      )
  }


  // export const useGetImageTaggingByID = (imageId)=> {
  //   let navigate = useNavigate()
  //   let dispatch = useDispatch()
  
  
  //   const Data = useSelector(state => state)
  //   return useQuery(
  //       {
  //         queryKey: ['getTagImageById'],
  //         queryFn: async () => {
            
  //           // return await axios.get(BACKEND_URL + `/view_corpuses`,{
  //           //    headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
  //           // }
  //           // ).then(res => res.json
  //           //  ).then(dataJson => setcorpaList(dataJson.data) )
  //           const response = await axios.request({
  //               headers: {
  //                 Authorization: `Bearer ${Data.auth["access_token"]}`
  //               },
  //               method: "GET",
  //               url: BACKEND_URL + `/admin_get_images_tags_by_id?image_id=`+imageId
  //             })
  //           return response.data ? response.data : []
  //         },
  //         onSuccess: (data) => {
  
  //         },
  //         onError: (error) => {
  //           if (error.response.data.message == "Please login first"){
  //             // logoutFn(Data.auth["access_token"])
  //           localStorage.clear()
  //           dispatch(redux_logout())
  //           navigate('/')
  //           }
  //         },
  //         enabled:false
  //       }
  //     )
  // }


  export const useGetImageTaggingByID = () => {
    let navigate = useNavigate();
    let dispatch = useDispatch();
  
    const Data = useSelector((state) => state);
  
    // Define the query function that accepts parameters
    const fetchTagsByReqId = async (id) => {
      const response = await axios.request({
        headers: {
          Authorization: `Bearer ${Data.auth["access_token"]}`,
        },
        method: "GET",
        url: BACKEND_URL + `/admin_get_images_tags_by_id?image_id=`+id
      });
      return response.data ? response.data : [];
    };
  
    // Initialize the query key with an empty parameter
    const queryKey = ['imgtagByid', '']; // The second element is initially empty.
  
    const query = useQuery(
      {
        queryKey,
        queryFn: async () => fetchTagsByReqId(queryKey[1]), // Use the parameter from the query key.
        onSuccess: (data) => {
          // Handle success if needed.
        },
        onError: (error) => {
          if (error.response.data.message === "Please login first") {
            // Handle the error.
            localStorage.clear();
            dispatch(redux_logout());
            navigate('/');
          }
        },
        enabled: false,
      }
    );
  
    // Function to refetch with new parameters
    const refetchWithId = (newId) => {
      queryKey[1] = newId; // Update the parameter in the query key.
      query.refetch(); // Trigger a refetch with the updated query key.
    };
  
    return {
      ...query,
      refetchWithId,
    };
  };
  
  export const useAddImageTags = () => {
    const Data = useSelector(state => state);
    let navigate = useNavigate()
    let dispatch = useDispatch()
  
    return useMutation(
        {
          mutationFn: async (data) => {
            return await axios.post(BACKEND_URL + `/upload_image_tags`, data , {
               headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            }
            )
          },
          onSuccess: (data) => {
              
          },
          onError: (error) => {
            if (error.response.data.message == "Please login first"){
              // logoutFn(Data.auth["access_token"])
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            }
          },
        }
      )
  }
  
  
  export const useUpdateImageTags = () => {
    const Data = useSelector(state => state);
    let navigate = useNavigate()
    let dispatch = useDispatch()
  
    return useMutation(
        {
          mutationFn: async (data) => {
            return await axios.post(BACKEND_URL + `/admin_update_image_tags_status`, data , {
               headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            }
            )
          },
          onSuccess: (data) => {
              
          },
          onError: (error) => {
            if (error.response.data.message == "Please login first"){
              // logoutFn(Data.auth["access_token"])
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            }
          },
        }
      )
  }
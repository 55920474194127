import { useQuery , useMutation } from "react-query";
import axios from "axios";
import { useSelector } from "react-redux";
import { BACKEND_URL } from "../../config";

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {redux_logout} from '../../auth/authSlice'

export const useListcorpus = ()=> {
    let navigate = useNavigate()
    let dispatch = useDispatch()

    const Data = useSelector(state => state)
    return useQuery(
        {queryKey: ['adminListCorpus'],
          queryFn: async () => {
            
            // return await axios.get(BACKEND_URL + `/view_corpuses`,{
            //    headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            // }
            // ).then(res => res.json
            //  ).then(dataJson => setcorpaList(dataJson.data) )
            const response = await axios.request({
                headers: {
                  Authorization: `Bearer ${Data.auth["access_token"]}`
                },
                method: "GET",
                url: BACKEND_URL + `/admin_view_corpuses`
              })
            return response.data ? response.data : []
          },
          onSuccess: (data) => {

          },
          onError: (error) => {
            if (error.response.data.message == "Please login first"){
              // logoutFn(Data.auth["access_token"])
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            }
          },
          enabled: false,
        }
      )
}


export const useGetCorpusByURI = (uri)=> {
    let navigate = useNavigate()
      let dispatch = useDispatch()
  
  
    const Data = useSelector(state => state)
    return useQuery(
        {queryKey: ['getCorpusByURI'],
          queryFn: async () => {
            
            // return await axios.get(BACKEND_URL + `/view_corpuses`,{
            //    headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            // }
            // ).then(res => res.json
            //  ).then(dataJson => setcorpaList(dataJson.data) )
            const response = await axios.request({
                headers: {
                  Authorization: `Bearer ${Data.auth["access_token"]}`
                },
                method: "GET",
                url: BACKEND_URL + `/admin_get_corpus_by_s3_uri?s3_uri=${uri}`
              })
            return response.data ? response.data : []
          },
          onSuccess: (data) => {
  
          },
          onError: (error) => {
            if (error.response.data.message == "Please login first"){
              // logoutFn(Data.auth["access_token"])
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            }
          },
          enabled: false,
        }
      )
  }


export const useUpdateCorpusData = () => {
  const Data = useSelector(state => state)
  let navigate = useNavigate()
    let dispatch = useDispatch()



  return useMutation(
      {
        mutationFn: async (data) => {
          
          return await axios.post(BACKEND_URL + `/update_corpus_data`, data , {
             headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
          }
          )
        },
        onSuccess: (data) => {
            
        },
        onError: (error) => {
          if (error.response.data.message == "Please login first"){
            // logoutFn(Data.auth["access_token"])
          localStorage.clear()
          dispatch(redux_logout())
          navigate('/')
          }
        },
      }
    )
}


  export const useDocumentCorpusByS3URI = (uri)=> {
    let navigate = useNavigate()
      let dispatch = useDispatch()
  
  
    const Data = useSelector(state => state)
    return useQuery(
        {queryKey: ['adminDocumentCorpus'],
          queryFn: async () => {
            
            // return await axios.get(BACKEND_URL + `/view_corpuses`,{
            //    headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            // }
            // ).then(res => res.json
            //  ).then(dataJson => setcorpaList(dataJson.data) )
            const response = await axios.request({
                headers: {
                  Authorization: `Bearer ${Data.auth["access_token"]}`
                },
                method: "GET",
                url: BACKEND_URL + `/document_corpus?s3_uri=${uri}`
              })
            return response.data ? response.data : []
          },
          onSuccess: (data) => {
  
          },
          onError: (error) => {
            if (error.response.data.message == "Please login first"){
              // logoutFn(Data.auth["access_token"])
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            }
          },
          enabled: false,
        }
      )
  }

  export const useGetCategories = ()=> {
    let navigate = useNavigate()
    let dispatch = useDispatch()
      const Data = useSelector(state => state)
      return useQuery(
          {queryKey: ['getCatsAd'],
            queryFn: async () => {
              
              // return await axios.get(BACKEND_URL + `/view_corpuses`,{
              //    headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
              // }
              // ).then(res => res.json
              //  ).then(dataJson => setcorpaList(dataJson.data) )
              const response = await axios.request({
                  headers: {
                    Authorization: `Bearer ${Data.auth["access_token"]}`
                  },
                  method: "GET",
                  url: BACKEND_URL + `/read_catagory`
                })
              return response.data ? response.data : []
            },
            onSuccess: (data) => {
  
            },
            onError: (error) => {
              if (error.response.data.message == "Please login first"){
                // logoutFn(Data.auth["access_token"])
              localStorage.clear()
              dispatch(redux_logout())
              navigate('/')
              }
            },
          }
        )
  }
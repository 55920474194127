import { useQuery , useMutation } from "react-query";
import axios from "axios";
import { useSelector } from "react-redux";
import { BACKEND_URL } from "../../config";

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {redux_logout} from '../../auth/authSlice'

export const useGetDocumentForSpeechTag = ()=> {

    const Data = useSelector(state => state)
    return useQuery(
        {
          queryKey: ['getDocumentSpeechTag'],
          queryFn: async () => {
          
            const response = await axios.request({
                headers: {
                  Authorization: `Bearer ${Data.auth["access_token"]}`
                },
                method: "GET",
                url: BACKEND_URL + `/get_new_document_for_speech_tags`
              })
            return response.data ? response.data : []
          },
          onSuccess: (data) => {

          },
          enabled:false
        }
      )
}

export const useGetAllSpeechTagging = ()=> {

  const Data = useSelector(state => state)
  return useQuery(
      {
        queryKey: ['getSpeechTag'],
        queryFn: async () => {
        
          const response = await axios.request({
              headers: {
                Authorization: `Bearer ${Data.auth["access_token"]}`
              },
              method: "GET",
              url: BACKEND_URL + `/admin_get_speech_tags`
            })
          return response.data ? response.data : []
        },
        onSuccess: (data) => {

        },
        enabled:false
      }
    )
}

// export const useGetTagByReqId = (imageId)=> {

//   const Data = useSelector(state => state)
//   return useQuery(
//       {
//         queryKey: ['getSpeechTagById',imageId],
//         queryFn: async () => {
          
//           // return await axios.get(BACKEND_URL + `/view_corpuses`,{
//           //    headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
//           // }
//           // ).then(res => res.json
//           //  ).then(dataJson => setcorpaList(dataJson.data) )
//           const response = await axios.request({
//               headers: {
//                 Authorization: `Bearer ${Data.auth["access_token"]}`
//               },
//               method: "GET",
//               url: BACKEND_URL + `/admin_get_speech_tag_by_id?speech_request_id=`+imageId
//             })
//           return response.data ? response.data : []
//         },
//         onSuccess: (data) => {

//         },
//         enabled:false
//       }
//     )
// }



export const useGetTagByReqId = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const Data = useSelector((state) => state);

  // Define the query function that accepts parameters
  const fetchTagsByReqId = async (id) => {
    const response = await axios.request({
      headers: {
        Authorization: `Bearer ${Data.auth["access_token"]}`,
      },
      method: "GET",
      url: BACKEND_URL + `/admin_get_speech_tag_by_id?speech_request_id=`+id
    });
    return response.data ? response.data : [];
  };

  // Initialize the query key with an empty parameter
  const queryKey = ['speechtagByid', '']; // The second element is initially empty.

  const query = useQuery(
    {
      queryKey,
      queryFn: async () => fetchTagsByReqId(queryKey[1]), // Use the parameter from the query key.
      onSuccess: (data) => {
        // Handle success if needed.
      },
      onError: (error) => {
        if (error.response.data.message === "Please login first") {
          // Handle the error.
          localStorage.clear();
          dispatch(redux_logout());
          navigate('/');
        }
      },
      enabled: false,
    }
  );

  // Function to refetch with new parameters
  const refetchWithId = (newId) => {
    queryKey[1] = newId; // Update the parameter in the query key.
    query.refetch(); // Trigger a refetch with the updated query key.
  };

  return {
    ...query,
    refetchWithId,
  };
};

export const useAddSpeechTags = () => {
  const Data = useSelector(state => state);
  return useMutation(
      {
        mutationFn: async (data) => {
          return await axios.post(BACKEND_URL + `/upload_speech_tags`, data , {
             headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
          }
          )
        },
        onSuccess: (data) => {
            
        }
      }
    )
}


export const useUpdateSpeechTags = () => {
  const Data = useSelector(state => state);
  return useMutation(
      {
        mutationFn: async (data) => {
          return await axios.post(BACKEND_URL + `/update_speech_tags`, data , {
             headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
          }
          )
        },
        onSuccess: (data) => {
            
        }
      }
    )
}

export const useAdminUpdateSpeechTags = () => {
  const Data = useSelector(state => state);
  return useMutation(
      {
        mutationFn: async (data) => {
          return await axios.post(BACKEND_URL + `/admin_update_speech_tags_status`, data , {
             headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
          }
          )
        },
        onSuccess: (data) => {
            
        }
      }
    )
}